import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "store";

const CertifiedOutlet = () => {
  const isCertified = useAppSelector((state) => state.auth.isCertified);

  return !isCertified ? <Navigate to='/login' replace /> : <Outlet />;
};

export default CertifiedOutlet;

import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const PanelContainer = styled.section`
  width: 392px;
  background-color: #fff;
  padding: 30px;
  ${media("huge")} {
    width: 100%;
  }
`;

export const BalanceContainer = styled.div`
  background-color: rgb(var(--off-white));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
`;
export const Amount = styled.span`
  font-size: 2.7rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  color: rgb(var(--main-color-light));
  span {
    font-size: 1.1rem;
    font-weight: normal;
  }
`;
export const AmountTitle = styled.span`
  color: rgb(var(--border-dark));
  font-size: 1.4rem;
  margin-top: 8px;
`;

export const TransactionsLink = styled(Link)`
  margin-top: 24px;
  text-decoration: none;
  background-color: rgb(var(--main-color-light));
  color: #fff;
  padding: 12px 32px;
  font-size: 1.4rem;
`;

export const Label = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 30px;
  display: block;
`;

export const NextBookingContainer = styled.div`
  background-color: rgb(var(--off-white));
  display: flex;
  flex-direction: column;
  padding: 28px;
  margin-top: 15px;
`;

export const StudentInfo = styled.div`
  display: flex;
  gap: 15px;
`;

export const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-top: 30px;
`;
export const TimeItem = styled.div`
  width: 82px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  span {
    color: rgb(var(--main-color));
    font-size: 2.7rem;
    font-weight: bold;
  }
  p {
    font-size: 1.4rem;
  }
`;

export const LessonUrlLink = styled(Link)`
  margin-top: 30px;
  text-decoration: none;
  background-color: rgb(var(--main-color-light));
  color: #fff;
  padding: 12px 32px;
  font-size: 1.4rem;
  text-align: center;
`;

export const QuickLinksContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
`;
export const QuickLinkItem = styled.div`
  flex-basis: 45%;
  background-color: rgb(var(--off-white));
  display: flex;
  flex-direction: column;
  padding: 12px;
`;
export const QuickLink = styled(Link)`
  font-size: 1.1rem;
  color: rgb(var(--main-color-light));
  text-decoration: none;
  margin-top: 6px;
`;

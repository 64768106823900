import ForgetImage from "assets/images/forget-password.png";
import AppButton from "components/shared/AppButton";
import PhoneInput from "components/shared/PhoneInput";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import {
  AuthSubTitle,
  BackLink,
  ForgetPasswordImage,
  ForgetPasswordTitleContainer,
  FormContainer,
  LoginFormContainer,
  LoginTitle,
} from "./auth.styles";
import { FiArrowRight } from "react-icons/fi";
import { authAPI } from "services/apis/auth";
import toast from "react-hot-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

const ForgetForm = () => {
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await authAPI.forgetPassword(data);
      if (response.data.metaData.key === "success") {
        toast.success(t("send_successfully"));
        const encodedMobile = encodeURIComponent(data.mobile);
        navigate("/reset?mobile=" + encodedMobile);
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <LoginFormContainer>
      <ForgetPasswordTitleContainer>
        <BackLink to='/login'>
          <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
            <FiArrowRight />
          </IconContext.Provider>
        </BackLink>
        <LoginTitle>{t("auth.forget_title")}</LoginTitle>
      </ForgetPasswordTitleContainer>
      <AuthSubTitle>{t("auth.forget_subtitle")}</AuthSubTitle>
      <ForgetPasswordImage src={ForgetImage} alt='forget password' />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        {/* <PhoneInput register={register} errors={errors} /> */}
        <PhoneInputWithCountry
          name='mobile'
          international
          control={control}
          rules={{ required: true }}
          countryCallingCodeEditable={false}
          defaultCountry='SA'
        />
        <AppButton size='lg' mt='40px' fontSize={18} loader={submitLoading}>
          {t("auth.send_code")}
        </AppButton>
      </FormContainer>
    </LoginFormContainer>
  );
};

export default ForgetForm;

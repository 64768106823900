import EditPackage from "components/Dashboard/TrainerPackages/EditPackage";
import DashboardLayout from "layouts/DashboardLayout";

const EditPackageContainer = () => {
  return (
    <DashboardLayout>
      <EditPackage />
    </DashboardLayout>
  );
};

export default EditPackageContainer;

import ResetForm from "components/Auth/ResetForm";
import AuthLayout from "layouts/AuthLayout";

const ResetPasswordContainer = () => {
  return (
    <AuthLayout>
      <ResetForm />
    </AuthLayout>
  );
};

export default ResetPasswordContainer;

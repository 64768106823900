import { Input, InputContainer } from "./passwordinput.styles";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { ErrorMessage } from "@hookform/error-message";

interface passwordProps {
  register: UseFormRegister<FieldValues>;
  errors?: FieldErrorsImpl<DeepRequired<FieldValues>>;
}
const PasswordInput = ({ register, errors }: passwordProps) => {
  const { t } = useTranslation();
  const [inputType, setInputType] = useState("password");

  const handlePasswordView = () => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  return (
    <InputContainer>
      <Input
        type={inputType}
        placeholder=' '
        {...register("password", {
          required: "كلمة السر مطلوبة",
          minLength: {
            value: 8,
            message: "كلمة السر يجب أن تكون 8 حروف على الأقل",
          },
        })}
      />
      <label htmlFor='password'>{t("auth.password")}</label>
      <span>
        {inputType === "text" && <FiEye onClick={handlePasswordView} />}
        {inputType === "password" && <FiEyeOff onClick={handlePasswordView} />}
      </span>
      <ErrorMessage errors={errors} name='password' as='p' />
    </InputContainer>
  );
};

export default PasswordInput;

import {
  ChangeImage,
  ImageContainer,
  ImageMeta,
  ImageSizes,
  IntroContainer,
  ProfilePicture,
} from "components/AccountSetup/Steps/BasicInformation/basicinformation.styles";
import {
  ImageInput,
  ImagesWrapper,
} from "components/AccountSetup/Steps/Education/education.styles";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FiUploadCloud } from "react-icons/fi";
import { Title } from "../Appointments/appointments.styles";
import { ImageUploader } from "../Courses/courses.styles";
import {
  DaysContainer,
  FormContainer,
  FormControl,
  Label,
  SettingsWrapper,
} from "./settings.style";
import { useEffect, useState } from "react";
import { Avatar } from "components/Icons";
import AppInput from "components/shared/AppInput";
import { Controller, useForm } from "react-hook-form";
import AppButton from "components/shared/AppButton";
import AppText from "components/shared/AppText";
import { trainerAPI } from "services/apis/trainer";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Day } from "components/AccountSetup/Steps/AvailableTimes/availabletimes.styles";
import EditDayModal from "components/Modals/EditDayModal";
import { IWorkingDay } from "types";
import { useAppSelector } from "store";
import { toast } from "react-hot-toast";
import { setAvatar } from "store/slices/auth";
import { useDispatch } from "react-redux";
import { set } from "date-fns";

const yearsOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "> 10 ", value: 10 },
];

const Settings = () => {
  //hooks
  const [intro, setIntro] = useState<File>();
  const [profilePicture, setProfilePicture] = useState<File>();
  const [trainerData, setTrainerData] = useState<any>();
  const [specialities, setSpecialities] = useState<
    { value: string; label: string; label_en: string }[]
  >([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [selectedEducationLevel, setSelectedEducationLevel] = useState<any>([]);
  const [selectedSubjects, setSelectedSubjects] = useState<any>([]);

  const [subjects, setSubjects] = useState<
    { value: string; label: string; label_en: string }[]
  >([]);
  const user = useAppSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const [currentDay, setCurrentDay] = useState({});

  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [openTimeModal, setOpenTimeModal] = useState(false);

  const daysOptions: { label: string; value: string }[] = [
    { label: t("sat"), value: "sat" },
    { label: t("sun"), value: "sun" },
    { label: t("mon"), value: "mon" },
    { label: t("tue"), value: "tue" },
    { label: t("wed"), value: "wed" },
    { label: t("thu"), value: "thu" },
    { label: t("fri"), value: "fri" },
  ];

  const majorOptions = [
    {
      value: "دبلوم متوسط",
      label: getLanguage() === "ar" ? "دبلوم متوسط" : "Intermediate Diploma",
    },

    {
      value: "دبلوم عالي",
      label: getLanguage() === "ar" ? "دبلوم عالي" : "High Diploma",
    },
    {
      value: "بكالوريوس",
      label: getLanguage() === "ar" ? "بكالوريوس" : "Bachelor",
    },
    { value: "ماجستير", label: getLanguage() === "ar" ? "ماجستير" : "Master" },
    { value: "دكتوراه", label: getLanguage() === "ar" ? "دكتوراه" : "PhD" },
  ];

  const [availableDays, setAvailableDays] = useState({
    sat: false,
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
  });

  //constants
  const targetOptions = [
    { label: t("primary"), value: 1 },
    { label: t("elementary"), value: 2 },
    { label: t("intermediate"), value: 3 },
    { label: t("secondary"), value: 4 },
    { label: t("university"), value: 5 },
  ];

  const bookingOptions = [
    { label: t("fifth_step.instant_booking"), value: "immediate_booking" },
    { label: t("fifth_step.chat_booking"), value: "booking_by_chat" },
    { label: t("both"), value: "both" },
  ];

  const studentOptions = [
    { label: t("male"), value: "male" },
    { label: t("female"), value: "female" },
    { label: t("both"), value: "both" },
  ];

  //library hooks
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  //effects hooks
  useEffect(() => {
    fetchUserInfo();
    fetchSpecialities();
    fetchSubjects();
  }, []);

  useEffect(() => {
    if (trainerData?.trainer?.bio) {
      setValue("bio", trainerData.trainer.bio);
    }
    if (trainerData?.trainer?.online_price) {
      setValue("online_price", trainerData.trainer.online_price);
    }
    if (trainerData?.trainer?.onsite_price) {
      setValue("onsite_price", trainerData.trainer.onsite_price);
    }
    if (trainerData?.trainer?.facebook) {
      setValue("facebook", trainerData.trainer.facebook);
    }
    if (trainerData?.trainer?.twitter) {
      setValue("twitter", trainerData.trainer.twitter);
    }
    if (trainerData?.trainer?.snapchat) {
      setValue("snapchat", trainerData.trainer.snapchat);
    }
    if (trainerData?.trainer?.youtube) {
      setValue("youtube", trainerData.trainer.youtube);
    }
    if (trainerData?.trainer?.instagram) {
      setValue("instagram", trainerData.trainer.instagram);
    }
    if (trainerData?.trainer?.linkedin) {
      setValue("linkedin", trainerData.trainer.linkedin);
    }

    if (trainerData?.trainer?.university_degree) {
      setValue("university_degree", trainerData.trainer.university_degree);
    }

    if (trainerData?.trainer?.target_type) {
      setValue("target_type", trainerData.trainer.target_type);
    }

    if (trainerData?.trainer?.target_type) {
      setValue("target_type", trainerData.trainer.target_type);
    }

    if (trainerData?.trainer?.speciality_id) {
      setValue("speciality_id", trainerData.trainer.speciality_id);
    }

    if (trainerData?.trainer?.years_of_expertise) {
      setValue("years_of_expertise", trainerData.trainer.years_of_expertise);
    }

    if (trainerData?.trainer?.booking_type) {
      setValue("booking_type", trainerData.trainer.booking_type);
    }
    if (trainerData?.trainer?.educational_levels) {
      // trainerData.trainer.educational_levels.forEach((element) => {
      //   setValue("educational_level", element.id);
      // });

      const selectedOptions = trainerData.trainer.educational_levels.map(
        (element) => ({
          value: element.id,
          label: t(element.name),
        })
      );
      setSelectedEducationLevel(selectedOptions);
    }

    if (trainerData?.trainer?.subjects) {
      const selectedOptions = trainerData.trainer.subjects.map((element) => ({
        value: element.id,
        label: element.name[getLanguage() === "ar" ? "ar" : "en"],
      }));

      setSelectedSubjects(selectedOptions);
    }

    if (
      trainerData?.trainer?.working_days &&
      trainerData?.trainer?.working_days.length > 0
    ) {
      const initialState = {
        sat: false,
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
      };

      const mappedState = trainerData.trainer.working_days.reduce(
        (acc, { day, from, to, id }) => {
          if (acc[day]) {
            acc[day] = [...acc[day], { from, to, id }];
          } else {
            acc[day] = [{ from, to, id }];
          }
          return acc;
        },
        initialState
      );

      setAvailableDays(mappedState);
    }
  }, [trainerData, setValue]);

  //methods
  const handleUploadVideo = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files) {
      return;
    }
    let url = URL.createObjectURL(files[0]);
    setIntro(files[0]);
  };

  const handleProfilePicture = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files) {
      return;
    }
    setProfilePicture(files[0]);
  };

  const handleTimeEdit = (day) => {
    setCurrentDay(day);
    setOpenTimeModal(true);
  };

  const saveTimeSlots = (day, timeData, unavailable = false) => {
    if (unavailable) {
      setAvailableDays({ ...availableDays, [day]: false });
      return;
    }

    setAvailableDays({ ...availableDays, [day]: timeData });
  };

  //Api calls

  const fetchSpecialities = async () => {
    try {
      const response = await trainerAPI.getSpecialities();

      if (response.data.metaData.key === "success") {
        setSpecialities(response.data.data);
      }
    } catch (error) {}
  };

  const fetchUserInfo = async () => {
    try {
      const response = await trainerAPI.getUserInfo();

      if (response.data.metaData.key === "success") {
        setTrainerData(response.data.data);
        if (response.data.data.trainer.avatar) {
          dispatch(setAvatar(response.data.data.trainer.avatar));
        }
      }
    } catch (error) {}
  };

  const fetchSubjects = async () => {
    try {
      const response = await trainerAPI.getSubjects();

      if (response.data.metaData.key === "success") {
        setSubjects(response.data.data);
      }
    } catch (error) {}
  };

  const formatSpecialityLabel = ({ label, label_en, value }) => {
    const displayedLabel = getLanguage() === "en" ? label_en : label;

    return <div>{displayedLabel}</div>;
  };

  const formatSubjectLabel = ({ label, label_en, value }) => {
    const displayedLabel = getLanguage() === "en" ? label_en : label;

    return <div>{displayedLabel}</div>;
  };

  const handleEducationSelect = (selectedOption) => {
    setSelectedEducationLevel(selectedOption);
  };

  const handleSubjectSelect = (selectedOption) => {
    setSelectedSubjects(selectedOption);
  };
  interface MyData {
    [key: string]: any; // allow any other keys with any values
  }

  const onSubmit = async (data: MyData) => {
    setSubmitLoading(true);

    if (selectedEducationLevel.length === 0) {
      toast.error(t("educational_level_required"));
      setSubmitLoading(false);
      return;
    }
    if (selectedSubjects.length === 0) {
      toast.error(t("subjects_required"));
      setSubmitLoading(false);
      return;
    }

    let workingDays: { day: string; value: boolean | IWorkingDay[] }[] = [];
    for (let [key, value] of Object.entries(availableDays)) {
      workingDays.push({ day: key, value: value });
    }
    data["working_days"] = workingDays;
    data["type"] = trainerData.trainer.type;
    data["update"] = true;
    data["educational_level"] = selectedEducationLevel;
    data["subjects"] = selectedSubjects;

    try {
      let payload = new FormData();

      for (let [key, value] of Object.entries(data)) {
        switch (key) {
          case "working_days":
            value.forEach((day) => {
              payload.append(`working_days[]`, JSON.stringify(day));
            });

            break;
          case "subjects":
            if (value instanceof Array) {
              let subjectsData: any[] = [];
              value.forEach((subject) => {
                subjectsData.push(subject.value);
              });
              payload.append(`subjects`, JSON.stringify(subjectsData));
            } else {
              payload.append(`subjects`, JSON.stringify(value));
            }

            break;

          case "educational_level":
            if (value.length > 0) {
              value.forEach((level) => {
                payload.append(
                  `educational_level[]`,
                  JSON.stringify(parseInt(level.value))
                );
              });
            }

            break;

          case "onsite_price":
            if (+value) {
              payload.append("onsite_price", value);
            } else {
              payload.append("onsite_price", "");
            }
            break;

          case "online_price":
            if (+value) {
              payload.append("online_price", value);
            } else {
              payload.append("online_price", "");
            }
            break;

          default:
            payload.append(key, value);
            break;
        }
      }

      if (intro) {
        payload.append(`intro`, intro);
      }

      if (profilePicture) {
        payload.append(`avatar`, profilePicture);
      }
      const response = await trainerAPI.setupAccount(payload, user.id);
      if (response.data.metaData.key === "success") {
        toast.success(t("added_successfully"));
        fetchUserInfo();
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <SettingsWrapper>
      <Title>{t("account_settings")}</Title>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <ImageContainer>
          {profilePicture ? (
            <ProfilePicture
              src={URL.createObjectURL(profilePicture)}
              alt='profilePicture'
            />
          ) : trainerData?.trainer?.avatar ? (
            <ProfilePicture
              src={trainerData.trainer.avatar}
              alt='profilePicture'
            />
          ) : (
            <Avatar width='120px' height='120px' />
          )}
          <input
            type='file'
            id='avatar'
            accept='image/*'
            onChange={handleProfilePicture}
          />
          <ImageMeta>
            <ChangeImage htmlFor='avatar'>
              {t("first_step.change_image")}
            </ChangeImage>
            <ImageSizes>
              <span>{t("first_step.photo_size")}</span>
              <span>120*120</span>
            </ImageSizes>
          </ImageMeta>
        </ImageContainer>
        <IntroContainer>
          <ImagesWrapper>
            <ImageUploader htmlFor='file'>
              <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
                <FiUploadCloud />
              </IconContext.Provider>
              <span>{t("intro")}</span>
              <h1>{t("select_from_device")}</h1>
            </ImageUploader>
            <ImageInput
              type='file'
              id='file'
              accept='video/*'
              onChange={handleUploadVideo}
              // {...register("files")}
            />
          </ImagesWrapper>
          {intro ? (
            <video
              className='react-player'
              src={URL.createObjectURL(intro)}
              width='100%'
              height='100%'
              controls={true}
            />
          ) : trainerData?.trainer?.intro ? (
            <video
              className='react-player'
              src={trainerData?.trainer?.intro}
              width='100%'
              height='100%'
              controls={true}
            />
          ) : null}
        </IntroContainer>
        <AppText
          register={register}
          errors={errors}
          placeholder={t("bio")}
          name='bio'
          mt='30px'
          variant='sm'
        />

        <div>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                isClearable
                value={majorOptions.find((c) => c.value === value)}
                name={name}
                options={majorOptions}
                placeholder={t("second_step.college_degree")}
                className='app-select'
                onChange={(selectedOption) => {
                  onChange(selectedOption?.value);
                }}
              />
            )}
            rules={{ required: trainerData?.trainer?.type === "academic" }}
            name={"university_degree"}
          />
          {errors?.university_degree && (
            <p className='error-message'>{t("university_degree_required")}</p>
          )}
        </div>

        <Controller
          control={control}
          rules={{ required: true }}
          name={"speciality_id"}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              ref={ref}
              isClearable
              formatOptionLabel={formatSpecialityLabel}
              placeholder={t("second_step.specialty")}
              value={specialities.find((c) => c.value === value)}
              onChange={(selectedOption) => {
                onChange(selectedOption?.value);
              }}
              options={specialities}
              className='app-select'
            />
          )}
        />
        {errors?.speciality_id && (
          <p className='error-message'>{t("speciality_required")}</p>
        )}
        <Controller
          control={control}
          // defaultValue={yearsOfExperienceValue}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              ref={ref}
              value={yearsOptions.find((c) => c.value === value)}
              isClearable
              name={name}
              options={yearsOptions}
              placeholder={t("second_step.years_of_expertise")}
              className='app-select'
              onChange={(selectedOption) => {
                onChange(selectedOption?.value);
              }}
            />
          )}
          rules={{ required: true }}
          name={"years_of_expertise"}
        />
        {errors?.years_of_expertise && (
          <p className='error-message'>{t("years_of_expertise_required")}</p>
        )}

        <Select
          isClearable
          isMulti
          placeholder={t("third_step.target_type")}
          // value={targetOptions.find((c) => c.value === value)}
          value={selectedEducationLevel}
          onChange={handleEducationSelect}
          options={targetOptions}
          className='app-select'
        />
        {errors?.educational_level && (
          <p className='error-message'>{t("target_level_required")}</p>
        )}

        <Controller
          control={control}
          rules={{ required: true }}
          name={"booking_type"}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              ref={ref}
              isClearable
              placeholder={t("fifth_step.booking_type")}
              value={bookingOptions.find((c) => c.value === value)}
              onChange={(selectedOption) => {
                onChange(selectedOption?.value);
              }}
              options={bookingOptions}
              className='app-select'
            />
          )}
        />
        {errors?.booking_type && (
          <p className='error-message'>{t("booking_type_required")}</p>
        )}

        <Controller
          control={control}
          rules={{ required: true }}
          name={"target_type"}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              ref={ref}
              isClearable
              placeholder={t("third_step.students_type")}
              value={studentOptions.find((c) => c.value === value)}
              onChange={(selectedOption) => {
                onChange(selectedOption?.value);
              }}
              options={studentOptions}
              className='app-select'
            />
          )}
        />
        {errors?.target_type && (
          <p className='error-message'>{t("target_gender_required")}</p>
        )}

        <CreatableSelect
          // ref={ref}
          // value={subjects.find((c) => c.value === value)}
          value={selectedSubjects}
          isMulti
          formatOptionLabel={formatSubjectLabel}
          onChange={handleSubjectSelect}
          options={subjects}
          className='app-select'
          placeholder={t("third_step.your_materials")}
        />
        {errors?.subjects && (
          <p className='error-message'>{t("subjects_required")}</p>
        )}

        <FormControl>
          <AppInput
            required={false}
            register={register}
            name='online_price'
            type='number'
            errors={errors}
            min={0}
          />
          <AppInput
            required={false}
            register={register}
            name='onsite_price'
            type='number'
            errors={errors}
            min={0}
          />
        </FormControl>
        <FormControl className='social-accounts'>
          <AppInput
            required={false}
            register={register}
            name='facebook'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='twitter'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='snapchat'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='youtube'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='instagram'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='linkedin'
            errors={errors}
          />
        </FormControl>

        <Label>{t("fifth_step.your_calendar")}</Label>

        <DaysContainer>
          {daysOptions.map((day) => (
            <Day
              key={day.value}
              active={
                availableDays[day.value] && availableDays[day.value].length > 0
              }
            >
              <div>
                <span>{day.label}</span>
                <button type='button' onClick={() => handleTimeEdit(day)}>
                  {t("edit")}
                </button>
              </div>
              {availableDays[day.value] ? (
                availableDays[day.value].length > 0 ? (
                  availableDays[day.value].map((period) => (
                    <p className='time-slot' key={period.id}>
                      <span>{period.from}</span>
                      {t("to")}
                      <span>{period.to}</span>
                    </p>
                  ))
                ) : (
                  ""
                )
              ) : (
                <p>{t("unavailable")}</p>
              )}
            </Day>
          ))}
        </DaysContainer>

        <AppButton
          size='lg'
          type='submit'
          disabled={submitLoading}
          loader={submitLoading}
        >
          {t("save")}
        </AppButton>
      </FormContainer>
      <EditDayModal
        open={openTimeModal}
        setOpen={setOpenTimeModal}
        currentDay={currentDay}
        saveTimeSlots={saveTimeSlots}
        timeData={availableDays}
      />
    </SettingsWrapper>
  );
};

export default Settings;

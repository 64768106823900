import styled from "styled-components/macro";
import { media } from "styles/utils";

export const AccountSetupWrapper = styled.div`
  margin-top: 118px;
  display: flex;
  gap: 30px;

  ${media("medium")} {
    flex-direction: column;
    margin-top: 50px;
  }
`;

import {
  Calendar,
  CalendarBlue,
  Chat,
  ChatBlue,
  Courses,
  CoursesBlue,
  DashboardAvatar,
  FreeLesson,
  FreeLessonBlue,
  Home,
  HomeBlue,
  Logout,
  Premium,
  Setting,
  SettingBlue,
  Wallet,
  WalletBlue,
} from "components/Icons";
import { ReactNode, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { FiStar } from "react-icons/fi";
import {
  LogoutButton,
  MenuContainer,
  MenuItem,
  MenuName,
  PremiumButton,
  PremiumContainer,
  RatingContainer,
  SideBarContainer,
  TeacherImage,
  TeacherInfoContainer,
  TeacherMajor,
  TeacherName,
} from "./sidebar.styles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { RiMenuUnfoldLine } from "react-icons/ri";
import { persistor, useAppSelector } from "store";

const menuItems: {
  label: string;
  link: string;
  icon: ReactNode;
  activeIcon: ReactNode;
}[] = [
  {
    label: "home",
    link: "/dashboard",
    icon: <Home className='inactive-icon' />,
    activeIcon: <HomeBlue className='active-icon' />,
  },
  {
    label: "appointments",
    link: "/dashboard/appointments",
    icon: <Calendar className='inactive-icon' />,
    activeIcon: <CalendarBlue className='active-icon' />,
  },
  {
    label: "messages",
    link: "/dashboard/messages",
    icon: <Chat className='inactive-icon' />,
    activeIcon: <ChatBlue className='active-icon' />,
  },
  {
    label: "courses",
    link: "/dashboard/courses",
    icon: <Courses className='inactive-icon' />,
    activeIcon: <CoursesBlue className='active-icon' />,
  },
  {
    label: "free_lessons",
    link: "/dashboard/free-lessons",
    icon: <FreeLesson className='inactive-icon' />,
    activeIcon: <FreeLessonBlue className='active-icon' />,
  },
  {
    label: "wallet",
    link: "/dashboard/wallet",
    icon: <Wallet className='inactive-icon' />,
    activeIcon: <WalletBlue className='active-icon' />,
  },
  {
    label: "alemni_packages",
    link: "/dashboard/packages",
    icon: <Home className='inactive-icon' />,
    activeIcon: <HomeBlue className='active-icon' />,
  },
  {
    label: "custom_packages",
    link: "/dashboard/custom-packages",
    icon: <Home className='inactive-icon' />,
    activeIcon: <HomeBlue className='active-icon' />,
  },
  {
    label: "settings",
    link: "/dashboard/settings",
    icon: <Setting className='inactive-icon' />,
    activeIcon: <SettingBlue className='active-icon' />,
  },
];

const Sidebar = ({ showSidebar, setShowSidebar }) => {
  //hooks
  const { t } = useTranslation();
  const location = useLocation();
  const sideBarRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.auth.user);
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  useEffect(() => {
    document.addEventListener("mousedown", handleProfileDropDownClick);

    return () => {
      document.removeEventListener("mousedown", handleProfileDropDownClick);
    };
  }, []);

  //functions

  const handleProfileDropDownClick = (e) => {
    if (sideBarRef.current?.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setShowSidebar(false);
  };

  const handleLogoutClick = () => {
    persistor.purge();
    localStorage.removeItem("persist:root");
    window.location.replace("/login");
  };

  return (
    <SideBarContainer ref={sideBarRef} open={showSidebar} isLtr={isLtr}>
      <IconContext.Provider value={{ color: "#FFf", size: "2em" }}>
        <RiMenuUnfoldLine
          className='sidebar-icon'
          onClick={() => setShowSidebar((prev) => !prev)}
        />
      </IconContext.Provider>
      <TeacherInfoContainer>
        <TeacherImage>
          {user.avatar ? (
            <img src={user.avatar} alt='user avatar' />
          ) : (
            <DashboardAvatar width='77px' height='77px' />
          )}
        </TeacherImage>
        <TeacherName>
          <span>{user.name}</span>
          <RatingContainer>
            <span>{user.rating}</span>
            <IconContext.Provider value={{ color: "#ffffff", size: ".7em" }}>
              <FiStar />
            </IconContext.Provider>
          </RatingContainer>
        </TeacherName>
        <TeacherMajor>{user?.speciality_name}</TeacherMajor>
      </TeacherInfoContainer>
      <MenuContainer>
        {menuItems.map((item, index) => (
          <MenuItem
            end
            key={index}
            to={item.link}
            active={item.link === location.pathname}
            state={{ test: "test" }}
          >
            {item.activeIcon}
            {item.icon}
            <MenuName>{t(item.label)}</MenuName>
          </MenuItem>
        ))}
      </MenuContainer>
      <LogoutButton onClick={() => handleLogoutClick()}>
        <Logout />
        <span>{t("logout")}</span>
      </LogoutButton>
      {/* <PremiumContainer>
        <Premium />
        <PremiumButton>{t("premium_account")}</PremiumButton>
      </PremiumContainer> */}
    </SideBarContainer>
  );
};

export default Sidebar;

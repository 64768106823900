import styled from "styled-components/macro";
import { media } from "styles/utils";

export const HomeContainer = styled.div``;
export const StatisticsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
`;
export const StatisticsItem = styled.div`
  background-color: #fff;
  padding: 20px;
  height: 175px;
  flex: 1;
  div {
    width: 39px;
    height: 39px;
    background: rgb(var(--off-white));
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${media("small")} {
    flex: unset;
  }
`;
export const StatisticsTitle = styled.p`
  font-size: 1.4rem;
  margin-top: 20px;
`;
export const StatisticsValue = styled.span`
  font-size: 2.7rem;
  font-weight: bold;
  display: block;
  margin-top: 25px;
  text-align: left;
`;

export const CalendarContainer = styled.div`
  margin-top: 30px;

  .fc-header-toolbar.fc-toolbar {
    .fc-toolbar-title {
      font-size: 2rem;
    }
    & .fc-toolbar-chunk:first-child > div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .fc-view-harness {
    background: #fff;
  }
  .fc-button {
    background: rgb(var(--main-color-light));
    border: none;
    &:hover,
    &:active {
      background: rgba(var(--main-color-light), 0.97) !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .fc-button-active {
    background: rgb(var(--off-white)) !important;
    color: rgb(var(--main-color-light)) !important;
    &:hover,
    &:active {
      background: rgb(var(--off-white)) !important;
    }
  }
  .fc-event-title {
    white-space: normal;
  }

  ${media("small")} {
    .fc .fc-toolbar.fc-header-toolbar {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
`;

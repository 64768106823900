import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { ModalBodyContainer, ModalTitle } from "./eventDataModal.styles";
import {
  DetailItem,
  DetailSection,
  DetailTitle,
  DetailValue,
  Details,
  Header,
  SectionTitle,
  StudentName,
} from "components/Dashboard/Appointments/SingleAppointment/singleAppointment.styles";
import { DashboardAvatar } from "components/Icons";
import AppLabel from "components/shared/AppLabel/AppLabel";
import format from "date-fns/format";
import { CourseImage } from "components/Dashboard/Courses/courses.styles";
import FreeLessonImage from "assets/images/free_lesson.png";

const EventDataModal = ({ setOpen, open, booking }) => {
  //hooks
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleCancelSubmit = () => {
    setOpen(false);
  };

  return (
    <Modal
      ref={modalRef}
      open={open}
      onClose={() => setOpen(false)}
      initialFocusRef={modalRef}
      showCloseIcon={true}
      closeOnOverlayClick={true}
      classNames={{
        root: "custom-root",
        overlay: "app-modal-overlay",
        modal: "app-modal review-app-modal",
        closeButton: "app-modal-close",
      }}
      center
    >
      {booking ? (
        <ModalBodyContainer>
          <ModalTitle>{booking?.title}</ModalTitle>
          <Header>
            {booking.session_type === "single" ? (
              <DashboardAvatar width='77px' height='77px' />
            ) : booking.session_type === "free_lesson" ? (
              <img
                src={FreeLessonImage}
                alt='free lesson'
                width='77px'
                height='77px'
              />
            ) : booking.session_type === "workshop" ? (
              <CourseImage
                src={booking?.event?.cover}
                width='77px'
                height='77px'
              />
            ) : null}

            <StudentName>
              {booking.session_type === "single"
                ? booking.trainee.name
                : booking.event?.title}
            </StudentName>
          </Header>
          <Details>
            <DetailSection>
              <SectionTitle>{t("booking_details")}</SectionTitle>
              <DetailItem>
                <DetailTitle>{t("booking_status")}</DetailTitle>
                <DetailValue>
                  <AppLabel
                    variation={booking.status}
                    text={t(booking.status)}
                  />
                </DetailValue>
              </DetailItem>
              {booking?.upcoming_session ? (
                <DetailItem>
                  <DetailTitle>{t("booking_date")}</DetailTitle>
                  <DetailValue>
                    {format(
                      new Date(booking?.upcoming_session),
                      "EEEE dd MMMM yyyy HH:mm:ss"
                    )}
                  </DetailValue>
                </DetailItem>
              ) : null}
              {/* <DetailItem>
                <DetailTitle>{t("booking_hours")}</DetailTitle>
                <DetailValue></DetailValue>
              </DetailItem> */}
              {/* <DetailItem>
                <DetailTitle>{t("lesson_duration")}</DetailTitle>
                <DetailValue></DetailValue>
              </DetailItem> */}
              <DetailItem>
                <DetailTitle>{t("lesson_loaction")}</DetailTitle>
                <DetailValue>{t(booking.attendance)}</DetailValue>
              </DetailItem>
            </DetailSection>
            {booking.session_type === "free_lesson" ? null : (
              <>
                <DetailSection>
                  <SectionTitle>{t("invoice")}</SectionTitle>
                  <DetailItem>
                    <DetailTitle>{t("price")}</DetailTitle>
                    <DetailValue>
                      <em>{booking.fee}</em>
                      {t("riyal")}
                    </DetailValue>
                  </DetailItem>
                  <DetailItem>
                    <DetailTitle>{t("vat")}</DetailTitle>
                    <DetailValue>
                      <em>16.80</em>
                      {t("riyal")}
                    </DetailValue>
                  </DetailItem>
                </DetailSection>
                <hr />
                <DetailSection>
                  <DetailItem>
                    <DetailTitle className='bold'>
                      {t("total_price")}
                    </DetailTitle>
                    <DetailValue>
                      <em>{+booking.fee + 16.8}</em>
                      {t("riyal")}
                    </DetailValue>
                  </DetailItem>
                </DetailSection>
              </>
            )}
          </Details>
        </ModalBodyContainer>
      ) : null}
    </Modal>
  );
};

export default EventDataModal;

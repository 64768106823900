import { ThanksBackground } from "components/Icons";
import AppButton from "components/shared/AppButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store";
import {
  ActionsContainer,
  MessageSubtitle,
  MessageTitle,
  ThanksWrapper,
  ThanksContent,
  CloseButton,
} from "./thanks.styles";

const Thanks = () => {
  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  //functions
  const handleButtonClick = () => {
    navigate("/");
  };
  return (
    <ThanksWrapper>
      <ThanksContent>
        <ActionsContainer>
          <MessageTitle>
            {user.in_certifying === "نعم"
              ? t("account_in_certificate")
              : t("thanks_title")}
          </MessageTitle>
          <MessageSubtitle>{t("thanks_subtitle")}</MessageSubtitle>
          <AppButton size='lg' mt='40px' onClick={() => handleButtonClick()}>
            {t("understand")}
          </AppButton>
          {/* <CloseButton>{t("close")}</CloseButton> */}
        </ActionsContainer>
        <ThanksBackground />
      </ThanksContent>
    </ThanksWrapper>
  );
};

export default Thanks;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAPI } from "services/apis/auth";
import { ILoginData, IVerificationData } from "types";

export const login = createAsyncThunk(
  "auth/login",
  async (data: ILoginData, { rejectWithValue }) => {
    try {
      const response = await authAPI.login(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
      // Throw the error response from the API
    }
  }
);

export const verify = createAsyncThunk(
  "auth/verify",
  async (data: IVerificationData) => {
    const response = await authAPI.verify(data);
    return response.data;
  }
);

interface User {
  avatar: string;
  bio: string;
  booking_type: string;
  educational_level: string;
  id: number;
  user_id: number;
  name: string;
  nationality: string;
  price_rate: string;
  rating: string;
  referral_code: string;
  speciality_name: string;
  target_type: string;
  teaching_type: string;
  token: string;
  type: string;
  working_days: Array<unknown>;
  years_of_expertise: string;
  in_certifying: string;
  is_certified: number;
  balance: number;
}
// Define a type for the slice state
interface IAuthState {
  user: Partial<User>;
  authenticated: boolean;
  isCertified: boolean;
  inCertifying: boolean;
  isVerified: boolean;
  token: string;
  errors: any;
}

// Define the initial state using that type
const initialState: IAuthState = {
  user: {},
  authenticated: false,
  isCertified: false,
  inCertifying: false,
  isVerified: false,
  token: "",
  errors: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSocialLogin: (state, action) => {
      state.user = action.payload;
      state.token = action.payload.token;
      state.authenticated = true;
      state.isCertified = action.payload.is_certified ? true : false;
      state.inCertifying = action.payload.in_certifying ? true : false;
      state.isVerified = action.payload.is_verified ? true : false;
    },

    setVerified: (state, action) => {
      state.isVerified = action.payload.is_verified ? true : false;
    },

    setIsCertified: (state) => {
      state.isCertified = true;
      state.inCertifying = false;
    },

    setAvatar: (state, action) => {
      state.user.avatar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.authenticated = true;
        state.token = action.payload.data.token;
        state.user = action.payload.data;
        state.isCertified = action.payload.data.is_certified ? true : false;
        state.inCertifying = action.payload.data.in_certifying ? true : false;
        state.isVerified = action.payload.data.is_verified ? true : false;
      })
      .addCase(login.pending, (state, action) => {})
      .addCase(login.rejected, (state, action) => {
        state.authenticated = false;
        state.isCertified = false;
        state.inCertifying = false;

        state.token = "";
        state.user = {};
      });

    builder
      .addCase(verify.fulfilled, (state, action) => {
        state.authenticated = true;
        state.isCertified = action.payload.data.is_certified ? true : false;
        state.inCertifying = action.payload.data.in_certifying ? true : false;
        state.isVerified = action.payload.data.is_verified ? true : false;

        state.token = action.payload.data.token;
        state.user = action.payload.data;
      })
      .addCase(verify.pending, (state, action) => {})
      .addCase(verify.rejected, (state, action) => {
        state.authenticated = false;
        state.isCertified = false;
        state.inCertifying = false;

        state.token = "";
        state.user = {};
      });
  },
});
export const { setIsCertified, setAvatar, setSocialLogin, setVerified } =
  authSlice.actions;

export const { reducer: authReducer } = authSlice;

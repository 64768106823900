import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  padding: 24px;
  max-width: 500px;
  width: 50vw;

  ${media("medium")} {
    width: 90vw;
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  gap: 10px;
`;

import AboutUsContent from "components/PagesContent/AboutUsContent";
import MainLayout from "layouts/MainLayout";

const AboutUsContainer = () => {
  return (
    <MainLayout>
      <AboutUsContent />
    </MainLayout>
  );
};

export default AboutUsContainer;

import DashboardHome from "components/Dashboard/Home";
import DashboardLayout from "layouts/DashboardLayout";

const DashboardContainer = () => {
  return (
    <DashboardLayout>
      <DashboardHome />
    </DashboardLayout>
  );
};

export default DashboardContainer;

import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "store";

const GuestOutlet = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);

  return !isAuthenticated ? ( //Check if logged in
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to='/' replace /> //Go back to login if not logged in
  );
};

export default GuestOutlet;

import WalletDetails from "components/Dashboard/WalletDetails";
import DashboardLayout from "layouts/DashboardLayout";

const WalletContainer = () => {
  return (
    <DashboardLayout>
      <WalletDetails />
    </DashboardLayout>
  );
};

export default WalletContainer;

import CheckoutForm from "components/Dashboard/CheckoutForm/CheckoutForm";
import DashboardLayout from "layouts/DashboardLayout";

const CheckoutContainer = () => {
  return (
    <DashboardLayout>
      <CheckoutForm />
    </DashboardLayout>
  );
};

export default CheckoutContainer;

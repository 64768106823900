import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "config/i18n";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import store, { persistor } from "store";
import { Toaster } from "react-hot-toast";
import Pusher from "pusher-js";
import TagManager from "react-gtm-module";
import { GoogleOAuthProvider } from "@react-oauth/google";

window["Pusher"] = Pusher;

const tagManagerArgs = {
  gtmId: "GTM-WX3R8HH",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <GoogleOAuthProvider clientId='206735028721-r6s8k37n9fq1kvquudbrocla2jj35ebi.apps.googleusercontent.com'>
          <App />
        </GoogleOAuthProvider>
        <Toaster />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "styled-components/macro";
import { space, fontSize, color } from "styled-system";

export const StyledButton = styled.button<{
  variant: "primary" | "outline" | "grey";
  size: string;
  loader: boolean;
  customColor: string;
}>`
  font-size: ${({ size }) => (size === "xs" ? "1.1rem" : "1.8rem")};
  padding: 20px;
  text-decoration: none;
  background: ${(props) =>
    props.customColor
      ? props.customColor
      : props.variant === "primary"
      ? "rgb(var(--main-color))"
      : props.variant === "outline"
      ? "rgb(var(--off-white))"
      : "white"};
  border: 1px solid;
  border-color: ${(props) =>
    props.variant === "primary"
      ? "transparent"
      : props.variant === "outline"
      ? "rgb(var(--main-color-lighter))"
      : "#e6eef9"};
  color: ${(props) =>
    props.variant === "primary"
      ? "white"
      : props.variant === "outline"
      ? "rgb(var(--main-color-lighter))"
      : "rgb(var(--dark-grey))"};

  display: block;
  width: ${({ size }) =>
    size === "lg"
      ? "100%"
      : size === "md"
      ? "50%"
      : size === "sm"
      ? "155px"
      : "87px"};
  cursor: ${(props) => (props.loader ? "not-allowed" : "pointer")};
  div.loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${space};
  ${fontSize};
  ${color}
`;

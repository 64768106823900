import AppButton from "components/shared/AppButton";
import AppInput from "components/shared/AppInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AddPackageTitle,
  AddPackageWrapper,
  InputGroup,
  InputsWrapper,
} from "./trainerPackages.styles";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import { useAppSelector } from "store";
import { trainerAPI } from "services/apis/trainer";
import toast from "react-hot-toast";

const EditPackage = () => {
  //state
  const user = useAppSelector((state) => state.auth.user);
  const [packageData, setPackageData] = useState<any>({} as any);
  //hooks
  const navigate = useNavigate();
  let { id: packageId } = useParams();

  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchPackage();
  }, []);

  //functions

  const fetchPackage = async () => {
    try {
      const response = await trainerAPI.getTrainerPackage(user.id, packageId);
      if (response.data.metaData.key === "success") {
        setPackageData(response.data.data);
      }
    } catch (error) {}
  };

  const onSubmit = async (data) => {
    try {
      setSubmitLoading(true);
      let payload = {
        fee: data.fee,
        hours: data.hours_num,
      };
      const response = await trainerAPI.editTrainerPackages(
        packageId,
        user.id,
        payload
      );

      if (response.data.metaData.key === "success") {
        navigate("/dashboard/custom-packages");

        toast.success(t("edited_successfully"));
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <AddPackageWrapper onSubmit={handleSubmit(onSubmit)}>
      <AddPackageTitle> {t("edit_package")}</AddPackageTitle>
      <InputsWrapper>
        <InputGroup>
          <AppInput
            register={register}
            name='fee'
            errors={errors}
            type='number'
            defaultValue={packageData?.fee}
          />
          <AppInput
            register={register}
            name='hours_num'
            errors={errors}
            type='number'
            defaultValue={packageData?.hours}
          />
        </InputGroup>
      </InputsWrapper>

      <AppButton size='lg' type='submit' loader={submitLoading}>
        {t("update")}
      </AppButton>
    </AddPackageWrapper>
  );
};

export default EditPackage;

import AppButton from "components/shared/AppButton";
import AppInput from "components/shared/AppInput";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import {
  ActionsContainer,
  FormContainer,
  ModalBodyContainer,
  ModalTitle,
} from "./chooseBankModal.styles";

const ChooseBankModal = ({
  setOpen,
  open,
  handleSubmitBank,
  banks,
  loading,
}) => {
  //hooks
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  //functions

  return (
    <Modal
      ref={modalRef}
      open={open}
      onClose={() => setOpen(false)}
      initialFocusRef={modalRef}
      showCloseIcon={true}
      closeOnOverlayClick={true}
      classNames={{
        root: "custom-root",
        overlay: "app-modal-overlay",
        modal: "app-modal review-app-modal",
        closeButton: "app-modal-close",
      }}
      center
    >
      <ModalBodyContainer>
        <ModalTitle>{t("edit_bank_modal")}</ModalTitle>
        <FormContainer onSubmit={handleSubmit(handleSubmitBank)}>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                value={banks.find((c) => c.value === value)}
                name={name}
                options={banks}
                placeholder={t("choose_bank")}
                className='app-select'
                onChange={(selectedOption) => {
                  onChange(selectedOption?.value);
                }}
              />
            )}
            rules={{ required: true }}
            name={"bank_id"}
          />
          {errors?.bank_name && (
            <p className='error-message'>اسم البنك مطلوب</p>
          )}
          <AppInput register={register} errors={errors} name='holder_name' />
          <AppInput register={register} errors={errors} name='account_number' />
          <AppInput register={register} errors={errors} name='iban' />
          <AppButton size='lg' mt='10px' type='submit' loader={loading}>
            {t("save")}
          </AppButton>
        </FormContainer>
      </ModalBodyContainer>
    </Modal>
  );
};

export default ChooseBankModal;

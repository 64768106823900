import { AccountSetupWrapper } from "components/AccountSetup/accountsetup.styles";
import Stepper from "components/AccountSetup/Stepper";
import Steps from "components/AccountSetup/Steps";
import MainLayout from "layouts/MainLayout";
import { useState } from "react";

const AccountSetupContainer = () => {
  const [formStep, setFormStep] = useState(0);

  const nextFormStep = () => {
    if (formStep === 4) return;
    setFormStep((currentStep) => currentStep + 1);
  };
  const prevFormStep = () => {
    if (formStep === 0) return;

    setFormStep((currentStep) => currentStep - 1);
  };
  return (
    <MainLayout>
      <AccountSetupWrapper>
        <Stepper formStep={formStep} />
        <Steps
          formStep={formStep}
          nextFormStep={nextFormStep}
          prevFormStep={prevFormStep}
        />
      </AccountSetupWrapper>
    </MainLayout>
  );
};

export default AccountSetupContainer;

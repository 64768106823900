import axios from "axios";
import React, { useEffect, useReducer, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import { IAccountFormState } from "types";
import AvailableTimes from "./AvailableTimes";
import BasicInformation from "./BasicInformation";
import Contract from "./Contract";
import Education from "./Education";
import { StepsContainer } from "./steps.styles";
import TeachingSpecifications from "./TeachingSpecifications";
import { useTranslation } from "react-i18next";

const initialFormState: IAccountFormState = {
  city: "",
  country: "",
  type: "academic",
  bio: "",
  speciality_id: "",
  years_of_expertise: "",
  certificates_files: [],
  target_type: "",
  educational_level: [],
  subjects: [],
  online_price: "",
  onsite_price: "",
  booking_type: "booking_by_chat",
  working_days: [],
  university_degree: "",
};

export enum ActionKind {
  AddData = "ADD_DATA",
}

export type accountSetupActions = {
  type: ActionKind;
  payload: Object;
};

function formReducer(
  state: IAccountFormState,
  action: accountSetupActions
): IAccountFormState {
  const { type, payload } = action;

  switch (type) {
    case ActionKind.AddData:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
const Steps = ({ nextFormStep, prevFormStep, formStep }) => {
  //hooks
  const [formData, dispatch] = useReducer(formReducer, initialFormState);
  const user = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([]);
  useEffect(() => {
    getCountries();
  }, []);

  const navigate = useNavigate();

  //functions
  const getCountries = async () => {
    try {
      const response = await axios.get(
        "https://countriesnow.space/api/v0.1/countries/iso"
      );

      let data = response.data.data.map((country) => ({
        label: country.name,
        value: country.name,
      }));

      setCountries(data);
    } catch (error) {}
  };

  const handleSubmitForm = async () => {
    setSubmitLoading(true);
    try {
      let payload = new FormData();
      for (let [key, value] of Object.entries(formData)) {
        switch (key) {
          case "certificates_files":
            value.forEach((file) => {
              payload.append("certificates_files[]", file);
            });

            break;
          case "working_days":
            value.forEach((day) => {
              payload.append(`working_days[]`, JSON.stringify(day));
            });

            break;
          case "subjects":
            payload.append(`subjects`, JSON.stringify(value));

            break;

          case "educational_level":
            if (value.length > 0) {
              value.forEach((level) => {
                payload.append(
                  `educational_level[]`,
                  JSON.stringify(parseInt(level))
                );
              });
            }

            break;

          case "intro":
            if (value) {
              payload.append(`intro`, value);
            }
            break;

          case "onsite_price":
            if (value) {
              payload.append("onsite_price", value);
            }
            break;
          case "online_price":
            if (value) {
              payload.append("online_price", value);
            }
            break;
          case "avatar":
            if (value) {
              payload.append(`avatar`, value);
            }
            break;

          case "signature":
            payload.append(`signature`, value);
            break;

          default:
            payload.append(key, value);
            break;
        }
      }

      const response = await trainerAPI.setupAccount(payload, user.id);
      if (response.data.metaData.key === "success") {
        navigate("/thanks");
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
    }
  };
  return (
    <StepsContainer>
      {formStep === 0 && (
        <BasicInformation
          nextFormStep={nextFormStep}
          formStep={formStep}
          formData={formData}
          dispatch={dispatch}
          countries={countries}
        />
      )}
      {formStep === 1 && (
        <Education
          prevFormStep={prevFormStep}
          nextFormStep={nextFormStep}
          formStep={formStep}
          formData={formData}
          dispatch={dispatch}
        />
      )}
      {formStep === 2 && (
        <TeachingSpecifications
          prevFormStep={prevFormStep}
          nextFormStep={nextFormStep}
          formStep={formStep}
          formData={formData}
          dispatch={dispatch}
        />
      )}

      {formStep === 3 && (
        <AvailableTimes
          prevFormStep={prevFormStep}
          nextFormStep={nextFormStep}
          formStep={formStep}
          formData={formData}
          dispatch={dispatch}
        />
      )}
      {formStep === 4 && (
        <Contract
          prevFormStep={prevFormStep}
          nextFormStep={nextFormStep}
          formStep={formStep}
          formData={formData}
          dispatch={dispatch}
          handleSubmitForm={handleSubmitForm}
          submitLoading={submitLoading}
        />
      )}
    </StepsContainer>
  );
};

export default Steps;

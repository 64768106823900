import AppButton from "components/shared/AppButton";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FiArrowRight } from "react-icons/fi";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { authAPI } from "services/apis/auth";
import { useAppDispatch } from "store";
import { verify } from "store/slices/auth";
import {
  OtpFormContainer,
  OtpTitle,
  OtpSubTitle,
  ResendButton,
  FormContainer,
  ForgetPasswordTitleContainer,
  BackButton,
} from "./auth.styles";

const OtpForm = ({ setShowOtpForm, mobileNumber, source }) => {
  //hooks
  const [otp, setOtp] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //functions

  const handleOtpChange = (e) => {
    setOtp(e);
  };

  const handleVerifyClick = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    dispatch(verify({ code: otp, mobile: mobileNumber }))
      .unwrap()
      .then((e) => navigate("/account-setup"))
      .catch((error) => toast.error(t("error_message")))
      .finally(() => setSubmitLoading(false));
  };

  const handleResendClick = async (e) => {
    e.preventDefault();
    try {
      const response = await authAPI.resend({ mobile: mobileNumber });
      if (response.data.metaData.key === "success") {
        toast.success(t("resend_successfully"));
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };
  return (
    <OtpFormContainer>
      <ForgetPasswordTitleContainer>
        <BackButton
          onClick={() => {
            setShowOtpForm(false);
          }}
        >
          <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
            <FiArrowRight />
          </IconContext.Provider>
        </BackButton>
        <OtpTitle>{t("verification_code")}</OtpTitle>
      </ForgetPasswordTitleContainer>
      <OtpSubTitle>
        {t("verification_code_was_sent")}
        <span>{mobileNumber}</span>
      </OtpSubTitle>

      <FormContainer>
        <OtpInput
          value={otp}
          onChange={(e) => handleOtpChange(e)}
          numInputs={6}
          separator={<span> </span>}
          containerStyle='otp-container'
          inputStyle='otp-input'
          isInputNum={true}
        />
        <AppButton
          size='lg'
          type='button'
          onClick={handleVerifyClick}
          loader={submitLoading}
        >
          {t("verify")}
        </AppButton>
        <ResendButton onClick={handleResendClick}>{t("resend")}</ResendButton>
      </FormContainer>
    </OtpFormContainer>
  );
};

export default OtpForm;

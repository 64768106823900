import AppButton from "components/shared/AppButton";
import { useTranslation } from "react-i18next";

import AppLabel from "components/shared/AppLabel";
import {
  ButtonWrapper,
  CourseContent,
  CourseMeta,
  CourseMetaWrapper,
  CoursesHeader,
  CoursesItem,
  CoursesItems,
  CoursesListContainer,
  CourseTitle,
  Info,
  ItemFooter,
  MetaTitle,
  MetaValue,
  Title,
} from "../Courses/courses.styles";
import { LessonThumbnail, LessonUrl } from "./freeLessons.styles";
import { useNavigate } from "react-router-dom";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import { useEffect, useState } from "react";
import { IEvent } from "types";
import format from "date-fns/format";
import EmptyLessons from "./EmptyLessons";
import LessonUrlModal from "components/Modals/LessonUrlModal";
import arLocale from "date-fns/locale/ar-SA";
import enLocale from "date-fns/locale/en-US";

const FreeLessonsList = () => {
  //hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [lessons, setLessons] = useState<IEvent[]>([]);
  const user = useAppSelector((state) => state.auth.user);
  const [openModal, setOpenModal] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<any>({});
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  useEffect(() => {
    fetchLessons();
  }, []);

  //functions
  const handleLessonUrlClick = (lesson) => {
    setSelectedLesson({ ...lesson });
    setOpenModal(true);
  };

  //API calls
  const fetchLessons = async () => {
    try {
      const response = await trainerAPI.getEvents(user.id, "free_lesson");

      if (response.data.metaData.key === "success") {
        setLessons(response.data.data.data);
      }
    } catch (error) {}
  };
  return (
    <CoursesListContainer>
      {lessons.length > 0 ? (
        <>
          <CoursesHeader>
            <Title>{t("free_lessons")}</Title>
            <ButtonWrapper>
              <AppButton
                onClick={() => {
                  navigate("/dashboard/free-lessons/add");
                }}
                size='lg'
                p='14px'
                fontSize='11px'
                backgroundColor='#434db4'
              >
                {t("add_free_lesson")}
              </AppButton>
            </ButtonWrapper>
          </CoursesHeader>

          <CoursesItems>
            {lessons.length > 0 &&
              lessons.map((lesson) => (
                <CoursesItem key={lesson.id}>
                  <CourseContent>
                    <LessonThumbnail>
                      <span>
                        {format(new Date(lesson.starts_at), "EEEE", {
                          locale: isLtr ? enLocale : arLocale,
                        })}
                      </span>
                      <h1>
                        {format(new Date(lesson.starts_at), "dd", {
                          locale: isLtr ? enLocale : arLocale,
                        })}
                      </h1>
                      <p>
                        {format(new Date(lesson.starts_at), "MMMM yyyy", {
                          locale: isLtr ? enLocale : arLocale,
                        })}
                      </p>
                    </LessonThumbnail>
                    <Info isLtr={isLtr}>
                      <CourseTitle>{lesson.title}</CourseTitle>
                      <CourseMetaWrapper>
                        <CourseMeta>
                          <MetaTitle> {t("allowed_number")}</MetaTitle>
                          <MetaValue>{lesson.max_attendees_number}</MetaValue>
                        </CourseMeta>
                        <CourseMeta>
                          <MetaTitle> {t("reserved_number")}</MetaTitle>
                          <MetaValue>{lesson.number_of_bookings}</MetaValue>
                        </CourseMeta>
                        <CourseMeta>
                          <MetaTitle> {t("lesson_time")}</MetaTitle>
                          <MetaValue>
                            {format(new Date(lesson.starts_at), "HH:mm")}
                          </MetaValue>
                        </CourseMeta>
                      </CourseMetaWrapper>
                    </Info>
                    <AppLabel
                      variation='upcoming'
                      size='md'
                      text={t(lesson.status)}
                    />
                  </CourseContent>
                  <ItemFooter>
                    {lesson.attendance === "online" ? (
                      <LessonUrl
                        isLtr={isLtr}
                        onClick={() => handleLessonUrlClick(lesson)}
                      >
                        {t("lesson_url")}
                      </LessonUrl>
                    ) : null}
                  </ItemFooter>
                </CoursesItem>
              ))}
          </CoursesItems>
        </>
      ) : (
        <EmptyLessons />
      )}

      <LessonUrlModal
        open={openModal}
        setOpen={setOpenModal}
        lessonUrl={selectedLesson.session_url}
        bookingId={selectedLesson.id}
        fetchResources={fetchLessons}
        type='event'
      />
    </CoursesListContainer>
  );
};

export default FreeLessonsList;

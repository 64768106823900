import TermsContent from "components/PagesContent/TermsContent";
import MainLayout from "layouts/MainLayout";

const TermsContainer = () => {
  return (
    <MainLayout>
      <TermsContent />
    </MainLayout>
  );
};

export default TermsContainer;

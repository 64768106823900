import TrainerPackages from "components/Dashboard/TrainerPackages";
import DashboardLayout from "layouts/DashboardLayout";

const TrainerPackagesContainer = () => {
  return (
    <DashboardLayout>
      <TrainerPackages />
    </DashboardLayout>
  );
};

export default TrainerPackagesContainer;

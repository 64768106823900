import { get, post } from "config/axios";
import {
  ILoginData,
  IRegisterForm,
  IRegisterFormSocial,
  IVerificationData,
} from "types";

export const authAPI = {
  register: (data: IRegisterForm | IRegisterFormSocial) =>
    post("auth/register", data),
  login: (data: ILoginData) => post("auth/login", data),
  verify: (data: IVerificationData) => post("verification/verify", data),
  resend: (data) => post("verification/resend", data),
  forgetPassword: (data) => post("forget-password/setup", data),
  resetPassword: (data) => post("forget-password/reset", data),
  getProfile: () => get("users/me"),
  sendCode: (data) => post("send-code", data),
};

import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const LogoContainer = styled(Link)`
  cursor: pointer;
  display: block;
`;
export const MenuContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  ${media("medium")} {
    display: none;
  }
`;
export const MenuLink = styled(Link)`
  font-size: 1.4rem;
  text-align: right;
  color: rgb(var(--text-main));
  text-decoration: none;
  position: relative;
  padding-bottom: 5px;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scale(0);
    transition: all 0.3s;
    height: 2px;
    bottom: 0;
    right: 0;
    background: rgb(var(--text-main));
  }
  &:hover {
    &::after {
      transform: scale(1);
    }
  }
`;
export const LeftMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  ${media("medium")} {
    display: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 16px 11px 16px;
  border: solid 1px rgb(var(--border-dark));
  background-color: #fff;
  color: rgb(var(--text-menu));
  font-size: 1.4rem;
  height: 39px;

  &.mobile {
    margin-top: 20px;
  }
`;

export const StyledBurger = styled.div<{
  open: boolean;
  // dir: "rtl" | "ltr";
}>`
  width: 3rem;
  height: 3rem;
  position: fixed;
  top: 15px;
  left: 20px;
  z-index: 20000000;
  display: none;

  &.ltr {
    left: unset;
    right: 20px;
  }
  ${media("medium")} {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  div {
    width: 3rem;
    height: 0.3rem;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    cursor: pointer;
    background-color: rgb(var(--border-dark));
    &:nth-child(1) {
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${(props) =>
        props.open ? "translateX(100%)" : "translateX(0)"};
      opacity: ${(props) => (props.open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const MobileSidebarWrapper = styled.div<{
  open: boolean;
}>`
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  box-shadow: 0 22px 66px 0 rgba(203, 194, 213, 0.31);
  background: #fff;
  z-index: 100;

  &.ltr {
    left: unset;
    right: 0;
  }

  ${media("medium")} {
    display: ${(props) => (props.open ? "flex" : "none")};
    flex-direction: column;
    align-items: center;
  }
`;

export const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
  width: 100%;
  gap: 30px;
`;
export const SocialContainer = styled.div`
  display: flex;

  margin-top: 50px;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
`;

export const LanguageSwitcher = styled.div`
  margin-top: 100px;
`;

export const LogoutButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  ${media("medium")} {
    margin-top: 20px;
  }
`;
export const LanguageButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

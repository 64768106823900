import AppButton from "components/shared/AppButton";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { trainerAPI } from "services/apis/trainer";
import { ModalTitle } from "../EditDayModal/editDayModal.styles";
import {
  ModalBodyContainer,
  PlaceImg,
  PlaceInfo,
  PlaceItem,
  PlaceLocation,
  PlaceMapButton,
  PlaceName,
  PlacesContainer,
} from "./locationSuggestionsModal.styles";
import Select from "react-select";
import toast from "react-hot-toast";

const LocationSuggestionsModal = ({ setOpen, open, setSelectedPlace }) => {
  //hooks
  const modalRef = useRef(null);
  const [cities, setCities] = useState<{ value: number; label: string }[]>();
  const [places, setPlaces] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    fetchCites();
  }, []);
  //functions

  const fetchCites = async () => {
    try {
      const response = await trainerAPI.getCities();

      if (response.data.metaData.key === "success") {
        setCities(response.data.data);
      }
    } catch (error) {}
  };
  const fetchPlaces = async (cityId) => {
    try {
      const response = await trainerAPI.getPlaces(cityId);
      if (response.data.metaData.key === "success") {
        setPlaces(response.data.data);
      }
    } catch (error) {}
  };

  const handleCityChange = (city) => {
    fetchPlaces(city.value);
  };
  const handlePlaceChange = (placeId) => {
    setSelectedId(placeId);
  };
  const handleSelectPlace = (e) => {
    e.preventDefault();
    if (places.filter((place) => place.id === selectedId).length > 0) {
      setSelectedPlace({
        ...places.filter((place) => place.id === selectedId)[0],
      });
      setOpen(false);
      return;
    }

    toast.error(t("place_select_error"));
  };
  return (
    <Modal
      ref={modalRef}
      open={open}
      onClose={() => setOpen(false)}
      initialFocusRef={modalRef}
      showCloseIcon={true}
      closeOnOverlayClick={true}
      classNames={{
        root: "custom-root",
        overlay: "app-modal-overlay",
        modal: "app-modal review-app-modal",
        closeButton: "app-modal-close",
      }}
      center
    >
      <ModalBodyContainer>
        <ModalTitle>{t("location_suggestions_title")}</ModalTitle>
        <Select
          // value={cities?.find((c) => c.value === value)}
          options={cities}
          placeholder={t("choose_city")}
          className='app-select'
          onChange={handleCityChange}
        />

        <PlacesContainer>
          {places.length > 0 &&
            places.map((place) => (
              <PlaceItem key={place?.id}>
                <PlaceInfo>
                  <PlaceImg src='' />
                  <div>
                    <PlaceName>{place.name}</PlaceName>
                    <PlaceLocation>{place.city_name}</PlaceLocation>
                    <PlaceMapButton>{t("show_on_map")}</PlaceMapButton>
                  </div>
                </PlaceInfo>
                <input
                  type='radio'
                  name=''
                  checked={place?.id === selectedId}
                  onChange={() => handlePlaceChange(place.id)}
                />
              </PlaceItem>
            ))}
        </PlacesContainer>

        <AppButton size='sm' mt='10px' onClick={handleSelectPlace}>
          {t("select")}
        </AppButton>
      </ModalBodyContainer>
    </Modal>
  );
};

export default LocationSuggestionsModal;

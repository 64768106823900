import AppButton from "components/shared/AppButton";
import AppInput from "components/shared/AppInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AddPackageTitle,
  AddPackageWrapper,
  InputGroup,
  InputsWrapper,
} from "./trainerPackages.styles";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "store";
import { trainerAPI } from "services/apis/trainer";
import toast from "react-hot-toast";

const AddPackage = () => {
  //state
  const user = useAppSelector((state) => state.auth.user);

  //hooks
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //functions

  const onSubmit = async (data) => {
    try {
      setSubmitLoading(true);
      let payload = {
        fee: data.fee,
        hours: data.hours_num,
      };
      const response = await trainerAPI.addTrainerPackages(user.id, payload);
      if (response.data.metaData.key === "success") {
        navigate("/dashboard/custom-packages");

        toast.success(t("added_successfully"));
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <AddPackageWrapper onSubmit={handleSubmit(onSubmit)}>
      <AddPackageTitle> {t("add_package")}</AddPackageTitle>
      <InputsWrapper>
        <InputGroup>
          <AppInput
            register={register}
            name='fee'
            errors={errors}
            type='number'
          />
          <AppInput
            register={register}
            name='hours_num'
            errors={errors}
            type='number'
          />
        </InputGroup>
      </InputsWrapper>

      <AppButton size='lg' type='submit' loader={submitLoading}>
        {t("save")}
      </AppButton>
    </AddPackageWrapper>
  );
};

export default AddPackage;

import styled from "styled-components/macro";
import { space, fontSize, color } from "styled-system";

export const InputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: rgb(var(--grey));
    transition: all 0.4s;
  }

  input:focus-visible + label,
  input:not(:placeholder-shown) + label {
    top: 0;
    color: rgb(var(--main-color-lighter));
    font-size: 1.1rem;

    &:after {
      content: " ";
      background-color: #fff;
      width: 100%;
      height: 13px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  span {
    position: absolute;
    display: block;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;

    color: rgb(var(--black));
    font-weight: bold;
  }

  p {
    color: red;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 1.1rem;
    &.long_text {
      bottom: -30px;
    }
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 50px;
  font-size: 1.6rem;
  color: rgb(var(--black));
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 10px 14px;
  &:focus-visible {
    outline: none;
    border: 3px solid rgb(var(--main-color-lighter));
  }
  ${space};
  ${fontSize};
  ${color}
`;

import {
  ActionButtonsContainer,
  CertificatesContainer,
  EducationContainer,
  FormContainer,
  ImageInput,
  ImageUploader,
  Label,
  SubTitle,
  Title,
  UploadedImage,
  ImagesWrapper,
  ImageWrapper,
  DeleteImage,
} from "./education.styles";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import AppButton from "components/shared/AppButton";
import { FiUploadCloud } from "react-icons/fi";
import { IconContext } from "react-icons";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { IAccountFormState } from "types";
import { accountSetupActions, ActionKind } from "../Steps";
import { trainerAPI } from "services/apis/trainer";
import { Pdf } from "components/Icons";

const yearsOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "> 10 ", value: "10" },
];

interface educationProps {
  nextFormStep: () => void;
  prevFormStep: () => void;
  formStep: Number;
  formData: IAccountFormState;
  dispatch: React.Dispatch<accountSetupActions>;
}

const Education = ({
  nextFormStep,
  prevFormStep,
  formStep,
  formData,
  dispatch,
}: educationProps) => {
  //hooks
  const [certificatesFiles, setCertificatesFiles] = useState<File[]>([]);
  const [specialities, setSpecialities] = useState<
    { value: string; label: string; label_en: string }[]
  >([]);
  const { t, i18n } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const majorOptions = [
    {
      value: "دبلوم متوسط",
      label: getLanguage() === "ar" ? "دبلوم متوسط" : "Intermediate Diploma",
    },

    {
      value: "دبلوم عالي",
      label: getLanguage() === "ar" ? "دبلوم عالي" : "High Diploma",
    },
    {
      value: "بكالوريوس",
      label: getLanguage() === "ar" ? "بكالوريوس" : "Bachelor",
    },
    { value: "ماجستير", label: getLanguage() === "ar" ? "ماجستير" : "Master" },
    { value: "دكتوراه", label: getLanguage() === "ar" ? "دكتوراه" : "PhD" },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (formData?.certificates_files?.length > 0) {
      setCertificatesFiles(formData.certificates_files);
    }
    fetchSpecialities();
  }, []);

  //api requests

  const fetchSpecialities = async () => {
    try {
      const response = await trainerAPI.getSpecialities();

      if (response.data.metaData.key === "success") {
        setSpecialities(response.data.data);
      }
    } catch (error) {}
  };
  //functions
  const handleUploadImages = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && files.length) {
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/gif",
        "application/pdf",
      ];
      const selectedFiles = Array.from(files).filter((file) =>
        allowedTypes.includes(file.type)
      );

      if (selectedFiles.length !== files.length) {
        alert("Only image and PDF files are allowed.");
        return;
      }
      setCertificatesFiles((existing) => existing.concat(Array.from(files)));
    }
  };

  const handleDeleteImage = (data) => {
    setCertificatesFiles(
      certificatesFiles.filter((file) => file.name !== data.name)
    );
  };

  const handleNextClick = (data) => {
    if (certificatesFiles.length === 0) {
      toast.error(t("upload_certificates_message"));
      return;
    }

    dispatch({
      type: ActionKind.AddData,
      payload: { ...data, certificates_files: certificatesFiles },
    });
    nextFormStep();
  };

  const handlePrevClick = () => {
    prevFormStep();
  };

  const formatSpecialityLabel = ({ label, label_en, value }) => {
    const displayedLabel = getLanguage() === "en" ? label_en : label;

    return <div>{displayedLabel}</div>;
  };

  return (
    <EducationContainer>
      <SubTitle>{t("second_step.subtitle")}</SubTitle>
      <Title>{t("second_step.title")}</Title>
      <FormContainer onSubmit={handleSubmit(handleNextClick)}>
        <div>
          <Controller
            control={control}
            defaultValue={
              formData.university_degree ? formData.university_degree : null
            }
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                value={majorOptions.find((c) => c.value === value)}
                name={name}
                options={majorOptions}
                placeholder={t("second_step.college_degree")}
                className='app-select'
                onChange={(selectedOption) => {
                  onChange(selectedOption?.value);
                }}
              />
            )}
            rules={{ required: formData.type === "academic" }}
            name={"university_degree"}
          />
          {errors?.university_degree && (
            <p className='error-message'>الشهادة الجامعية مطلوب</p>
          )}
        </div>

        <Controller
          control={control}
          rules={{ required: true }}
          name={"speciality_id"}
          defaultValue={formData.speciality_id ? formData.speciality_id : null}
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              formatOptionLabel={formatSpecialityLabel}
              ref={ref}
              isClearable
              placeholder={t("second_step.specialty")}
              onChange={(selectedOption) => {
                onChange(selectedOption?.value);
              }}
              // onInputChange={(selectedOption) => {
              //   onChange(selectedOption);
              // }}
              //  onInputChange={this.handleInputChange}
              options={specialities}
              className='app-select'
            />
          )}
        />
        {errors?.speciality_id && <p className='error-message'>التخصص مطلوب</p>}
        <Controller
          control={control}
          defaultValue={
            formData.years_of_expertise ? formData.years_of_expertise : null
          }
          render={({ field: { onChange, value, name, ref } }) => (
            <Select
              ref={ref}
              value={yearsOptions.find((c) => c.value === value)}
              name={name}
              options={yearsOptions}
              placeholder={t("second_step.years_of_expertise")}
              className='app-select'
              onChange={(selectedOption) => {
                onChange(selectedOption?.value);
              }}
            />
          )}
          rules={{ required: true }}
          name={"years_of_expertise"}
        />
        {errors?.years_of_expertise && (
          <p className='error-message'>عدد سنوات الخبرة مطلوبة</p>
        )}

        <CertificatesContainer>
          <Label>{t("second_step.your_certificates")}</Label>
          <ImagesWrapper>
            <ImageUploader htmlFor='file'>
              <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
                <FiUploadCloud />
              </IconContext.Provider>
              <span>{t("second_step.certificate_image")}</span>
              <h1>{t("select_from_device")}</h1>
            </ImageUploader>
            <ImageInput
              type='file'
              id='file'
              multiple
              onChange={handleUploadImages}
              accept='image/png,image/jpeg,image/gif,application/pdf'
              // {...register("files")}
            />
            {certificatesFiles.length !== 0 &&
              certificatesFiles.map((file, index) => (
                <ImageWrapper key={file.name + index}>
                  {file.type === "application/pdf" ? (
                    <Pdf width='50' />
                  ) : (
                    <UploadedImage
                      key={index}
                      src={URL.createObjectURL(file)}
                      alt='image'
                    />
                  )}
                  <DeleteImage
                    type='button'
                    onClick={() => handleDeleteImage(file)}
                  >
                    {t("delete")}
                  </DeleteImage>
                </ImageWrapper>
              ))}
          </ImagesWrapper>
        </CertificatesContainer>
        <ActionButtonsContainer>
          <div>
            <AppButton
              size='sm'
              variant='outline'
              onClick={() => handlePrevClick()}
            >
              {t("prev")}
            </AppButton>
            <AppButton size='sm' type='submit'>
              {t("next")}
            </AppButton>
          </div>
          {/* <AppButton size='sm' variant='grey' onClick={() => handleSkipClick()}>
            {t("skip")}
          </AppButton> */}
        </ActionButtonsContainer>
      </FormContainer>
    </EducationContainer>
  );
};

export default Education;

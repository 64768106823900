import ReactPlayer from "react-player";
import {
  InfoBox,
  InfoBoxes,
  InfoSubTitle,
  InfoTitle,
  LeftSectionContainer,
} from "./home.styles";
import { useTranslation } from "react-i18next";
import { HomeShapeOne } from "components/Icons";

const LeftSection = () => {
  const { t } = useTranslation();

  return (
    <LeftSectionContainer>
      <ReactPlayer
        className='react-player'
        url='videos/home.mp4'
        width='100%'
        height='auto'
        controls={true}
      />

      <InfoBoxes>
        <InfoBox>
          <HomeShapeOne className='rotate' />
          <InfoTitle>{t("main-home.info_box_one_title")}</InfoTitle>
          <InfoSubTitle>{t("main-home.info_box_one_subtitle")}</InfoSubTitle>
        </InfoBox>
        <InfoBox>
          <HomeShapeOne />

          <InfoTitle>{t("main-home.info_box_two_title")}</InfoTitle>
          <InfoSubTitle>{t("main-home.info_box_two_subtitle")}</InfoSubTitle>
        </InfoBox>
      </InfoBoxes>
    </LeftSectionContainer>
  );
};

export default LeftSection;

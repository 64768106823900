import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const MainContentContainer = styled.main<{ isLtr: boolean }>`
  flex: 1;
  background-color: rgb(var(--beige));
  padding: 30px 20px;
  .sidebar-icon {
    display: none;
  }

  ${media("medium")} {
    .sidebar-icon {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: ${({ isLtr }) => ({ isLtr } ? "unset" : "20px")};
      left: ${({ isLtr }) => (isLtr ? "20px" : "unset")};
      transform: ${({ isLtr }) => (isLtr ? "rotateY(180deg)" : "unset")};
    }
    padding: 40px 20px 30px;
  }
`;

export const DashboardHeader = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media("medium")} {
    margin-top: 13px;
  }
`;
export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const InfoContainer = styled.div``;

export const Greeting = styled.h1`
  font-size: 1.4rem;
  font-weight: bold;
`;
export const BreadCrumb = styled.div`
  font-size: 1.1rem;
  margin-top: 13px;
`;
export const BreadCrumbLink = styled(Link)`
  text-decoration: none;
`;
export const IconContainer = styled(Link)`
  /* all: unset; */
  width: 39px;
  height: 39px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  .notifications-counter {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgb(var(--red));
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
  }
`;

export const VerifyMessageContainer = styled.div<{
  dir: string;
}>`
  .actions-container {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-top: 20px;
    text-align: ${({ dir }) => (dir === "rtl" ? "left" : "right")};
  }
`;

export const VerifyText = styled.p`
  line-height: 1.5;
  font-size: 1.6rem;
`;

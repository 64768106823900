import AppButton from "components/shared/AppButton";
import AppInput from "components/shared/AppInput";
import PhoneInput from "components/shared/PhoneInput";
import { useRef, useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import ReactSignatureCanvas from "react-signature-canvas";
import { IAccountFormState } from "types";
import { accountSetupActions, ActionKind } from "../Steps";
import {
  ActionButtonsContainer,
  ContractContainer,
  FormContainer,
  FormControl,
  Hint,
  Label,
  SignatureContainer,
  SignatureButtons,
  SubTitle,
  Title,
} from "./contract.styles";
import { ContractContent } from "./ContractContent";
import { useReactToPrint } from "react-to-print";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { trainerAPI } from "services/apis/trainer";
import Select from "react-select";

interface teachingSpecificationProps {
  nextFormStep: () => void;
  prevFormStep: () => void;
  formStep: Number;
  formData: IAccountFormState;
  handleSubmitForm: () => void;
  submitLoading: boolean;

  dispatch: React.Dispatch<accountSetupActions>;
}

const Contract = ({
  nextFormStep,
  prevFormStep,
  formStep,
  formData,
  handleSubmitForm,
  dispatch,
  submitLoading,
}: teachingSpecificationProps) => {
  //hooks
  const { t } = useTranslation();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [trimmedDataURL, setTrimmedDataURL] = useState("");
  const [banks, setBanks] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    fetchBanks();
  }, []);

  let sigPad: ReactSignatureCanvas | null;

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const {
    register,
    handleSubmit,

    control,
    watch,
    getValues,

    formState: { errors },
  } = useForm();

  const watchEmail = watch("email", "");
  const watchName = watch("name", "");
  const watchAddress = watch("address", "");
  const watchPhone = watch("mobile", "");
  const watchIban = watch("iban", "");
  const watchBankName = watch("bank_id", "");

  //methods
  const handleActivateClick = (data) => {
    if (!trimmedDataURL) {
      toast.error(t("add_signature_message"));
      return;
    }
    let formData = {
      contract_email: data.email,
      contract_name: data.name,
      contract_phone: data.mobile,
      contract_address: data.address,
      iban: data.iban,
      bank_id: data.bank_id,
      signature: trimmedDataURL,
    };
    dispatch({
      type: ActionKind.AddData,
      payload: formData,
    });
    setOpenConfirmationModal(true);

    // toast.error("Successfully created!");
  };

  const fetchBanks = async () => {
    try {
      const response = await trainerAPI.getBanks();

      if (response.data.metaData.key === "success") {
        setBanks(
          response.data.data.map((bank) => ({
            label: bank.name,
            value: bank.id,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrevClick = () => {
    prevFormStep();
  };
  const clear = (e) => {
    sigPad?.clear();
    setTrimmedDataURL("");
  };
  const trim = () => {
    if (sigPad) {
      setTrimmedDataURL(sigPad.getTrimmedCanvas().toDataURL("image/png"));
    }
  };
  return (
    <ContractContainer>
      <SubTitle>{t("fourth_step.subtitle")}</SubTitle>
      <Title>{t("fourth_step.title")}</Title>
      <FormContainer>
        <FormControl>
          <AppInput
            required={true}
            register={register}
            name='name'
            errors={errors}
          />
          <PhoneInputWithCountry
            name='mobile'
            international
            control={control}
            rules={{ required: true }}
            countryCallingCodeEditable={false}
            defaultCountry='SA'
          />
        </FormControl>
      </FormContainer>
      <FormContainer onSubmit={handleSubmit(handleActivateClick)}>
        <FormControl>
          <AppInput
            required={true}
            register={register}
            name='address'
            errors={errors}
          />
          <AppInput
            register={register}
            name='email'
            errors={errors}
            type='email'
          />
        </FormControl>

        <FormControl>
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                value={banks.find((c) => c.value === value)}
                name={name}
                options={banks}
                placeholder={t("choose_bank")}
                className='app-select'
                onChange={(selectedOption) => {
                  onChange(selectedOption?.value);
                }}
              />
            )}
            rules={{ required: true }}
            name={"bank_id"}
          />

          <AppInput register={register} name='iban' errors={errors} />
        </FormControl>
        <SignatureContainer>
          <Label>{t("signature")}</Label>
          <Hint>({t("click_save")})</Hint>

          <ReactSignatureCanvas
            penColor='black'
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
            ref={(ref) => {
              sigPad = ref;
            }}
          />
          <SignatureButtons>
            <button onClick={clear} type='button'>
              {t("clear")}
            </button>
            <button onClick={trim} type='button'>
              {t("save")}
            </button>
          </SignatureButtons>
        </SignatureContainer>
        <ActionButtonsContainer>
          <AppButton
            size='sm'
            variant='outline'
            onClick={() => handlePrevClick()}
          >
            {t("prev")}
          </AppButton>
          <AppButton size='md' type='submit'>
            {t("activate_request")}
          </AppButton>
        </ActionButtonsContainer>
      </FormContainer>
      <ContractContent
        watchAddress={watchAddress}
        watchName={watchName}
        watchPhone={watchPhone}
        watchEmail={watchEmail}
        watchIban={watchIban}
        watchBank={watchBankName}
        trimmedDataURL={trimmedDataURL}
        banks={banks}
        ref={componentRef}
      />
      <AppButton onClick={handlePrint} size='sm' variant='primary' mt='20px'>
        {t("print_contract")}
      </AppButton>
      <ConfirmationModal
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        handleSubmitForm={handleSubmitForm}
        submitLoading={submitLoading}
      />
    </ContractContainer>
  );
};

export default Contract;

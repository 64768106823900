import Signature from "assets/images/signature.jpg";
import { Logo } from "components/Icons";
import React, { useMemo } from "react";
import {
  ContractHeader,
  ContractPreview,
  ContractSection,
  Party,
  PartyTitle,
  SignatureImage,
  SignatureSection,
} from "./contract.styles";
import format from "date-fns/format";
import arLocale from "date-fns/locale/ar-SA";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";

export const ContractContent = React.forwardRef<any, any>((props, ref) => {
  const {
    watchAddress,
    watchName,
    watchPhone,
    watchEmail,
    trimmedDataURL,
    watchIban,
    watchBank,
    banks,
  } = props;
  const { t, i18n } = useTranslation();

  // use memo hook to get the bank label using the watch bank value
  // the banks is an array of object each object contain value and label
  // you need to comapre the watchBank value with the value of the object
  // and return the label of the object
  const bankLabel = useMemo(() => {
    const bank = banks.find((bank) => bank.value === watchBank);
    return bank?.label;
  }, [watchBank, banks]);

  const settings = useAppSelector((state) => state.setting.settings);
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  return (
    <ContractPreview>
      <div ref={ref}>
        <ContractHeader>
          <Logo />
        </ContractHeader>
        {getLanguage() === "ar" ? (
          <>
            <ContractSection>
              &nbsp; أبرم هذا العقد في يوم&nbsp;
              {format(new Date(), "eeee", { locale: arLocale })} بتاريخ{" "}
              {format(new Date(), "dd MMMM yyyy", { locale: arLocale })} م بين
              كل من:<br></br>
              1- <strong>مؤسسة علمني الرائدة لتقنية المعلومات</strong>، مؤسسة
              سعودية الجنسية، بموجب سجل تجاري رقم <strong>(4650238553)</strong>{" "}
              و <strong>عنوانها</strong>: 5131, ظئر محمد بن طلحة, حي بني ظفر,
              8009 ،<strong>تلفون</strong> : (148493393 )،{" "}
              <strong>موقع الكتروني</strong>
              (http//alemni.com.sa) ويشار إليه فيما بعد بـ (
              <strong>"الطرف الأول" أو "الوسيط"</strong>) <br></br>
              2- <strong>{watchName}</strong> / ، و عنوانه:{" "}
              <strong>{watchAddress}</strong> ، هاتف{" "}
              <strong>({watchPhone} )</strong>، بريد الكتروني{" "}
              <strong>({watchEmail} )</strong> ويشار إليه فيما بعد بـ{" "}
              <strong>
                ("الطرف الثاني" أو "المعلم" او "المدرب" أو "مقدم الخدمة")
              </strong>
            </ContractSection>
            <hr />
            <ContractSection>
              <strong>تمهيد:</strong>
              <br></br>
              <p>
                حيث إن الطرف الأول متخصص في نشاط التسويق الإلكتروني ولديه منصة
                إلكترونية المسماة alemni ("التطبيق") يقوم من خلالها بتعريف
                الطلاب على خبراء ومدربين مستعدين لإعطاء دروس ودورات في شتى
                المجالات ("الخدمات التسويقية")، وحيث إن الطرف الثاني هو (معلم/
                مدرب) يقوم بإعطاء دروس ودورات في مجال ("الإختصاص")، وحيث إن
                الطرف الثاني يرغب في التعاقد مع الطرف الأول ليقوم بإدراج اسمه
                ضمن أسماء المعلمين والمدربين في الإعلانات على التطبيق حسب أحكام
                هذا العقد، وعليه فقد اتفق الطرفين وهما بكامل الأهلية المعتبرة
                شرعاً ونظاماً على ما يلي:{" "}
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(1): حجية التمهيد:</strong>
              <br></br>
              <p>
                يعتبر التمهيد أعلاه ووثيقة الشروط وأحكام الإستخدام ووثيقة سياسة
                خصوصية تطبيق خدمات تعليمية المرفقين كملاحق مع هذا العقد جزءا لا
                يتجزأ من هذا العقد، ومكملين ومفسرين له ومتممين لأحكامه.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(2): موضوع العقد:</strong>
              <br></br>
              <p>
                إن الغرض من هذا العقد تنظيم العلاقة بين الطرف الأول والطرف
                الثاني، حيث يقوم الطرف الأول بصفته المالك والمطور لمنصة alemni
                ("التطبيق")، بالإعلان على التطبيق عن استعداد الطرف الثاني كـ
                )معلم /مدرب( لإعطاء دورات تعليمية أو تطويرية في اختصاص
                ("الاختصاص") لمن يشاء من الطلاب ضمن برامج زمنية يحددها الطرف
                الثاني ("الدورات"). يقوم الطرف الأول بتسجيل أسماء الراغبين في
                الإشتراك بالدورات واستيفاء بدل الإشتراك منهم حسب أحكام هذا
                العقد. مقابل هذه الخدمات يتقاضى الطرف الأول بدل أتعابه النسبة
                المذكورة في البند رقم (6) من هذا العقد.
              </p>
            </ContractSection>
            <strong>البند:(3): مهام الطرف الأول:</strong>
            <br></br>
            <p>
              يقوم الطرف الأول بما يلي: 1- إدراج اسم الطرف الثاني كـ (معلم/مدرب)
              في الإعلانات التي يضعها على التطبيق، مع نبذة عن سيرته الذاتية
              وبأنه حائز على شهادات في مجال ("الاختصاص"). 2- التنسيق مع الطرف
              الثاني لوضع جدول بالمحاضرات وإطلاع المستخدمين عليه على موقع
              التطبيق. 3- تقديم الدعم الفني اللازم الذي يسمح للطرف الثاني من
              تشغيل التطبيق على أجهزته الإلكترونية التي يجب أن تكون متطابقة
              لتشغيل برنامج التطبيق عليها وفق أحكام هذا العقد ووثيقة شروط وأحكام
              الإستخدام الملحقة مع هذا العقد. 4- توفير الدعم التقني لحل المشاكل
              التي تظهر خلال إستخدام التطبيق. 5- إبلاغ الطرف الثاني عن عدد
              الطلاب الفعلي الذين سددوا رسوم الإشتراك في الدورة التي ينظمها
              الطرف الثاني. 6- تحويل النسبة المتفق عليها إلى الطرف الثاني عن
              الدورات التي تمت بنجاح دون إعتراض من الطلاب المشاركين بها، بعد
              إقتطاع حصة الطرف الأول منها. 7- إبلاغ الطرف الثاني أو الطلاب عن أي
              طارئ يعطل التطبيق أو جدول الدورات المتفق عليها مع الطرف الثاني
              والطلاب.
            </p>
            <ContractSection>
              <strong>البند:(4): مهام الطرف الثاني:</strong>
              <br></br>
              <p>
                يتوجب على الطرف الثاني القيام بما يلي: 1- تزويد الطرف الأول عند
                توقيع هذا العقد نسخ عن بياناته الشخصية وملخص سيرته الذاتية
                وشهاداته الجامعية وشهادات الخبرة (حال وجودها) ذات الصلة
                بإختصاصه. يتحمل الطرف الثاني لوحده صحة المعلومات التي يقدمها
                للطرف الأول دون أدنى مسؤولية على الطرف الأول. 2- تزويد الطرف
                الأول بلائحة عن أتعابه التي سيتقاضاها من الطلاب في حال رغبوا
                الإشتراك والتسجيل في الدورات التي سيعطي دروس فيها ("بدل
                الإشتراك"). 3- تزويد الطرف الأول بعنوانه وأرقامه الهاتفية
                وعناوين حساباته الإلكترونية الشخصية لتسهيل التواصل معه. 4-
                التنسيق مع الطرف الأول من أجل وضع جدول بمواعيد الدورات التي
                سيقوم الطرف الثاني بإعطاء دروس خلالها لمن يرغب من الطلاب. 5-
                تحمل مسؤولية إبلاغ الطرف الأول عن أي تعديل يرغب بإجرائه على جدول
                الدورات بعد الاتفاق عليه وإعلانه في التطبيق. 6- عدم إستخدام أي
                جهاز إلكتروني يتعارض أو يسبب ضرر لبرنامج التطبيق. 7- عدم القيام
                بأي تصرف من شأنه الإساءة الى التطبيق أو سمعته وسمعة الطرف الأول.
                8- لا يستحق الطرف الثاني اتعابه عند عدم تقديم الدورة في موعدها
                لأي سبب كان ما عدا حالة عدم حضور الطالب في الموعد المحدد له.
                يتوجب على الطرف الثاني تسجيل الدورة عبر الوسائل المرئية
                والمسموعة وفي حال لم يثبت فيها تخلف الطالب عن حضور الدورة، ترد
                رسوم الاشتراكات للطلاب. 9- يلتزم الطرف الثاني بالحفاظ على سرية
                معلومات الطلاب وعدم بيعها أو مشاركتها مع أي طرف آخر. 10- يلتزم
                الطرف الثاني بتوفير الأجهزة والاشتراك بشبكته على نفقته الخاصة،
                وذلك للدخول للتطبيق والقيام بإدارة جداوله، ولا يتحمل الطرف الأول
                أي مسؤولية عما يترتب على مخالفة ذلك. 11- يتعهد الطرف الثاني
                بالقيام بجميع الخدمات التعليمية عن طريق جلسات الفيديو المرئية
                والمسموعة المباشرة في برنامج ( Google meet) وإرسال الرابط قبل
                موعد الجلسة بـ.48 ساعة على الأقل إلى الطالب والطرف الأول والسماح
                لهم بدخولها. 12- يلتزم الطرف الثاني بالتسجيل الصوتي والمرئي
                للدورات وإرسال التسجيل للطرف الأول والطالب. يشكل هذا التسجيل
                الضمان للطرف الثاني بتأديته للخدمة المطلوبة وإلا فإنه سيؤخذ
                بكلام الطالب في حال وجود إعتراض أو شكوى بهذا الخصوص. 13- يجب على
                الطرف الثاني طيلة سريان هذا العقد، أن يتقيد بالأنظمة المرعية
                الإجراء في المملكة وعليه موجب التأكد من صحة وصلاحية تراخيصه او
                شهاداته المعتمدة اللازمة لمزاولة التدريب وتجديدها عند اللزوم من
                الجهات المختصة على نفقته الخاصة.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(5): مدة التعاقد:</strong>
              <br></br>
              <p>
                مدة هذا العقد سنوات تبدأ من تاريخ{" "}
                <strong>{format(new Date(), "dd MMMM yyyy")} </strong>
                وتنتهي في تاريخ {/* add one year to teh current date */}
                <strong>
                  {format(
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() + 1)
                    ),
                    "dd MMMM yyyy"
                  )}
                </strong>
                ، قابلة للتجديد لمدة أخرى مماثلة، ما لم يخطر أحد الأطراف نظيره
                بعدم الرغبة في تجديد الاشتراك قبل انتهاء مدته بـ(30) بثلاثين
                يوماً.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(6): آلية السداد:</strong>
              <br></br>
              <p>
                1- إن العملة التي سيتم التعامل فيها هي الريال السعودي سواء
                لتقاضي بدل الإشتراك أو لتسوية الحسابات بين الطرفين. 2- يتقاضى
                الطرف الأول أتعابه بما يوازي نسبة{" "}
                <strong>( {settings.percentage}%)</strong> من قيمة بدل الإشتراك
                التي يدفعها الطالب عن طريق التطبيق مقابل الخدمات التعليمية
                المقدمة من الطرف الثاني. 3- يسدد الطرف الأول خلال (15) يوم عمل،
                المتبقي من بدل الإشتراك إلى الطرف الثاني، بعد إقتطاع نسبته
                المحددة في الفقرة رقم (2) من هذا البند. 4- يدفع الطرف الأول
                للطرف الثاني المتبقي من بدل الإشتراك بالوسيلة التي يتفقان عليها
                لاحقاً أو عبر التحويل البنكي على الحساب رقم:
                <strong>{watchIban}</strong> في البنك:{" "}
                <strong>{bankLabel}</strong>
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(7): الاحكام العامة:</strong>
              <br></br>
              <p>
                1- لأغراض تنظيمية والتحقق من جودة الخدمات، يحق للطرف الأول حضور
                الدورات التي يقدمها الطرف الثاني في أي وقت ولا يمانع الطرف
                الثاني بهذا الأمر. 2- يحق للطرف الاول رفض أي خدمة تعليمية من
                الطرف الثاني تكون مخالفة للأنظمة او لأي سبب أخر حسب مشيئته. 3-
                يقر الطرفان بأنه لا يجوز لأي منهما استخدام العلامات التجارية
                الخاصة بالطرف الآخر إلا للإعلان عن حقيقة أنه متعاقد مع الطرف
                الآخر لتقديم الخدمة. 4- لا يعد هذا العقد أي نوع من أنواع الشراكة
                فيما بين الطرفين وتبقى كامل الحقوق للتطبيق ولا يحق للطرف الثاني
                الرجوع عليه بأي مطالبات مالية سوى ما هو مذكور في البند (6) من
                هذا العقد عند توجبها. 5- يلتزم الطرف الثاني بأن يكون استقبال
                المواعيد من خلال التطبيق حصراً، وفي حال رغب باستضافة أي شخص في
                دورة أعلنت في التطبيق، فإن هذا الأمر يتم بعد أخذ موافقة الطرف
                الأول عليه وإلا فإن الطرف الثاني يصبح مسؤولاً عن تسديد رسوم
                إشتراك هذا الضيف عن الدورة ذات الصلة الى الطرف الأول. 6- تبقى
                الأسماء التجارية وحقوق الطبع والنشر والحقوق الفكرية وغيرها
                الخاصة بأحد أطراف هذا العقد مصونة ومحمية وملكاً خالصاً له، ولا
                يمكن لأي أحد التعرض لهذه الملكية دون موافقة خطية من صاحبها. 7-
                يحق للطرف الأول وفقاً لمشيئته الخاصة، إجراء أي تعديل على الخدمات
                المقدمة من طرفه بموجب هذا العقد، في أي وقت كان حسب ما يراه
                مناسباً. 8- أي تعديل على الخدمات المقدمة من الطرف الأول أو
                الخدمات المقدمة من الطرف الثاني يوجب أن يخطر كل طرف نظيره عبر
                الوسيلة المعتمدة للإخطار قبل مدة (7) أيام من إجراء التعديل ويشمل
                مصطلح "التعديلات" أي تغيير، أو تعديل، أو تكملة، أو حذف، أو
                استبدال، او تحديث مرتبط بالخدمة محل هذا العقد أياً كان تأثير هذا
                التعديل. 9- لا يعتبر أي تعديل على أحكام هذا العقد سارياً ما لم
                يكن خطياً وموقع من قبل ممثلي الطرفين. 10- لا يمانع الطرف الثاني
                بأن يقوم الطرف الأول بتوفير خدماته لأطراف أخرى قد تكون منافسة له
                في نوع الخدمة.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(8): الإخطارات:</strong>
              <br></br>
              <p>
                العنوان المذكور في بداية العقد هو العنوان النظامي لكل طرف، توجه
                إليه جميع المراسلات بينهما، والمراسلة عليه تعد تبليغاً رسمياً،
                تترتب عليه آثاره النظامية، ويتعهد كل طرف بإخطار الآخر كتابة فور
                أي تغيير يطرأ على بياناته في هذا العقد
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(9): الفسخ:</strong>
              <br></br>
              <p>
                بإمكان أي من الطرفين إنهاء هذا العقد في أي وقت يشاء بموجب إشعار
                خطي يوجه للطرف الآخر قبل انتهاء العقد بمدة (30) ثلاثين يوماً. في
                حال الإنهاء قبل موعد دورة متفق عليها يقوم الطرف الأول بإعادة
                رسوم الإشتراك للطلاب المشاركين بها ولا يتوجب للطرف الثاني أي
                اتعاب عنها.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(10): لغة العقد:</strong>
              <br></br>
              <p>
                في حال تعارض بنود هذا العقد العربية مع الإنجليزية، فتكون اللغة
                العربية هي اللغة المعتمدة لتنفيذ هذا العقد
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(11): النزاعات:</strong>
              <br></br>
              <p>
                ‌كل نزاع أو خلاف قد ينشأ بين الطرفين فيما يتعلق بتنفيذ الاتفاقية
                ولا يتوصل فيه إلى تسوية ودية يحال إلى المحاكم المختصة بجدة -
                المملكة العربية السعودية للفصل فيه بشكل نهائي.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(12): القانون واجب التطبيق:</strong>
              <br></br>
              <p>
                تطبق على هذا العقد أنظمة المملكة العربية السعودية، ويحل هذا
                العقد محل أي اتفاقيات أو عقود سابقة شفهية أو كتابية تتعلق
                بموضوعه.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>البند:(13): نسخ العقد:</strong>
              <br></br>
              <p>
                يجوز توقيع هذه الاتفاقية بأي عدد من النسخ المتطابقة والمستقلة
                وتعتبر كل منها عند توقيعها نسخة أصلية ولكن جميع النسخ المتطابقة
                مجتمعة تعتبر وثيقة واحدة.
              </p>
            </ContractSection>
            <SignatureSection>
              <Party>
                <PartyTitle>الطرف الثاني </PartyTitle>
                {trimmedDataURL ? (
                  <SignatureImage src={trimmedDataURL} alt='' />
                ) : null}
              </Party>
              <Party>
                <PartyTitle>الطرف الأول </PartyTitle>
                <SignatureImage src={Signature} alt='' />
              </Party>
            </SignatureSection>
          </>
        ) : (
          <>
            <ContractSection>
              &nbsp; This Agreement (“The Agreement”) is signed on &nbsp;
              {format(new Date(), "eeee")} {format(new Date(), "dd MMMM yyyy")}{" "}
              between :<br></br>
              1-{" "}
              <strong>
                Alemni the leading for information technology Establishment,
              </strong>
              ، Saudi Establishment with CR No. (4650238553),{" "}
              <strong>(4650238553)</strong> و <strong>and its address</strong>:
              5131, ظئر محمد بن طلحة, حي بني ظفر, 8009 ،
              <strong>Telephone</strong> : (148493393 )،{" "}
              <strong>website </strong>
              (http//alemni.com.sa) hereinafter referred to as (
              <strong>The First Party" or "The Mediator</strong>) <br></br>
              2- <strong>{watchName}</strong> / ، whose address at:{" "}
              <strong>{watchAddress}</strong> ، phone{" "}
              <strong>({watchPhone} )</strong>، Email{" "}
              <strong>({watchEmail} )</strong> hereinafter referred to as{" "}
              <strong>
                (“The Second Party” or “The Trainer” or “The Service Provider”)
              </strong>
            </ContractSection>
            <hr />
            <ContractSection>
              <strong>Preamble:</strong>
              <br></br>
              <p>
                Whereas the First Party specialized in digital marketing and has
                an electronic platform alemni (“The application”) which
                introduces students to trainers who are willing to provide
                courses in various fields “Marketing services”. Whereas the
                Second Party is an expert in the (“The Major”). And, whereas the
                Second Party wishes to engage the First Party to promote his
                name among the tutors and trainers’ list on the Application in
                accordance with the terms of this Agreement, THEREFORE the
                parties have agreed to the following:{" "}
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article (1): Preamble interpretation:</strong>
              <br></br>
              <p>
                The above preamble to this Agreement, the annexed Terms and
                Conditions of Use and the Privacy Policy for the Educational
                Services Application shall be deemed an integral part of this
                agreement, complementary, and interpreted thereof.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article (2): Purpose of the Agreement:</strong>
              <br></br>
              <p>
                The purpose of this Agreement is to organize the relationship
                between the parties to this agreement, whereas the First Party
                as the owner and the developer of the platform “alemni” (“The
                Application”), will announce on the application the readiness of
                the Second Party as a trainer to give educational or development
                courses in (“The Major”) for students within scheduled programs
                (“the courses”) set by the Second Party. The First Party will
                register those who are interested in enrolling in the courses
                and obtain their subscription fees according to this The purpose
                of this Agreement is to organize the relationship between the
                parties to this agreement, whereas the First Party as the owner
                and the developer of the platform “alemni” (“The Application”),
                will announce on the application the readiness of the Second
                Party as a trainer to give educational or development courses in
                (“The Major”) for students within scheduled programs (“the
                courses”) set by the Second Party. The First Party will register
                those who are interested in enrolling in the courses and obtain
                their subscription fees according to this Agreement. In exchange
                for his services, the First Party will receive his fees the
                percentage stipulated in article (6) of this Agreement.
              </p>
            </ContractSection>
            <strong>Article (3): First Party duties:</strong>
            <br></br>
            <p>
              The first part’s duties are the following: 1- Enroll the Second
              Party’s name as (tutor/trainer) in the ads he posts on the
              application, along with a profile of his resume and certificates
              in ("the Major"). <br />
              2- Coordinating with the Second Party to set up the courses’
              schedule and share it on the application's website with the users.
              <br />
              3- Provide the necessary technical support that allows the Second
              Party to run the Application on his devices, which must be
              compatible to run the application software in accordance with the
              terms of this Agreement and the annexed Terms and Conditions of
              Us.
              <br />
              4- Provide technical support to solve the problems that appear
              during the use of the Application. 5- Informing the Second Party
              of the actual number of students who have paid the fees of the
              Second Party’s course. <br />
              6- Transferring the agreed percentage to the Second Party for
              courses successfully completed without objection from students,
              and after deducting the share of the First Party.
              <br />
              7- Notifying the Second Party or students of any problems that
              disrupts the Application or the schedule of the courses agreed
              upon with the Second Party and the students
            </p>
            <ContractSection>
              <strong>Article :(4): Second Party obligations:</strong>
              <br></br>
              <p>
                The Second Party shall: Upon signing this Agreement, the First
                Party shall provide copies of his personal data, summary of his
                resume, university certificates and certificates of expertise
                (if any) relevant to his qualification. The Second Party solely
                bears the validity of the information he provides to the First
                Party without any liability on the First Party. Provide the
                First Party with a list of the fees that students must pay if
                they wish to subscribe and register for the courses
                ("Registration fees"). Provide his address, phone numbers and
                personal social accounts to the First Party for ease of
                communication. Coordinating with the First Party to schedule
                course dates held by the Second Party for any interested
                students. Taking the responsibility of informing the First Party
                about any change occurring on a course after agreeing upon its
                time schedule and listing it in the application. To not use any
                device that disrupts or causes damage to the Application
                software. Not carrying out any act that may offend the
                Application or the First Party's reputation. Except the case
                where the student does not attend the course, the Second Party
                shall not be entitled to his fees if the relevant course is not
                held on its agreed time. The Second Party must register the
                course through visual and audio means, and if it is not proven
                that the student has failed to attend the course, the
                subscription fees will be refunded to the students. Maintaining
                the confidentiality of the students' information and to not sell
                it or share it with any other party. Provide and set up the
                equipment and the subscription to his network at his own
                expense, to access the Application and manage his schedules. The
                First Party will not be held liable for the consequences of
                Second Party failing to observe this matter. Undertakes to carry
                out all educational services via live video and audio sessions
                in ( Google meet). The link of the session shall be sent by the
                Second Party to the students and the First Party at least 48
                hours before it starts, and the Second Party must allow them to
                attend it. Record the courses via video sessions and send these
                recordings to the First Party and the student. These recordings
                will be the Second Party’s guarantee that he performed the
                agreed service, otherwise the student's word will prevail in the
                event of a complaint or objection in this regard. During the
                term of the Agreement, the Second Party shall adhere to the
                applicable laws and regulations within Saudi Arabia, and he
                shall ensure the validity and expiration of his licenses and
                certificates needed to practice the training, and its renewal if
                necessary, at his own expense.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article:(5): Agreement duration:</strong>
              <br></br>
              <p>
                The duration of this Agreement years starting from{" "}
                <strong>{format(new Date(), "dd MMMM yyyy")}</strong> and ends
                on{" "}
                <strong>
                  {format(
                    new Date(
                      new Date().setFullYear(new Date().getFullYear() + 1)
                    ),
                    "dd MMMM yyyy"
                  )}
                </strong>
                The Agreement may be renewed for the same period, unless either
                party notifies the other Party of his intent to not renew (30)
                days before the expiry date.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article: (6): Payment method:</strong>
              <br></br>
              <p>
                All payments under this Agreement are in Saudi riyal currency,
                whether to charge the subscription fees or to settle the
                payments accounts between the parties. The First Party receives
                his fees by <strong>({settings.percentage}% )</strong> percent
                of the subscription fees from the student through the
                Application for the services provided by the Second Party. The
                First Party pays within (15) working days, the remaining
                subscription fees to the Second Party, after deducting his
                percentage in paragraph 2 of this article. The First Party pays
                the Second Party the remaining subscription fees in any way they
                see fit and agreed upon later or through bank transfer to the
                account:
                <strong>{watchIban}</strong> Bank:<strong>{bankLabel}</strong>{" "}
                No. SA
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article:(7): General provisions:</strong>
              <br></br>
              <p>
                For organizational purposes and to check the quality of
                services, the First Party has the right to attend the courses
                presented by the Second Party at any time without the latter
                objection. The First Party has the right to reject any
                educational services from the Second Party that contravene with
                the laws, or refuses these services by his sole discretion for
                whatever reason. The parties acknowledge that neither party may
                use the trademarks of the other party except to indicate that he
                is Agreemented with the other party to provide the services.
                This Agreement shall not be interpreted or construed as a
                partnership between the parties. All rights shall be owned and
                remain to the application, and the Second Party is not permitted
                to claim any financial rights other than what is stipulated in
                article (6) of this Agreement. The appointments must be received
                by the Second Party through the application exclusively. The
                Second Party must take the First Party approval to accept the
                attendance of a guest in any course which has been advertised in
                the application, otherwise the Second Party is held accountable
                for paying the course fees on behalf of the guest to the First
                Party. The trademarks, copyrights, intellectual property rights
                and other rights owned by any party in this Agreement, shall
                remain protected and exclusively owned by its owner, and no one
                shall be permitted to use or exploit them without the written
                consent from its owner. In any event, the First Party by his
                sole discretion may modify his services provided under this
                Agreement. Any amendments to the services provided by either
                party require that each party notify the other Party through the
                agreed method of notification within (7) Seven days period
                before the amendments take place. The term “amendments” includes
                any change, or modification, addendum, deletion, replacement, or
                update to the service subject to this Agreement regardless of
                the effect of this amendment. A variation to this Agreement is
                only valid if it is in writing and signed by the representative
                of each Party. The Second Party does not mind if the First Party
                provides his services to other parties who may be competitors in
                the same service.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article:(8): Notifications:</strong>
              <br></br>
              <p>
                All correspondence and notifications are directed to the
                addresses above in this agreement. Therefore, the correspondence
                notice is considered an official notice and has legal
                implications, and either party must inform the other promptly of
                any changes to his contact information in writing.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article:(9): Termination</strong>
              <br></br>
              <p>
                This Agreement may be terminated by either Party at any time
                after by a written notice to the other Party, thirty (30) days
                prior to termination date. If the termination occurs prior to an
                agreed course date, the First Party is responsible to refund
                students' participation fees, and the Second Party will not be
                entitled to any fees for it.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article:(10): Agreement language:</strong>
              <br></br>
              <p>
                In case of conflict between Arabic and English terms of this
                Agreement, the Arabic language shall prevail.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article:(11): Disputes:</strong>
              <br></br>
              <p>
                Any dispute or controversy arises between the Parties regarding
                the implementation of this agreement, that cannot be resolved
                amicably, it must be resolved by the competent court in Jeddah.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article:(12): Governing law and jurisdiction:</strong>
              <br></br>
              <p>
                This agreement shall be governed under the Laws and regulations
                of Kingdom of Saudi Arabia's, and it shall replace any prior
                verbal or written agreements or contracts relating to its
                subject.
              </p>
            </ContractSection>
            <ContractSection>
              <strong>Article (13): Counterparts:</strong>
              <br></br>
              <p>
                This Agreement may be executed in any number of counterparts,
                each of which shall be deemed, when signed, an original, but all
                of which shall constitute one and the same instrument
              </p>
            </ContractSection>
            <SignatureSection>
              <Party>
                <PartyTitle>Second Party </PartyTitle>
                {trimmedDataURL ? (
                  <SignatureImage src={trimmedDataURL} alt='' />
                ) : null}
              </Party>
              <Party>
                <PartyTitle>First Party</PartyTitle>
                <SignatureImage src={Signature} alt='' />
              </Party>
            </SignatureSection>
          </>
        )}
      </div>
    </ContractPreview>
  );
});

import {
  ActionButtonsContainer,
  EducationContainer,
  FormContainer,
  FormControl,
  Label,
  OnsiteContainer,
  RemotelyContainer,
  SubTitle,
  TeachingTypesContainer,
  Title,
  Hint,
} from "./specifications.styles";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import AppButton from "components/shared/AppButton";
import { FiUploadCloud } from "react-icons/fi";
import { IconContext } from "react-icons";
import React, { useEffect, useState } from "react";
import AppChips from "components/shared/AppChips";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { IAccountFormState } from "types";
import { accountSetupActions, ActionKind } from "../Steps";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";

const targetTypes = [
  { name: "primary", value: "1" },
  { name: "elementary", value: "2" },
  { name: "intermediate", value: "3" },
  { name: "secondary", value: "4" },
  { name: "university", value: "5" },
];

const studentTypes = [
  { name: "male", value: "male" },
  { name: "female", value: "female" },
];

interface teachingSpecificationProps {
  nextFormStep: () => void;
  prevFormStep: () => void;
  formStep: Number;
  formData: IAccountFormState;
  dispatch: React.Dispatch<accountSetupActions>;
}

const TeachingSpecifications = ({
  nextFormStep,
  prevFormStep,
  formStep,
  formData,
  dispatch,
}: teachingSpecificationProps) => {
  //hooks
  const { t, i18n } = useTranslation();

  const [educationalLevel, setEducationalLevel] = useState(["2"]);
  const [targetType, setTargetType] = useState(["male"]);
  const [onlinePrice, setOnlinePrice] = useState<string | number>(0);
  const [onsitePrice, setOnsitePrice] = useState<string | number>(0);
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  const [subjects, setSubjects] = useState<
    { value: string; label: string; label_en: string }[]
  >([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  useEffect(() => {
    if (formData.target_type) {
      if (formData.target_type === "both") {
        setTargetType(["male", "female"]);
      } else {
        setTargetType([formData.target_type]);
      }
    }

    if (formData.online_price) {
      setOnlinePrice(formData.online_price);
    }
    if (formData.onsite_price) {
      setOnsitePrice(formData.onsite_price);
    }
  }, []);

  useEffect(() => {
    fetchSubjects();
  }, []);

  //api requests

  const fetchSubjects = async () => {
    try {
      const response = await trainerAPI.getSubjects();

      if (response.data.metaData.key === "success") {
        setSubjects(response.data.data);
      }
    } catch (error) {}
  };
  //functions

  const handleEducationalLevelChoice = (value: string) => {
    const index = educationalLevel.indexOf(value);
    if (index > -1) {
      setEducationalLevel(educationalLevel.filter((type) => type !== value));
    } else {
      setEducationalLevel([...educationalLevel, value]);
    }
  };

  const handleTargetTypeChoice = (value: string) => {
    const index = targetType.indexOf(value);
    if (index > -1) {
      setTargetType(targetType.filter((type) => type !== value));
    } else {
      setTargetType([...targetType, value]);
    }
  };

  const handleNextClick = (data) => {
    //check if either online price or onsite price are note set

    if (!+onlinePrice && !+onsitePrice) {
      toast.error(t("enter_hour_rate"));
      return;
    }
    let subjects = data.subjects.map((material) => material.value);
    let formData = {
      subjects,
      educational_level: educationalLevel,
      target_type: targetType.length === 2 ? "both" : targetType[0],
      onsite_price: onsitePrice ? onsitePrice : null,
      online_price: onlinePrice ? onlinePrice : null,
    };
    dispatch({
      type: ActionKind.AddData,
      payload: formData,
    });
    nextFormStep();

    // toast.error("Successfully created!");
  };
  const handlePrevClick = () => {
    prevFormStep();
  };

  const handlePriceChange = (e, type) => {
    type === "online"
      ? setOnlinePrice(e.target.value)
      : setOnsitePrice(e.target.value);
  };

  const formatSubjectLabel = ({ label, label_en, value }) => {
    const displayedLabel = getLanguage() === "en" ? label_en : label;

    return <div>{displayedLabel}</div>;
  };

  return (
    <EducationContainer>
      <SubTitle>{t("third_step.subtitle")}</SubTitle>
      <Title>{t("third_step.title")}</Title>
      <FormContainer onSubmit={handleSubmit(handleNextClick)}>
        <Label>{t("third_step.teaching_type")}</Label>
        <TeachingTypesContainer>
          <RemotelyContainer active isLtr={getLanguage() === "en"}>
            <div>
              <p className='title'>{t("third_step.remotely")}</p>
              <p className='subtitle'>{t("third_step.remotely_title")}</p>
            </div>
            <div className='input-container'>
              <input
                type='number'
                min={0}
                onChange={(e) => handlePriceChange(e, "online")}
                value={onlinePrice}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {t("riyal")}
              </span>
            </div>
          </RemotelyContainer>
          <OnsiteContainer active isLtr={getLanguage() === "en"}>
            <div>
              <p className='title'>{t("third_step.on_site")}</p>
              <p className='subtitle'>{t("third_step.on_site_title")}</p>
            </div>

            <div className='input-container'>
              <input
                type='number'
                min={0}
                onChange={(e) => handlePriceChange(e, "onsite")}
                value={onsitePrice}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {t("riyal")}
              </span>
            </div>
          </OnsiteContainer>
        </TeachingTypesContainer>

        <FormControl>
          {formData.type === "academic" ? (
            <div>
              <Label>{t("third_step.target_type")}</Label>
              <AppChips
                options={targetTypes}
                handleChoice={(value) => handleEducationalLevelChoice(value)}
                selectedChoice={educationalLevel}
                fontSize='14px'
                multiple
              />
            </div>
          ) : null}
          <div>
            <Label>{t("third_step.students_type")}</Label>
            <AppChips
              options={studentTypes}
              fontSize='14px'
              handleChoice={(value) => handleTargetTypeChoice(value)}
              selectedChoice={targetType}
              multiple
            />
            <Hint>({t("you_can_select_multiple")})</Hint>
          </div>
        </FormControl>

        <Controller
          control={control}
          rules={{ required: true }}
          name={"subjects"}
          render={({ field: { onChange, value, name, ref } }) => (
            <CreatableSelect
              ref={ref}
              value={subjects.find((c) => c.value === value)}
              formatOptionLabel={formatSubjectLabel}
              isMulti
              onChange={(selectedOption) => {
                onChange(selectedOption);
              }}
              options={subjects}
              className='app-select'
              placeholder={t("third_step.your_materials")}
            />
          )}
        />
        {errors?.subjects && (
          <p className='error-message'>المواد التعليمية مطلوب</p>
        )}
        {/* <Select
          isMulti
          options={materialOptions}
          className='app-select'
          placeholder={t("third_step.your_materials")}
        /> */}
        <ActionButtonsContainer>
          <div>
            <AppButton
              size='sm'
              variant='outline'
              onClick={() => handlePrevClick()}
            >
              {t("prev")}
            </AppButton>
            <AppButton size='sm' type='submit'>
              {t("next")}
            </AppButton>
          </div>
          {/* <AppButton size='sm' variant='grey' onClick={() => handleSkipClick()}>
            {t("skip")}
          </AppButton> */}
        </ActionButtonsContainer>
      </FormContainer>
    </EducationContainer>
  );
};

export default TeachingSpecifications;

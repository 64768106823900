import styled from "styled-components/macro";

export const InputContainer = styled.div`
  position: relative;
  direction: ltr !important;
  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: rgb(var(--grey));
    transition: all 0.4s;
  }

  input:focus-visible + label,
  input:not(:placeholder-shown) + label {
    top: 0;
    color: rgb(var(--main-color-lighter));
    font-size: 1.1rem;

    &:after {
      content: " ";
      background-color: #fff;
      width: 100%;
      height: 13px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  span {
    position: absolute;
    display: block;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    z-index: 9;
    cursor: pointer;
    color: rgb(var(--black));
    font-weight: bold;
  }

  p {
    color: red;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 1.1rem;
  }
`;
export const Input = styled.input`
  width: 100%;
  height: 50px;
  font-size: 1.6rem;
  color: rgb(var(--black));
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding-left: 50px;
  &:focus-visible {
    outline: none;
    border: 3px solid rgb(var(--main-color-lighter));
  }
`;

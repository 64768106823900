import { Link, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const PackagesWrapper = styled.div``;
export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;
export const MetaTitle = styled.span`
  color: rgb(var(--light-grey));
  font-size: 1.3rem;
`;
export const MetaValue = styled.span`
  font-size: 1.6rem;
  color: rgb(var(--border-dark));
  margin-top: 4px;
`;

export const PackageItem = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  &:not(:first-child) {
    margin-top: 15px;
  }

  /* ${media("large")} {
    height: 200px;
  } */
`;

export const PackageThumbnail = styled.div`
  width: 110px;
  height: 96px;
  background-color: rgb(var(--off-white));
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: bold;
    color: #363b9e;
  }

  span {
    font-size: 1.6rem;
    font-weight: bold;
    color: #363b9e;
  }
`;

export const AddPackageWrapper = styled.form``;
export const AddPackageTitle = styled.h1`
  font-size: 2.7rem;
  font-weight: bold;
  margin: 0;
`;

export const InputsWrapper = styled.div`
  background-color: #fff;
  padding: 32px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const InputGroup = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  gap: 11px;
`;

export const Info = styled.div<{
  isLtr: boolean;
}>`
  margin-right: ${({ isLtr }) => (isLtr ? "14px" : "auto")};
  margin-left: ${({ isLtr }) => (isLtr ? "auto" : "14px")};
`;

export const ActionsContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 11px;
  justify-content: space-between;
`;
export const EditLink = styled(Link)`
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(var(--main-color-light));
  text-decoration: none;
  cursor: pointer;
`;
export const DeleteButton = styled.button`
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(var(--red));
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
`;

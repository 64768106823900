import {
  BasicInformationContainer,
  ChangeImage,
  FormContainer,
  FormControl,
  ImageContainer,
  ImageMeta,
  ImageSizes,
  ProfileImage,
  SubTitle,
  Title,
  Label,
  ActionButtonsContainer,
  IntroContainer,
  ProfilePicture,
} from "./basicinformation.styles";
import { useTranslation } from "react-i18next";
import AppChips from "components/shared/AppChips";

import { Avatar } from "components/Icons";
import Select from "react-select";
import React, { useEffect, useRef, useState } from "react";
import AppButton from "components/shared/AppButton";
import { Controller, useForm } from "react-hook-form";
import AppText from "components/shared/AppText";
import toast from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import { IAccountFormState, TrainerType } from "types";
import { accountSetupActions, ActionKind } from "../Steps";
import axios from "axios";
import {
  CertificatesContainer,
  ImageInput,
  ImagesWrapper,
  ImageUploader,
} from "../Education/education.styles";
import { IconContext } from "react-icons";
import { FiUploadCloud } from "react-icons/fi";
import AppInput from "components/shared/AppInput";
import { useAppSelector } from "store";

const chipsOptions = [
  { name: "academic", value: "academic" },
  { name: "vocational", value: "vocational" },
];

interface basicInformationProps {
  nextFormStep: () => void;

  formStep: Number;
  formData: IAccountFormState;
  dispatch: React.Dispatch<accountSetupActions>;
  countries?: { label: string; value: string }[] | [];
}

const BasicInformation = ({
  nextFormStep,
  formData,
  dispatch,
  countries,
}: basicInformationProps) => {
  //hooks
  const { t } = useTranslation();
  const [cities, setCities] = useState<{ label: string; value: string }[]>([]);
  const [selectedType, setSelectedType] = useState<TrainerType>("academic");
  const [intro, setIntro] = useState<File>();
  const [profilePicture, setProfilePicture] = useState<File>();

  const user = useAppSelector((state) => state.auth.user);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchCountry = watch("country");
  useEffect(() => {
    if (formData.type) {
      setSelectedType(formData.type);
    }
    if (formData.bio) {
      setValue("bio", formData.bio);
    }

    if (formData?.intro) {
      setIntro(formData.intro);
    }

    if (formData?.avatar) {
      setProfilePicture(formData.avatar);
    }
  }, []);

  useEffect(() => {
    setCities([]);
    if (watchCountry) {
      getCities(watchCountry);
    }
  }, [watchCountry]);

  //functions
  const handleTypeChoice = (value: TrainerType) => {
    setSelectedType(value);
  };

  const getCities = async (country) => {
    try {
      const response = await axios.post(
        "https://countriesnow.space/api/v0.1/countries/cities",
        { country }
      );

      let data = response.data.data.map((city) => ({
        label: city,
        value: city,
      }));

      setCities([...data]);
    } catch (error) {}
  };

  const handleNextClick = (data) => {
    let formData = {
      ...data,
      type: selectedType,
      intro,
      avatar: profilePicture,
    };
    dispatch({ type: ActionKind.AddData, payload: formData });
    nextFormStep();
  };

  const handleUploadVideo = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files) {
      return;
    }
    let url = URL.createObjectURL(files[0]);
    setIntro(files[0]);
  };

  const handleProfilePicture = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files) {
      return;
    }
    setProfilePicture(files[0]);
  };

  return (
    <BasicInformationContainer>
      <Title>
        {t("first_step.title")} {user.name}
      </Title>
      <SubTitle>{t("first_step.subtitle")}</SubTitle>
      <ImageContainer>
        {profilePicture ? (
          <ProfilePicture
            src={URL.createObjectURL(profilePicture)}
            alt='profilePicture'
          />
        ) : (
          <Avatar width='120px' height='120px' />
        )}
        <input
          type='file'
          name='avatar'
          id='avatar'
          accept='image/*'
          onChange={handleProfilePicture}
        />
        <ImageMeta>
          <ChangeImage htmlFor='avatar'>
            {t("first_step.change_image")}
          </ChangeImage>
          <ImageSizes>
            <span>{t("first_step.photo_size")}</span>
            <span>120*120</span>
          </ImageSizes>
        </ImageMeta>
      </ImageContainer>

      <IntroContainer>
        <ImagesWrapper>
          <ImageUploader htmlFor='file'>
            <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
              <FiUploadCloud />
            </IconContext.Provider>
            <span>{t("intro")}</span>
            <h1>{t("select_from_device")}</h1>
          </ImageUploader>
          <ImageInput
            type='file'
            id='file'
            accept='video/*'
            onChange={handleUploadVideo}
            // {...register("files")}
          />
        </ImagesWrapper>
        {intro && (
          <video
            className='react-player'
            src={URL.createObjectURL(intro)}
            width='100%'
            height='100%'
            controls={true}
          />
        )}
      </IntroContainer>
      <FormContainer onSubmit={handleSubmit(handleNextClick)}>
        <FormControl>
          <Controller
            control={control}
            defaultValue={formData.country ? formData.country : null}
            rules={{ required: true }}
            name={"country"}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                value={countries?.find((c) => c.value === value)}
                name={name}
                options={countries}
                placeholder={t("country")}
                className='app-select'
                onChange={(selectedOption) => {
                  onChange(selectedOption?.value);
                  // setCities([]);
                  // getCities(selectedOption?.value);
                }}
              />
            )}
          />
          {errors?.country && <p className='error-message'>حقل الدولة مطلوب</p>}

          <Controller
            control={control}
            defaultValue={formData.city ? formData.city : null}
            name={"city"}
            rules={{ required: true }}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                value={cities.find((c) => c.value === value)}
                name={name}
                options={cities}
                placeholder={t("city")}
                className='app-select'
                onChange={(selectedOption) => {
                  onChange(selectedOption?.value);
                }}
              />
            )}
          />
          {errors?.city && <p className='error-message'>حقل المدينة مطلوب</p>}
        </FormControl>

        <Label>{t("teacher_type")}</Label>
        <AppChips
          options={chipsOptions}
          mt='9px'
          handleChoice={(value) => handleTypeChoice(value)}
          selectedChoice={selectedType}
          variant='sm'
        />

        <AppText
          register={register}
          errors={errors}
          placeholder={t("bio")}
          name='bio'
          mt='30px'
          variant='sm'
          defaultValue={formData.bio ? formData.bio : null}
        />
        <Label>{t("social_media")}</Label>
        <FormControl className='social-accounts'>
          <AppInput
            required={false}
            register={register}
            name='facebook'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='twitter'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='snapchat'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='youtube'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='instagram'
            errors={errors}
          />
          <AppInput
            required={false}
            register={register}
            name='linkedin'
            errors={errors}
          />
        </FormControl>

        <ActionButtonsContainer>
          <div>
            <AppButton size='sm' type='submit'>
              {t("next")}
            </AppButton>
          </div>
        </ActionButtonsContainer>
      </FormContainer>
    </BasicInformationContainer>
  );
};

export default BasicInformation;

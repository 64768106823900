import styled from "styled-components/macro";
import { media } from "styles/utils";

export const PageWrapper = styled.div`
  margin-top: 118px;
`;

export const Title = styled.h1`
  text-align: center;
  font-weight: bold;
`;

export const ContentContainer = styled.div`
  max-width: 80%;
  ${media("medium")} {
    max-width: 100%;
  }
`;

export const SectionTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
`;

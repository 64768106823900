import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "store";

const PrivateOutlet = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
  const isCertified = useAppSelector((state) => state.auth.isCertified);

  return isCertified ? (
    <Navigate to='/dashboard' replace />
  ) : isAuthenticated ? ( //Check if logged in
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to='/login' replace /> //Go back to login if not logged in
  );
};

export default PrivateOutlet;

import { DashboardAvatar, Filter } from "components/Icons";
import AppButton from "components/shared/AppButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trainerAPI } from "services/apis/trainer";
import {
  MessagesHeader,
  MessageItem,
  MessagesItems,
  MessagesWrapper,
  Info,
  Title,
  Message,
  SenderName,
  Date as MessageDate,
} from "./messages.styles";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import arLocale from "date-fns/locale/ar-SA";
import { useAppSelector } from "store";

const MessagesList = () => {
  const [messages, setMessages] = useState<any>([]);
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  const { t } = useTranslation();

  useEffect(() => {
    fetchMyMessages();
  }, []);

  //functions
  const fetchMyMessages = async () => {
    try {
      const response = await trainerAPI.getMySession();

      if (response.data.metaData.key === "success") {
        setMessages(response.data.data.data);
      }
    } catch (error) {}
  };
  return (
    <MessagesWrapper>
      <MessagesHeader>
        <Title>{t("messages")}</Title>
      </MessagesHeader>
      <MessagesItems>
        {messages.length > 0 &&
          messages.map((message) => (
            <MessageItem
              to={`/dashboard/messages/${message.id}`}
              key={message.id}
            >
              <Info isLtr={isLtr}>
                <DashboardAvatar width='77px' height='77px' />
                <div>
                  <SenderName>{message.lastDm?.user.name}</SenderName>
                  <Message>{message.lastDm?.content}</Message>
                </div>
              </Info>
              <MessageDate>
                {message.lastDm
                  ? formatDistanceToNow(new Date(message.lastDm.created_at), {
                      addSuffix: true,
                      locale: arLocale,
                    })
                  : ""}
              </MessageDate>
            </MessageItem>
          ))}
      </MessagesItems>
      {/* <AppButton size='lg'>{t("more")}</AppButton> */}
    </MessagesWrapper>
  );
};

export default MessagesList;

import { DashboardAvatar } from "components/Icons";
import LessonUrlModal from "components/Modals/LessonUrlModal";
import AppButton from "components/shared/AppButton";
import AppLabel from "components/shared/AppLabel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import { IconContext } from "react-icons";
import { FiArrowLeft } from "react-icons/fi";
import {
  AppointmentContainer,
  Title,
  Header,
  StudentName,
  Details,
  DetailItem,
  DetailTitle,
  DetailValue,
  DetailSection,
  SectionTitle,
  MessageStudent,
  BackButton,
  TitleContainer,
} from "./singleAppointment.styles";
import { useAppSelector } from "store";
import { CourseImage } from "components/Dashboard/Courses/courses.styles";
import FreeLessonImage from "assets/images/free_lesson.png";

const SingleAppointment = ({ booking, setShowBooking }) => {
  //hooks
  const { t } = useTranslation();
  const [openUrlModal, setOpenUrlModal] = useState(false);
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  //functions

  const handleOpenModal = () => {
    setOpenUrlModal(true);
  };

  return (
    <AppointmentContainer>
      <TitleContainer isLtr={isLtr}>
        <Title>{t("appointment_details")}</Title>
        <BackButton
          onClick={() => {
            setShowBooking(false);
          }}
        >
          <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
            <FiArrowLeft />
          </IconContext.Provider>
        </BackButton>
      </TitleContainer>
      <Header>
        {booking.session_type === "single" ? (
          <DashboardAvatar width='77px' height='77px' />
        ) : booking.session_type === "free_lesson" ? (
          <img
            src={FreeLessonImage}
            alt='free lesson'
            width='77px'
            height='77px'
          />
        ) : booking.session_type === "workshop" ? (
          <CourseImage src={booking?.event?.cover} width='77px' height='77px' />
        ) : null}

        <StudentName>
          {booking.session_type === "single"
            ? booking.trainee.name
            : booking.event?.title}
        </StudentName>
      </Header>
      <Details>
        <DetailSection>
          <SectionTitle>{t("booking_details")}</SectionTitle>
          <DetailItem>
            <DetailTitle>{t("booking_status")}</DetailTitle>
            <DetailValue>
              <AppLabel variation={booking.status} text={t(booking.status)} />
            </DetailValue>
          </DetailItem>
          <DetailItem>
            <DetailTitle>{t("booking_date")}</DetailTitle>
            <DetailValue>
              {format(new Date(booking.upcoming_session), "EEEE dd MMMM yyyy")}
            </DetailValue>
          </DetailItem>
          {/* <DetailItem>
            <DetailTitle>{t("booking_hours")}</DetailTitle>
            <DetailValue>/ الاسبوع</DetailValue>
          </DetailItem>
          <DetailItem>
            <DetailTitle>{t("lesson_duration")}</DetailTitle>
            <DetailValue>30 دقيقة</DetailValue>
          </DetailItem> */}
          <DetailItem>
            <DetailTitle>{t("lesson_loaction")}</DetailTitle>
            <DetailValue>{t(booking.attendance)}</DetailValue>
          </DetailItem>
        </DetailSection>
        {booking.session_type === "free_lesson" ? null : (
          <>
            <DetailSection>
              <SectionTitle>{t("invoice")}</SectionTitle>
              <DetailItem>
                <DetailTitle>{t("price")}</DetailTitle>
                <DetailValue>
                  <em>{booking.fee_before_vat}</em>
                  {t("riyal")}
                </DetailValue>
              </DetailItem>
              <DetailItem>
                <DetailTitle>{t("vat")}</DetailTitle>
                <DetailValue>
                  <em>{booking.vat}</em>
                  {t("riyal")}
                </DetailValue>
              </DetailItem>
            </DetailSection>
            <hr />
            <DetailSection>
              <DetailItem>
                <DetailTitle className='bold'>{t("total_price")}</DetailTitle>
                <DetailValue>
                  <em>{+booking.fee}</em>
                  {t("riyal")}
                </DetailValue>
              </DetailItem>
            </DetailSection>
          </>
        )}
      </Details>
      <AppButton size='lg' onClick={() => handleOpenModal()}>
        {t("lesson_url")}
      </AppButton>
      {/* <MessageStudent>{t("message_student")}</MessageStudent> */}
      <LessonUrlModal
        open={openUrlModal}
        setOpen={setOpenUrlModal}
        lessonUrl={booking.session_url}
        bookingId={booking.id}
      />
    </AppointmentContainer>
  );
};

export default SingleAppointment;

import styled from "styled-components/macro";
import { space, fontSize } from "styled-system";

const labelColors = {
  upcoming: "rgb(var(--main-color-lighter))",
  active: "rgb(var(--yellow))",
  canceled: "rgb(var(--red))",
  completed: "rgb(var(--green))",
};
export const LabelContainer = styled.span<{
  variation: "upcoming" | "active" | "canceled" | "completed";
  size: "sm" | "md" | "lg";
}>`
  padding: 3px 6px;
  display: inline-block;
  background-color: ${({ variation }) => labelColors[variation]};

  text-align: center;
  color: ${({ variation }) =>
    variation === "active" ? "rgb(var(--border-dark))" : "#fff"};
  font-size: ${({ size }) => (size === "md" ? "1.1rem" : ".9rem")};
  ${space};
  ${fontSize};
`;

import styled from "styled-components/macro";
import { media } from "styles/utils";

//empty course
export const EmptyCoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const EmptyCourseTitle = styled.h1`
  font-size: 2.7rem;
  font-weight: bold;
  margin-top: 40px;
`;
export const EmptyCourseSubTitle = styled.h3`
  color: rgb(var(--black));
  width: 50%;
  text-align: center;
`;

//course list

export const CoursesListContainer = styled.div``;

export const CoursesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div``;
export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;

export const CoursesItems = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
`;
export const CoursesItem = styled.div`
  width: 100%;
  background-color: #fff;
  /* height: 173px; */
  padding: 15px;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-start; */
  position: relative;
  &:not(:first-child) {
    margin-top: 15px;
  }

  ${media("large")} {
    /* height: 200px; */
  }
`;

export const Info = styled.div<{
  isLtr: boolean;
}>`
  margin-left: ${({ isLtr }) => (isLtr ? "14px" : "auto")};
  margin-right: ${({ isLtr }) => (isLtr ? "auto" : "14px")};
`;
export const CourseTitle = styled.h1`
  font-size: 1.6rem;
  margin: 0;
`;
export const CourseImage = styled.img`
  display: block;
  max-width: 110px;
  object-fit: cover;
`;
export const CourseMetaWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 11px;
  flex-wrap: wrap;
`;
export const CourseMeta = styled.div`
  display: flex;
  flex-direction: column;
`;
export const MetaTitle = styled.span`
  color: rgb(var(--light-grey));
  font-size: 1.1rem;
`;
export const MetaValue = styled.span`
  font-size: 1.4rem;
  color: rgb(var(--border-dark));
  margin-top: 4px;
`;
export const CoursePrice = styled.span`
  /* position: absolute; */
  font-weight: bold;
  left: 15px;
  bottom: 15px;
  em {
    font-weight: normal;
    font-size: 1.1rem;
  }
`;

//add course

export const AddCourseWrapper = styled.form``;
export const AddCourseTitle = styled.h1`
  font-size: 2.7rem;
  font-weight: bold;
  margin: 0;
`;

export const InputsWrapper = styled.div`
  background-color: #fff;
  padding: 32px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
`;
export const CoverContainer = styled.div`
  margin-bottom: 40px;
`;

export const ImageUploader = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 323px;
  height: 186px;
  border: 1px dashed #707070;
  background-color: rgb(var(--blue-light));
  cursor: pointer;

  span {
    font-size: 1.4rem;
    color: rgb(var(--main-color));
    margin-top: 18px;
  }

  h1 {
    background-color: rgb(var(--main-color-lighter));
    border: none;
    color: #fff;
    font-size: 1.1rem;
    padding: 10px 18px;
    margin-top: 8px;
    cursor: pointer;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  gap: 11px;
  .app-select {
    width: 100%;
  }
`;

export const Label = styled.span`
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  color: rgb(var(--border-dark));
  align-self: flex-start;
`;

export const SubjectsContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const SubjectInputContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 0;
  margin-bottom: 20px;
  label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: rgb(var(--grey));
    transition: all 0.4s;
  }

  input:focus-visible + label,
  input:not(:placeholder-shown) + label {
    top: 0;
    color: rgb(var(--main-color-lighter));
    font-size: 1.1rem;

    &:after {
      content: " ";
      background-color: #fff;
      width: 100%;
      height: 13px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    max-height: 100%;
  }

  p {
    color: red;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: -15px;
    font-size: 1.1rem;
  }
`;
export const SubjectInput = styled.input`
  width: 100%;
  height: 50px;
  font-size: 1.6rem;
  color: rgb(var(--black));
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding-left: 160px;
  &:focus-visible {
    outline: none;
    border: 3px solid rgb(var(--main-color-lighter));
  }
`;
export const SelectedSubjectsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const SubjectItem = styled.div`
  flex-basis: 40%;
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const SubjectValue = styled.span`
  font-size: 1.2rem;
`;
export const SubjectNumber = styled.span`
  background-color: rgb(var(--off-white));
  font-size: 1.1rem;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CourseLocationContainer = styled.div`
  padding: 15px;
  border: solid 1px rgb(var(--beige));
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0;
`;
export const LocationInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  div {
    display: flex;
    flex-direction: column;
    align-items: self-start;
  }
`;
export const LocationImage = styled.img`
  width: 67px;
  height: 67px;
`;
export const LocationTitle = styled.span`
  font-size: 1.2rem;
`;
export const LocationCity = styled.span`
  color: rgb(var(--grey));
  font-size: 1.1rem;
  margin-top: 8px;
`;
export const DeleteLocation = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: rgb(var(--red));
  font-size: 1.1rem;
  margin-top: 15px;
  cursor: pointer;
`;
export const EditLocation = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  color: rgb(var(--border-dark));
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  width: 323px;
  height: 186px;
  position: relative;
`;

export const DeleteImage = styled.button`
  background: transparent;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(var(--red));
  border-radius: 50%;
  padding: 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(var(--red));
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
`;

export const UploadedCourseCover = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px dotted rgb(var(--main-color));
`;

export const LocationSuggestions = styled.div`
  height: 57px;
  padding: 16px;
  border: solid 1px rgb(var(--beige));
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0;

  h1 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
    color: rgb(var(--border-dark));
  }
  svg {
    cursor: pointer;
  }
`;

export const CourseDatesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  margin-top: 10px;
`;
export const DateItem = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;
  width: 100%;
`;

export const InputContainer = styled.div`
  width: 100%;

  input {
    width: 100%;
    height: 50px;
    font-size: 1.6rem;
    color: rgb(var(--black));
    border: solid 1px rgba(0, 0, 0, 0.12);
    padding: 10px 14px;
    margin-top: 3px;
    &:focus-visible {
      outline: none;
      border: 3px solid rgb(var(--main-color-lighter));
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 125px !important;
  }
  .react-datepicker__time-container {
    width: 125px !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    font-size: 16px;
  }
`;

export const DeleteButton = styled.button`
  background-color: rgb(var(--red));
  border: none;
  padding: 13px;
  color: white;
  cursor: pointer;
`;
export const LessonUrl = styled.button`
  background-color: rgb(var(--beige));
  border: none;
  color: #363b9e;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 12px 24px;
  cursor: pointer;
`;

export const CourseContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const ItemFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;
`;

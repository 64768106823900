import styled, { css } from "styled-components/macro";

const SENDER = css`
  margin-right: auto;
  margin-left: unset;
`;

const MESSAGE_SENDER = css`
  background: #fff;
  color: rgb(var(--main-color-lighter));
`;

const DATE_SENDER = css`
  text-align: left;
  margin-left: 4px;
  margin-top: 4px;
`;

export const SingleMessageWrapper = styled.div``;
export const ChatContainer = styled.div`
  max-height: 700px;
  overflow-y: scroll;
  margin-top: 60px;

  /* ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: rgb(var(--main-color-light));
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  } */
`;
export const ChatItem = styled.div<{ sender: boolean }>`
  max-width: 400px;
  margin-bottom: 30px;

  /* color: #fff;
  background: rgb(var(--main-color-lighter)); */
  margin-left: auto;

  ${({ sender }) => sender && SENDER};
`;
export const ChatContent = styled.p<{ sender: boolean }>`
  color: #fff;
  background: rgb(var(--main-color-lighter));
  padding: 15px;

  ${({ sender }) => sender && MESSAGE_SENDER};
`;
export const ChatDate = styled.span<{ sender: boolean }>`
  font-size: 1.1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: rgb(var(--grey));
  display: block;
  ${({ sender }) => sender && DATE_SENDER};
`;

export const SendMessageContainer = styled.div`
  position: relative;
  margin-top: 18px;
`;
export const MessageInput = styled.input`
  width: 100%;
  height: 66px;
  border: solid 1px #ebf2ff;
  padding: 0 20px 0 70px;
`;
export const SendButton = styled.button`
  background-color: rgb(var(--main-color));
  border: none;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 55px;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
`;

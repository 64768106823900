import AppButton from "components/shared/AppButton";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { ModalTitle } from "../EditDayModal/editDayModal.styles";
import {
  ActionsContainer,
  ModalBodyContainer,
} from "./confirmationModal.styles";

const ConfirmationModal = ({
  setOpen,
  open,
  handleSubmitForm,
  submitLoading,
}) => {
  //hooks
  const modalRef = useRef(null);
  const { t } = useTranslation();

  //functions

  const handleCancelSubmit = () => {
    setOpen(false);
  };

  const handleConfirmSubmit = () => {
    handleSubmitForm();
  };
  return (
    <Modal
      ref={modalRef}
      open={open}
      onClose={() => setOpen(false)}
      initialFocusRef={modalRef}
      showCloseIcon={true}
      closeOnOverlayClick={true}
      classNames={{
        root: "custom-root",
        overlay: "app-modal-overlay",
        modal: "app-modal review-app-modal",
        closeButton: "app-modal-close",
      }}
      center
    >
      <ModalBodyContainer>
        <ModalTitle>{t("request_confirm")}</ModalTitle>

        <ActionsContainer>
          <AppButton
            size='sm'
            variant='outline'
            onClick={() => handleCancelSubmit()}
          >
            {t("cancel")}
          </AppButton>

          <AppButton
            size='sm'
            onClick={() => handleConfirmSubmit()}
            loader={submitLoading}
          >
            {t("confirm")}
          </AppButton>
        </ActionsContainer>
      </ModalBodyContainer>
    </Modal>
  );
};

export default ConfirmationModal;

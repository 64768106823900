import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { space, fontSize, color } from "styled-system";

export const StyledLink = styled(Link)`
  font-size: 1.8rem;
  padding: 20px;
  text-decoration: none;

  background: ${(props) =>
    props.type === "primary"
      ? "rgb(var(--main-color))"
      : "rgb(var(--off-white))"};
  border: 1px solid;
  border-color: ${(props) =>
    props.type === "primary"
      ? "transparent"
      : "rgb(var(--main-color-lighter))"};
  color: ${(props) =>
    props.type === "primary" ? "white" : "rgb(var(--main-color-lighter))"};
  ${space};
  ${fontSize};
  ${color}
`;

import PackagesList from "components/Dashboard/Packages/PackagesList";
import DashboardLayout from "layouts/DashboardLayout";

export const PackagesContainer = () => {
  return (
    <DashboardLayout>
      <PackagesList />
    </DashboardLayout>
  );
};

import { ImageInput } from "components/AccountSetup/Steps/Education/education.styles";
import AppButton from "components/shared/AppButton";
import AppChips from "components/shared/AppChips";
import AppInput from "components/shared/AppInput";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import { FiUploadCloud } from "react-icons/fi";
import {
  AddCourseTitle,
  AddCourseWrapper,
  CourseDatesContainer,
  CourseImage,
  CourseLocationContainer,
  CoverContainer,
  DateItem,
  DeleteButton,
  DeleteImage,
  DeleteLocation,
  EditLocation,
  ImageUploader,
  ImageWrapper,
  InputContainer,
  InputGroup,
  InputsWrapper,
  Label,
  LocationCity,
  LocationImage,
  LocationInfo,
  LocationSuggestions,
  LocationTitle,
  SelectedSubjectsContainer,
  SubjectInput,
  SubjectInputContainer,
  SubjectItem,
  SubjectNumber,
  SubjectsContainer,
  SubjectValue,
  UploadedCourseCover,
} from "./courses.styles";
import { IoIosArrowBack } from "react-icons/io";
import LocationSuggestionsModal from "components/Modals/LocationSuggestionsModal";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import { trainerAPI } from "services/apis/trainer";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import ReactDatePicker from "react-datepicker";
import {
  getMidnightTime,
  getTimeFromString,
  getTimeString,
  isTimeBigger,
} from "helpers/utils";

const chipsOptions = [
  { name: "online", value: "online" },
  { name: "on_site", value: "on_site" },
];
const AddCourse = () => {
  //hooks
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState("online");
  const [courseCover, setCourseCover] = useState<File | undefined>();
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<any>({});
  const [prices, setPrices] = useState<any>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dateValues, setDateValues] = useState<any>([
    { course_date: "", from: "", to: "" },
  ]);

  const [selectedSubjects, setSelectedSubjects] = useState<string[] | never>(
    []
  );
  const [currentSubject, setCurrentSubject] = useState("");
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchPrices();
  }, []);
  //functions

  const fetchPrices = async () => {
    try {
      const response = await trainerAPI.getPrices();

      if (response.data.metaData.key === "success") {
        setPrices(
          response.data.data.map((price) => ({
            label: price.label,
            value: price.id,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  //functions
  const handleLocationChoice = (value: string) => {
    setSelectedLocation(value);
  };
  const onSubmit = async (data) => {

    if (!courseCover) {
      toast.error(t("upload_cover_message"));
      return;
    }
    if (!selectedSubjects.length) {
      toast.error(t("select_topics_message"));
      return;
    }
    let placeId =
      selectedLocation === "on_site" && selectedPlace?.id
        ? selectedPlace.id
        : null;
    try {
      setSubmitLoading(true);
      let formData = new FormData();
      formData.append("type", "workshop");
      formData.append("cover", courseCover);
      formData.append("title", data.course_name);
      formData.append("attendance", selectedLocation);
      formData.append("trainer_id", JSON.stringify(user.id));
      formData.append("num_hours", data.course_hours);
      formData.append("starts_at", data.start_date);
      formData.append("price_id", data.fee);
      if (placeId) {
        formData.append("place_id", placeId);
      }
      formData.append("topics", JSON.stringify(selectedSubjects));

      dateValues.forEach((courseDate) => {
        formData.append(`event_dates[]`, JSON.stringify(courseDate));
      });

      const response = await trainerAPI.createEvent(formData);
      if (response.data.metaData.key === "success") {
        navigate("/dashboard/courses");

        toast.success(t("added_successfully"));
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleAddSubject = () => {
    if (!currentSubject) return;
    setSelectedSubjects([...selectedSubjects, currentSubject]);
    setCurrentSubject("");
  };
  const handleUploadCover = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files) {
      return;
    }
    setCourseCover(files[0]);
  };

  const handleDeleteImage = () => {
    setCourseCover(undefined);
  };

  const handleSubjectChange = (event) => {
    setCurrentSubject(event.target.value);
  };

  const handleDatesChange = (e, i) => {
    const newFormValues = [...dateValues];

    if (e.target.name === "from") {
      if (
        newFormValues[i]["to"].length > 0 &&
        isTimeBigger(e.target.value, newFormValues[i]["to"])
      ) {
        toast.error(t("lower_time_error"));
        return;
      }
    }
    if (e.target.name === "to") {
      if (
        newFormValues[i]["from"].length > 0 &&
        isTimeBigger(newFormValues[i]["from"], e.target.value)
      ) {
        toast.error(t("higher_time_error"));
        return;
      }
    }
    newFormValues[i][e.target.name] = e.target.value;
    setDateValues(newFormValues);
  };

  const addFormFields = () => {
    setDateValues([...dateValues, { course_date: "", from: "", to: "" }]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...dateValues];
    newFormValues.splice(i, 1);
    setDateValues(newFormValues);
  };

  const handleTimeChange = () => {};

  return (
    <AddCourseWrapper onSubmit={handleSubmit(onSubmit)}>
      <AddCourseTitle> {t("add_course")}</AddCourseTitle>
      <InputsWrapper>
        <CoverContainer>
          {courseCover ? (
            <ImageWrapper>
              <UploadedCourseCover
                src={URL.createObjectURL(courseCover)}
                alt='image'
              />
              <DeleteImage type='button' onClick={() => handleDeleteImage()}>
                X
              </DeleteImage>
            </ImageWrapper>
          ) : (
            <ImageUploader htmlFor='file'>
              <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
                <FiUploadCloud />
              </IconContext.Provider>
              <span>{t("course_cover")}</span>
              <h1>{t("select_from_device")}</h1>
            </ImageUploader>
          )}
          <ImageInput
            type='file'
            id='file'
            onChange={(e) => handleUploadCover(e)}
          />
        </CoverContainer>
        <InputGroup>
          <AppInput register={register} name='course_name' errors={errors} />
          <Controller
            control={control}
            render={({ field: { onChange, value, name, ref } }) => (
              <Select
                ref={ref}
                value={prices.find((c) => c.value === value)}
                name={name}
                options={prices}
                placeholder={t("select_course_price")}
                className='app-select'
                onChange={(selectedOption) => {
                  onChange(selectedOption?.value);
                }}
              />
            )}
            rules={{ required: true }}
            name={"fee"}
          />
          {errors?.fee && <p className='error-message'>السعر مطلوب</p>}
        </InputGroup>
        <Label>{t("course_location")}</Label>
        <InputGroup>
          <AppChips
            options={chipsOptions}
            handleChoice={(value) => handleLocationChoice(value)}
            selectedChoice={selectedLocation}
          />
        </InputGroup>
        {selectedLocation === "on_site" && (
          <>
            {Object.keys(selectedPlace).length > 0 ? (
              <CourseLocationContainer>
                <LocationInfo>
                  <LocationImage alt='' />
                  <div>
                    <LocationTitle>{selectedPlace?.name}</LocationTitle>
                    <LocationCity>{selectedPlace?.city_name}</LocationCity>
                    <DeleteLocation
                      onClick={() => {
                        setSelectedPlace({});
                      }}
                    >
                      {t("delete_location")}
                    </DeleteLocation>
                  </div>
                </LocationInfo>
                <EditLocation
                  type='button'
                  onClick={() => setOpenSuggestions(true)}
                >
                  {t("edit_location")}
                </EditLocation>
              </CourseLocationContainer>
            ) : (
              <LocationSuggestions>
                <h1>{t("location_suggestions_title")}</h1>
                <IoIosArrowBack
                  onClick={() => {
                    setOpenSuggestions(true);
                  }}
                />
              </LocationSuggestions>
            )}
          </>
        )}
        <InputGroup>
          <AppInput
            register={register}
            name='course_hours'
            errors={errors}
            type='number'
          />
          <AppInput
            register={register}
            name='start_date'
            errors={errors}
            type='datetime-local'
          />
        </InputGroup>
        <Label>{t("course_dates")}</Label>
        <CourseDatesContainer>
          {React.Children.toArray(
            dateValues.map((element, index) => {
              return (
                <DateItem>
                  <InputContainer>
                    <label htmlFor='course_date'> {t("day")}</label>

                    <input
                      name='course_date'
                      type='date'
                      onChange={(e) => handleDatesChange(e, index)}
                    />
                  </InputContainer>
                  <InputContainer>
                    <label htmlFor='from'> {t("from")}</label>

                    <ReactDatePicker
                      selected={
                        element
                          ? getTimeFromString(element.from)
                          : getMidnightTime()
                      }
                      onChange={(date) =>
                        handleDatesChange(
                          {
                            target: {
                              name: "from",
                              value: getTimeString(date),
                            },
                          } as React.ChangeEvent<HTMLInputElement>,
                          index
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption='Time'
                      dateFormat='h:mm aa'
                      required
                    />
                  </InputContainer>
                  <InputContainer>
                    <label htmlFor='to'> {t("to")}</label>
                    <ReactDatePicker
                      selected={
                        element
                          ? getTimeFromString(element.to)
                          : getMidnightTime()
                      }
                      onChange={(date) =>
                        handleDatesChange(
                          {
                            target: {
                              name: "to",
                              value: getTimeString(date),
                            },
                          } as React.ChangeEvent<HTMLInputElement>,
                          index
                        )
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={30}
                      timeCaption='Time'
                      dateFormat='h:mm aa'
                      required
                    />
                  </InputContainer>
                  {index ? (
                    <DeleteButton
                      type='button'
                      onClick={() => removeFormFields(index)}
                    >
                      <span>{t("delete")}</span>
                    </DeleteButton>
                  ) : null}
                </DateItem>
              );
            })
          )}
          <AppButton size='sm' type='button' onClick={() => addFormFields()}>
            {t("add_date")}
          </AppButton>
        </CourseDatesContainer>

        <SubjectsContainer>
          <SubjectInputContainer>
            <SubjectInput
              type='text'
              onChange={handleSubjectChange}
              value={currentSubject}
              placeholder=' '
            />
            <label htmlFor='phone'>{t("subjects")}</label>
            <AppButton
              onClick={() => handleAddSubject()}
              type='button'
              size='sm'
            >
              {t("add")}
            </AppButton>
          </SubjectInputContainer>
          <SelectedSubjectsContainer>
            {selectedSubjects.length > 0 &&
              selectedSubjects.map((subject, index) => (
                <SubjectItem key={subject}>
                  <SubjectNumber>{index + 1}</SubjectNumber>
                  <SubjectValue>{subject}</SubjectValue>
                </SubjectItem>
              ))}
          </SelectedSubjectsContainer>
        </SubjectsContainer>
      </InputsWrapper>

      <AppButton size='lg' type='submit' loader={submitLoading}>
        {t("submit_course")}
      </AppButton>
      <LocationSuggestionsModal
        open={openSuggestions}
        setOpen={setOpenSuggestions}
        setSelectedPlace={setSelectedPlace}
      />
    </AddCourseWrapper>
  );
};

export default AddCourse;

import { EmptyCourse } from "components/Icons";
import AppButton from "components/shared/AppButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  EmptyCoursesContainer,
  EmptyCourseTitle,
  EmptyCourseSubTitle,
} from "./courses.styles";

const EmptyCourses = () => {
  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  //functions
  const handleAddCourse = () => {
    navigate("/dashboard/courses/add");
  };

  return (
    <EmptyCoursesContainer>
      <EmptyCourse />
      <EmptyCourseTitle>{t("courses")}</EmptyCourseTitle>
      <EmptyCourseSubTitle>{t("empty_courses")}</EmptyCourseSubTitle>
      <AppButton onClick={() => handleAddCourse()} mt='35px'>
        {t("add_course")}
      </AppButton>
    </EmptyCoursesContainer>
  );
};

export default EmptyCourses;

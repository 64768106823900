import SingleMessage from "components/Dashboard/Messages/SingleMessage";
import DashboardLayout from "layouts/DashboardLayout";

const SingleMessageContainer = () => {
  return (
    <DashboardLayout>
      <SingleMessage />
    </DashboardLayout>
  );
};

export default SingleMessageContainer;

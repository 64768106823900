import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const AppointmentsWrapper = styled.div``;
export const AppointmentsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;
export const FiltersContainer = styled.div`
  width: 87px;
  height: 41px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  span {
    color: rgb(var(--main-color));
    font-size: 1.1rem;
    font-weight: bold;
  }
`;
export const AppointmentsItems = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
`;
export const AppointmentsItem = styled.div`
  width: 100%;
  background-color: #fff;
  height: 107px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  cursor: pointer;
  &:not(:first-child) {
    margin-top: 15px;
  }
`;
export const Image = styled.div``;

export const Info = styled.div<{
  isLtr: boolean;
}>`
  margin-left: ${({ isLtr }) => (isLtr ? "12px" : "auto")};
  margin-right: ${({ isLtr }) => (isLtr ? "auto" : "12px")};
`;
export const StudentName = styled.h2`
  margin: 0;
`;
export const AppointmentDate = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 8px;
`;
export const Date = styled.span``;
export const Time = styled.span``;
export const AppointmentType = styled.span`
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
`;

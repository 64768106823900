import AppButton from "components/shared/AppButton";
import { useTranslation } from "react-i18next";
import {
  CoursesListContainer,
  CoursesHeader,
  Title,
  ButtonWrapper,
  CoursesItems,
  CoursesItem,
  Info,
  CourseTitle,
  CourseImage,
  CourseMetaWrapper,
  CourseMeta,
  MetaTitle,
  MetaValue,
  CoursePrice,
  LessonUrl,
  CourseContent,
  ItemFooter,
} from "./courses.styles";
import CourseCover from "assets/images/course.png";
import AppLabel from "components/shared/AppLabel";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IEvent } from "types";
import { useAppSelector } from "store";
import { trainerAPI } from "services/apis/trainer";
import format from "date-fns/format";
import EmptyCourses from "./EmptyCourses";
import LessonUrlModal from "components/Modals/LessonUrlModal";
import arLocale from "date-fns/locale/ar-SA";
import enLocale from "date-fns/locale/en-US";

const CoursesList = () => {
  //hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [courses, setCourses] = useState<IEvent[]>([]);
  const user = useAppSelector((state) => state.auth.user);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<any>({});
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  useEffect(() => {
    fetchCourses();
  }, []);

  //functions
  const handleLessonUrlClick = (course) => {
    if (course.status === "completed") return;
    setSelectedCourse({ ...course });
    setOpenModal(true);
  };
  //API calls
  const fetchCourses = async () => {
    try {
      const response = await trainerAPI.getEvents(user.id, "workshop");

      if (response.data.metaData.key === "success") {
        setCourses(response.data.data.data);
      }
    } catch (error) {}
  };
  return (
    <CoursesListContainer>
      {courses.length > 0 ? (
        <>
          <CoursesHeader>
            <Title>{t("courses")}</Title>
            <ButtonWrapper>
              <AppButton
                onClick={() => {
                  navigate("/dashboard/courses/add");
                }}
                size='lg'
                p='14px'
                fontSize='11px'
                backgroundColor='#434db4'
              >
                {t("add_course")}
              </AppButton>
            </ButtonWrapper>
          </CoursesHeader>

          <CoursesItems>
            {courses.length > 0 &&
              courses.map((course) => (
                <CoursesItem key={course.id}>
                  <CourseContent>
                    <CourseImage
                      src={course.cover}
                      width='77px'
                      height='77px'
                    />
                    <Info isLtr={isLtr}>
                      <CourseTitle>{course.title}</CourseTitle>
                      <CourseMetaWrapper>
                        <CourseMeta>
                          <MetaTitle> {t("course_durateion")}</MetaTitle>
                          <MetaValue>{course.num_hours} ساعات</MetaValue>
                        </CourseMeta>
                        <CourseMeta>
                          <MetaTitle> {t("course_location")}</MetaTitle>
                          <MetaValue>{t(course.attendance)}</MetaValue>
                        </CourseMeta>
                        <CourseMeta>
                          <MetaTitle> {t("reserved_number")}</MetaTitle>
                          <MetaValue>{course.number_of_bookings}</MetaValue>
                        </CourseMeta>
                        <CourseMeta>
                          <MetaTitle> {t("start_date")}</MetaTitle>
                          <MetaValue>
                            {format(
                              new Date(course.starts_at),
                              "dd EEEE MMMM yyyy",
                              {
                                locale: isLtr ? enLocale : arLocale,
                              }
                            )}
                          </MetaValue>
                        </CourseMeta>
                      </CourseMetaWrapper>
                    </Info>
                    <AppLabel
                      variation={
                        course.status as
                          | "upcoming"
                          | "active"
                          | "canceled"
                          | "completed"
                      }
                      size='md'
                      text={t(course.status)}
                    />
                  </CourseContent>
                  <ItemFooter>
                    <CoursePrice>
                      {course?.fee}
                      <em> {t("riyal")}</em>
                    </CoursePrice>
                    {course.attendance === "online" ? (
                      <LessonUrl onClick={() => handleLessonUrlClick(course)}>
                        {t("lesson_url")}
                      </LessonUrl>
                    ) : null}
                  </ItemFooter>
                </CoursesItem>
              ))}
          </CoursesItems>
        </>
      ) : (
        <EmptyCourses />
      )}
      <LessonUrlModal
        open={openModal}
        setOpen={setOpenModal}
        lessonUrl={selectedCourse.session_url}
        bookingId={selectedCourse.id}
        fetchResources={fetchCourses}
        type='event'
      />
    </CoursesListContainer>
  );
};

export default CoursesList;

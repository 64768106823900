import { useTranslation } from "react-i18next";
import { PageWrapper, Title, ContentContainer } from "./termsContent.styles";

const TermsContent = () => {
  const { t, i18n } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  return (
    <PageWrapper>
      <Title>{t("terms")}</Title>
      {getLanguage() === "ar" ? (
        <ContentContainer>
          <p>
            يعد التسجيل في التطبيق إقرار بالموافقة على كل بنود وثيقة الشروط
            والأحكام الآتية:
          </p>
          <br />
          <h4>
            <strong> البند: (1): التعريفات:</strong>
          </h4>
          <p>
            ما لم يقتض سياق النص خلاف ذلك، فإن الكلمات التالية في هذه الاتفاقية
            تدل على المعاني التالية:
            <br />
            ● إن "التطبيق" يقصد به تطبيق ("علمني") ، وهو منصة رقمية على الإنترنت
            والذي بواسطته سيتمكن الزائرين من التعرف والإشتراك في الخدمات التي
            سيوفرها مزودي هذه الخدمات حسب تعريف وثيقة الشروط والأحكام هذه
            <br />
            ● إن "الجهة المتحكمة" و" الوسيط" و"نحن" و "المؤسسة" و"لنا" و"بنا"
            يقصد بها التطبيق والذي يلعب دور الوسيط بين المستخدمين وبين مزودي
            الخدمة.
            <br />
            ● إن "المستخدم" يقصد بها المستفيد من الخدمة الذي يوجد له حساب شخصي
            على التطبيق ومدوّن فيه بياناته الشخصية حسب الشروط والأحكام.
            <br />
            ● إن "بيانات المستخدم" يقصد بها البيانات التي يستعملها المستخدم
            للدخول إلى التطبيق وهي على سبيل المثال لا الحصر (الإسم المختصر
            للمستخدم، كلمة المرور، تاريخ الميلاد، المعلومات الشخصية ، العنوان،
            معلومات البطاقة المصرفية ... ألخ)
            <br />
            ● إن " الخدمة " يقصد بها خدمة التي يتم الإعلان عنها على التطبيق
            ويقوم بتوفيرها المدرب/ المعلم للمستخدم حسب أحكام التطبيق.
            <br />
            ● إن "المدرب" و "المعلم" و "مزود الخدمة" يقصد به المتعاقدين مع
            الوسيط لتوفير الخدمات للمستخدمين عن طريق التطبيق.
            <br />
            ● إن "الشروط والأحكام" يقصد بها وثيقة الشروط والأحكام هذه والمعتمدة
            على الصفحة الإلكترونية للتطبيق مع ما تحتويه من احكام وشروط وكذلك أي
            تعديلات أو تحديثات ممكن أن تطرأ عليها مستقبلاً.
            <br />
            ● إن "سياسة الخصوصية" يقصد بها سياسة الخصوصية للتطبيق المعتمدة على
            الصفحة الإلكترونية للتطبيق، وتشمل أيضاً أي من التعديلات أو التحديثات
            التي من الممكن أن تطرأ عليها مستقبلاً.
            <br />
            ● إن "فترة السماح" هي المهلة التي تنتهي قبل ( ) ساعة من الموعد
            المحدد للمحاضرة أو الدورة كحد أقصى، والتي بإمكان المستخدم خلالها أن
            يتقدم بطلب لإلغاء إشتراكه في هذه الدورة أو المحاضرة عن طريق حسابه
            الشخصي وإسترداد بدل الإشتراك ذات الصلة.
            <br />
            ● إن "بدل الإشتراك" هو القيمة المالية المحددة من المعلم/ المدرب عن
            طريق التطبيق والتي بتسديدها سيتمكن المستخدم من الإشتراك في الدورة أو
            المحاضرة ذات الصلة.
            <br />
          </p>
          <br />
          <br />
          <h4>
            <strong>البند:(2): حساب المستخدم والتزاماته:</strong>
          </h4>
          <p>
            1- يحق لأي متصفح أو زائر للموقع الإلكتروني للتطبيق، إستخدامه و إنشاء
            حساب شخصي له في التطبيق يذكر فيه البيانات الشخصية حسب متطلبات
            التطبيق ويشار إليه لاحقاً بـ "المستخدم". ومن أجل هذا الإستخدام
            بإمكان المستخدم تعيين "إسم مختصر" يستخدمه كرمز للدخول إلى التطبيق
            ويكون مرتبط مباشرة بـ"كلمة مرور" يحددها المستخدم حسب شروط التطبيق.
            <br />
            2- يتعهد المستخدم طيلة فترة إستخدامه للتطبيق، أن يقدم بيانات حقيقية
            وصحيحة ويكون مسؤولاً عن تحديثها في التطبيق حال حصول أي تغيير عليها.
            <br />
            3- يقر المستخدم أنه عند تسجيل حسابه الشخصي في التطبيق، قد قام
            بالإطلاع على بنود "وثيقة الشروط والأحكام" و"سياسة الخصوصية" الخاصة
            بالتطبيق ووافق عليها وعلى أي تعديل ممكن أن يطرأ عليها مستقبلاً من
            جانب الجهة المتحكمة دون قيد أو شرط.
            <br />
            4- تقوم الجهة المتحكمة حسب مشيئتها الخاصة، بإجراء التعديلات أو
            التحديثات على بنود وثيقة الشروط والأحكام وسياسة خصوصية الخاصة
            بالتطبيق في أي وقت تراه مناسباً، دون أن يكون للمستخدم أي إعتراض في
            هذا الشأن
            <br />
            5- يتم إبلاغ المستخدم عن أي تعديل أو تحديث على بنود وثيقة الشروط
            والأحكام وسياسة خصوصية الخاصة بالتطبيق، والتي ترغب الجهة المتحكمة أن
            تقوم به سواء عن طريق الموقع الإلكتروني أو بموجب إشعار إلى بريد
            المستخدم الإلكتروني. وفي هذه الحالة يكون أمام المستخدم مهلة (48)
            ثمانية وأربعون ساعة من تاريخ الإعلان أو إرسال البريد الإلكتروني ذات
            الصلة بالتعديل، لإلغاء إشتراكه من التطبيق وإلا فإن المستخدم يعتبر
            موافقاً على التعديل أو التحديث ولا يحق له الإعتراض عليه مستقبلاً.
            <br />
            6- يتعهد المستخدم أنه لن يستخدم حسابه في التطبيق للقيام بأي عمل
            مخالف للأنظمة السارية في المملكة أو للقيام بأي عمل مخالف للآداب كما
            يتعهد المستخدم أنه سيكون المسؤول الوحيد عن أي نشاط يحدث من جهته عبر
            حسابه ويتحمل تبعاته.
            <br />
            7- يتعهد المستخدم بالمحافظة على حسابه الشخصي وألا يكشف لأي شخص عن
            بياناته أو كلمة المرور الذين يستخدمهم لدخول التطبيق، كما يتعهد بمنع
            أي شخص خلافه من دخول الحساب مستخدماً بياناته الخاصة.
            <br />
            8- يجب على المستخدم أن لا يسجل في حسابه الشخصي سوى البيانات
            والمعلومات الحقيقية والصحيحة كما يكون مسؤولاً عن تحديثها عند اللزوم.
            <br />
            9- يجب على المستخدم إشعار الجهة المتحكمة في التطبيق على وجه الفور
            عند حدوث أي تعد أو اختراق لحسابه في التطبيق، وإلا فإنه يتحمل مسؤولية
            هذا الأمر.
            <br />
            10- يجب ألا يقل عمر المستخدم عن ( ) عامًا، أو يكون قد بلغ سنّ الرشد
            والأهلية القانونية، لكي يحق له التسجيل واستخدام التطبيق، لا يجوز
            للقُصّر استخدام الخدمة إلا بعلم وموافقة وليه، ولا يتحمل التطبيق أي
            مسؤولية عما يترتب على مخالفة ذلك.
          </p>
          <br />
          <br />
          <h4>
            <strong>البند:(3): آلية حجز المواعيد:</strong>
          </h4>
          <br />
          <p>
            بإمكان المستخدم الإشتراك في الدورات والبرامج التي يتم الإعلان عنها
            في التطبيق، وفقاً للآلية التالية:
          </p>
          <br />
          <p>
            1- تقوم الجهة المتحكمة بوضع إعلانات عن برامج أو دورات تعليمية او
            تطويرية في شتى المجالات، سيلقيها نخبة من المعلمين والمدربين بموجب
            إتفاقات مسبقة معهم.
            <br />
            2- لمن يرغب من المستخدمين الإطلاع على البرامج التعليمية أو التطويرية
            المعلن عنها في التطبيق والتعرف على المحاضرين فيها.
            <br />
            3- يحق للمستخدمين الإشتراك في الدورات والمحاضرات لقاء بدل مالي يتم
            تحديده مع الإعلان عن هذه الدورات أو المحاضرات ("بدل الإشتراك").
            <br />
            4- يقوم التطبيق بصفته وسيط بين المستخدم والمعلم / المدرب، بإستيفاء
            بدل الإشتراك من المستخدم مباشرة عن طريق برنامج خاص لهذا الغرض.
            <br />
            5- بعد إنقضاء "فترة السماح" المحددة في الفقرة رقم (1) من البند رقم
            (4) أدناه، يصبح حجز المستخدم في المحاضرة أو الدورة مؤكداً ولا رجوع
            فيه، ولا يكون بدل الإشتراك قابلاً للإسترداد لأي سبب كان بعد هذه
            الفترة.
            <br />
            6- يتولى التطبيق مهمة التنسيق مع المدرب/المعلم فيما يخص جدول
            المواعيد والخدمات المتوفرة على (التطبيق(.
            <br />
            7- بعد اتمام عملية الحجز وتأكيدها يقوم التطبيق بإرسال التنبيهات
            التالية:
            <br />
            1- تنبيه للمستخدم يتضمن تأكيد حجزه في دورة أو محاضرة الذي قام بتسجيل
            إسمه فيها مع تبيان عنوانها وتاريخها وإسم المحاضر فيها.
            <br />
            2- ‌تنبيه للمستخدم يتضمن الرابط الإلكتروني الذي يسمح للمستخدم من
            حضور الدورة أو المحاضرة.
            <br />
            3- تنبيه للمستخدم قبل الموعد بـ( ) دقيقة.
            <br />
            4- تنبيه للمستخدم عند بدأ موعد الجلسة.
            <br />
            5- تنبيه للمستخدم بعد ( ) دقائق من بداية الموعد.
            <br />
            6- تنبيه للمستخدم بإنتهاء الدورة أو المحاضرة.
          </p>
          <br />
          <br />
          <h4>
            <strong>البند:(4): سياسة الإلغاء والتعديل: </strong>
          </h4>

          <br />
          <p>
            1- لا يمكن إلغاء موعد الحجز وإسترداد بدل الإشتراك بعد تسديده، إلا في
            حال تقديم المستخدم طلباً بهذا الشأن قبل ( ) ساعة من الموعد ("فترة
            السماح") عن طريق حسابه الشخصي في التطبيق. لا يعتد بأي طلب من
            المستخدم لإسترداد بدل الإشتراك لأي سبب كان بعد إنقضاء هذه المهلة
            بإستثناء الحالات التي لا يتم تقديم الخدمة بسبب خلل تقني جسيم في
            التطبيق أو فشل مزودي الخدمة بتقديم المحاضرة أو الدورة في الموعد
            المتفق عليه. يقع عبء إثبات وجود خلل أو فشل مزودي الخدمة على عاتق
            المستخدم.
            <br />
            2- بإمكان المستخدم إجراء تعديل على أحد الحجوزات متى كان هذا الأمر
            متاحاً في التطبيق.
            <br />
            3- لا يكون التطبيق مسؤولاً عن أي إلغاء أو فشل في توفير خدمة إلا في
            الإستثناءات المذكورة في الفقرة رقم (1) من هذا البند، ويكون الحد
            الأقصى للتعويض التي يحق للمستخدم المطالبة به، لن يتجاوز قيمة ما سدده
            كبدل إشتراك عن هذه الخدمة.
            <br />
            4- يحق للجهة المتحكمة لوحدها وفي أي وقت تشاء، إلغاء مواعيد الحجوزات
            قبل تاريخ إنعقادها، عندئذ يكون للمستخدم الحق في إسترداد بدل الإشتراك
            المسدد من قبله.
            <br />
            5- يحق للجهة المتحكمة لوحدها وفي أي وقت تشاء، تعديل مواعيد الحجوزات
            قبل تاريخ إنعقادها، عندئذ يكون للمستخدم الحق في إلغاء حجزه شرط تقديم
            طلب بذلك خلال ( ) ساعة من إعلامه بهذا التعديل عبر التطبيق، وإلا فإنه
            يعتبر موافقاً على الموعد الجديد وما تم تسديده كبدل إشتراك لا يمكن
            إسترداده أبداً إلا في الإستثناءات المشار إليها في الفقرة رقم (1)
            أعلاه.
            <br />
            6- أو الموافقة على الموعد الجديد الذي يحدده له التطبيق.
            <br />
            7- لا تتحمل الجهة المتحكمة أي مسؤولية عن حالات التأخير، أو الانقطاع
            في الخدمة أو حدوث أي مشكلة أخرى بالشبكة والاتصالات الإلكترونية أو
            غيرها والتي لا يكون للجهة المتحكمة يد فيها.
          </p>
          <br />
          <br />
          <h4>
            <strong>البند:(5): التحديثات والتعديلات:</strong>
          </h4>
          <br />
          <p>
            1- من حين الى آخر تقوم الجهة المتحكمة بإجراء تعديلات أو تحديثات على
            التطبيق أو أي من الإتفاقيات المرتبطة به، وعلى المستخدم أن يراجع جميع
            هذه الاتفاقيات والموافقة عليها لكي يتمكن من متابعة استخدامه للتطبيق
            وخدماته.
            <br />
            2- للتطبيق مطلق الحق بناء على تقديره الخاص، في تعديل الخدمات في أي
            وقت يراه مناسباً دون أن يكون للمستخدم أي حق بالإعتراض على هذا الأمر
            سوى إلغاء إشتراكه في التطبيق.
            <br />
            3- التطبيق لا يتحمل أي مسؤولية تجاه المستخدم عن الفقرتين (1) و (2)
            المشار إليها آنفاً.
            <br />
            4- في حالة عدم رضا المستخدم على أي من التعديلات او التحديثات على
            التطبيق، فله التوقف فوراً ﻋﻦ استعمال التطبيق او أي من خدماته وإلغاء
            الحساب.
            <br />
            5- يعد استمرار المستخدم في استخدام التطبيق بعد أي من التحديثات او
            التعديلات، إقرارا من المستخدم وموافقة بهذه التغييرات عن جميع
            الخدمات.
            <br />
            <br />
          </p>
          <h4>
            <strong>البند:(6): طرق الدفع والمكافآت:</strong>
          </h4>
          <br />
          <p>
            تتوفر في التطبيق عدة طرق للمستخدم تمكنه من دفع قيمة الحجوزات
            والفواتير عبر خدمة دفع الهايبر باي، وهذه الخدمة المشار إليها آنفا
            تحتفظ بسياساتها الخاصة، (للاطلاع ومعرفة المزيد عن سياسات هذه الخدمة
            يمكن الدخول من خلال الرابط ا()
          </p>
          <br />

          <h4>
            <strong>البند:(7): الخصوصية:</strong>
          </h4>
          <br />
          <p>
            يحمي التطبيق خصوصية المستخدم عند استخدامه، وفق السياسة المتبعة
            بالتطبيق، ويمكن الاطلاع على آلية جمع المعلومات واستخدامها والكشف
            عنها عبر سياسة الخصوصية للتطبيق على الرابط التالي:
            <br />
          </p>
          <br />

          <h4>
            <strong>البند:(8): حقوق الملكية الفكرية: </strong>
          </h4>
          <br />
          <p>
            جميع المواد المتوفرة في هذا التطبيق محمية أو مرخصة بموجب حقوق
            الملكية الفكرية، ويسمح فقط للاستخدام الشخصي والاستخدام غير الربحي
            بالاستفادة من خدمات التطبيق والمعلومات المنشورة عليه، وليس للمستخدم
            الحق في استخدام أي جزء من محتوى التطبيق لأي أغراض تجارية دون الحصول
            على إذن كتابي من الجهة المتحكمة بالتطبيق، وننوه بأن هذا التطبيق قد
            يعرض موادًا تحتوي على حقوق ملكية فكرية لأطراف ثالثة، وهذه الحقوق هي
            ملك لأصحابها ويستفاد منها حسب السياسة التي وضعها اصحابها.
            <br />
          </p>
          <br />
          <h4>
            <strong>البند:(9): إنهاء الاستخدام:</strong>
          </h4>
          <br />
          <p>
            1- المستخدم حر في إلغاء إشتراكه في التطبيق متى شاء شرط أن لا يكون
            ذلك سبباً في إلحاق ضرر بالتطبيق أو الجهة المتحكمة به.
            <br />
            2- تملك الجهة المتحكمة الحق الكامل في إنهاء أو إيقاف أو تعليق خدمات
            المستخدم أو حسابه في التطبيق ومنع الاستخدام حسب تقدير الجهة المتحكمة
            بوجود استخدام غير قانوني أو مخالفة لهذه الشروط والأحكام.
            <br />
            3- سوف تقوم الجهة المتحكمة فوراً ودون إشعار مسبق، بإنهاء أو تعليق
            إستعمال المستخدم لحسابه في التطبيق واتخاذ الإجراءات القانونية عند
            حصول أي من التالي
            <br />
            1- إن وجدت الجهة المتحكمة هذا الأمر وفقاً لتقديرها الخاص ضرورياً من
            أجل حماية المستخدم أو حماية التطبيق أو شركائنا من سرقة الهوية أو
            غيرها من الأنشطة الاحتيالية
            <br />
            2- اذا قام المستخدم على سبيل المثال لا الحصر: ( بإدخال أو تسبب
            بإدخال أي نوع من أنواع الفيروسات او تسبب بأي ضرر أو خلل فني على
            التطبيق ... الخ).
            <br />
            3- إذا قام المستخدم بأي إجراء ومن ضمنها تحميل أي بيانات أو ملفات
            تسببت بإيقاف التطبيق عن العمل، أو قام بتغيير تصاميم التطبيق، أو
            إتلافه.
            <br />
            4- إذا دخل المستخدم دخولاً غير مشروع إلى التطبيق لإلغاء بيانات خاصة،
            أو حذفها، أو إتلافها، أو تغييرها، أو إعادة نشرها.
            <br />
            5- إذا قام المستخدم بأي من التصرفات الإلكترونية التي قد تسيء إلى
            (الجهة المتحكمة للتطبيق أو مزودي الخدمة أو التطبيق) بأي شكل من
            الأشكال ومن ضمنها سمعتهم، أو تعيق التطبيق او تعتبر أعمال غير قانونية
            وفقاً للأنظمة السارية في المملكة أو أي بلد آخر يستخدم فيه التطبيق.
            <br />
          </p>
          <br />
          <h4>
            <strong>البند:(10): الاشعارات والمراسلات:</strong>
          </h4>
          <br />
          <p>
            جميع المراسلات والاشعارات مع إدارة التطبيق تكون عن طريق الوسائل
            الالكترونية المقدمة من المستخدم عند التسجيل في التطبيق
          </p>
          <br />
          <h4>
            <strong>البند:(11): القانون واجب التطبيق:</strong>
          </h4>
          <br />
          <p>
            في حال نشوء خلاف -لا قدر الله- بسبب استخدام هذا التطبيق وأي من
            المواقع المتصلة به، فإن القانون الواجب التطبيق هي أنظمة المملكة
            العربية السعودية، وتختص محاكم المملكة العربية السعودية بالنظر فيها.
          </p>
          <br />
        </ContentContainer>
      ) : (
        <ContentContainer>
          <p>
            By registering for the app, you agree to the following terms and
            conditions:
          </p>
          <br />
          <h4>
            <strong>Clause (1): Definitions:</strong>
          </h4>
          <p>
            The following words shall have the following meanings unless
            otherwise indicated in this Agreement:
            <br />
            ● The “App” refers to the Application called (“Alemni”), which is a
            digital platform on the internet that enables visitors to identify
            and subscribe to the services provided by the providers of these
            services based on the terms and conditions set forth in this terms
            and conditions.
            <br />
            ● The term “data controller” refers to Alemni It acts as a mediator
            between clients and service providers.
            <br />
            ● The term “User” refer to the user using the services with a
            personal account on the app, and whose personal data is recorded in
            it in under these terms and conditions.
            <br />
            ● “User data” means the data that the user uses to access the app,
            including but not limited to (user’s name, password, date of birth,
            personal information, address, bank card information ...etc.)
            <br />
            ● The term “Service” refers to the Service, which is listed in the
            app and is given by the trainer to the user under the app's terms.
            <br />
            ● The terms “The Trainer” or “Service provider” refers to who
            perform the services through the app as contractors to the users..
            <br />
            ● The “Terms and Conditions” means this terms and conditions that is
            approved on the app page with its terms and conditions, as well as
            any amendments or updates that may occur in the future.
            <br />
            ● The term, “privacy policy” means the privacy policy of the appl
            that is certified on the app website, as well as any modifications
            or updates that may occur in the future.
            <br />
            ● The “Cancelation period” is the period that ends ( ) hour before
            the scheduled date of the course, during which the user can cancel
            his attendance to the course through his personal account and
            receive a refund of the applicable subscription price.
            <br />
            ● The “Subscription fees” is the amount value specified by the
            service provider through the app, which the user can pay in order to
            take the course.
            <br />
          </p>
          <br />
          <br />
          <h4>
            <strong>Clause (2): The user’s account and obligations:</strong>
          </h4>
          <p>
            1- Any browser or visitor to the app's website has the right to use
            it and create a personal account in the app, in which he specifies
            personal data in accordance with the app's requirements, and it is
            therefore referred as “the user”. The user can provide a “nickname”
            to be used as a username to sign in, and it is directly linked to a
            “password” that the user chooses according to the app's terms.
            <br />
            2- The user obligates to provide a true and accurate information
            during the duration of using the app and updating in case any
            changes.
            <br />
            3- The user acknowledges upon registering his personal account in
            the app, that he read and understood the App’s “Terms and
            Conditions” and “Privacy Policy” and agrees to it with any future
            amendments to it by the data controller, without any condition.
            <br />
            4- The data controller may, at his own discretion, change or update
            the terms and conditions as well as the privacy policy of the app at
            any time it deems necessary, without the user's objection.
            <br />
            5- The users are informed in case of any amendments to these terms
            of the terms and conditions and the privacy policy of the app either
            through the website or by notice to the user's e-mail. In this case,
            the user has forty-eight (48) hours from the date of announcement or
            sending the e-mail related to the amendment, to cancel his
            subscription from the app, otherwise, the amendment is considered
            approved by the user without objection related to such amendment in
            the future.
            <br />
            6- The user undertakes that he will not use his account in the app
            to carry out any act contrary to the regulations in the Kingdom or
            to carry out any act contrary to morality and the user undertakes
            that he will be solely responsible for any activity that occurs on
            his part through his account and bear the consequences thereof.
            <br />
            7- The User undertakes to maintain his personal account and not to
            disclose to anyone his data or password that he uses to access the
            app, and undertakes to prevent anyone else from entering the account
            using his private data.
            <br />
            8- The user must register in his personal account only valid and
            accurate data and information, and he is responsible for updating
            them as needed.
            <br />
            9- The user shall inform the controller of the app immediately in
            the event of any infringement or breach of his account in the app,
            otherwise the user shall be liable for such matter.
            <br />
            10- The user must be at least age ( ) years old or have reached the
            age of legal capacity, to be entitled to register and use the app.
            Minors may not use the service except with the knowledge and consent
            of their parents, as a result, the app is not responsible for the
            consequences of violating this matter.
          </p>
          <br />
          <br />
          <h4>
            <strong>
              Clause: (3): Scheduling Appointment Reservation Mechanism:
            </strong>
          </h4>
          <br />
          <p>
            The user can participate in the courses and programs that are
            announced in the app, according to the following:
          </p>
          <br />
          <p>
            1- The data controller shall place advertisements for educational or
            developmental programs or courses in various fields, which will be
            delivered by a selection of teachers according to a prior agreement.
            <br />
            2- For those who wish to view the educational or developmental
            programs announced in the app and inquire about the trainers of the
            courses.
            <br />
            3- Users have the right to participate in courses in return for a
            fee to be determined with the announcement of these courses
            (“subscription fees”).
            <br />
            4- The app, as an intermediary between the user and the trainer,
            collects the subscription fee from the user directly through a
            special program for this purpose.
            <br />
            5- After the expiry of the “Cancelation period” specified in
            Paragraph No. (1) of Clause No. (4) below, the user’s reservation in
            the course becomes confirmed and irrevocable, and the subscription
            fee is not refundable for any reason whatsoever after this period.
            <br />
            6- The app undertakes the task of coordinating with the trainer
            regarding the schedule of appointments and services available on
            “Alemni”.
            <br />
            7- After completing and confirming the reservation process, the app
            will send the following alerts:
            <br />
            a- A notice to the user that includes a confirmation of his
            reservation in the course he registered, indicating its address,
            date and the name of the trainer.
            <br />
            b- A notice to the user that includes the e-link that allows the
            user to attend the course.
            <br />
            c- A notice to the user min before the appointment.
            <br />
            d- A notice to the user when the session begins.
            <br />
            e- A notice to the user after ( ) minutes from the start of the
            course.
            <br />
            f- A notice to the user of the end of the course.
          </p>
          <br />
          <br />
          <h4>
            <strong>Clause: (4): Termination and modification policy:</strong>
          </h4>

          <br />
          <p>
            1- It is not possible to cancel the booking appointment and refund
            the subscription fee after it has been paid, unless the user submits
            a request through his personal account in the app before ( ) hour of
            the appointment the “Cancelation period”. Any request from the user
            to retrieve the subscription fee after the expiration of this period
            will not be considered, except in cases where the service is not
            provided due to a serious technical error in the app or due to a
            failure from the service providers to perform the course on the
            arranged date. Proving technical errors or failure of performing the
            services is the user's responsibility.
            <br />
            2- The user can change his schedule whenever it is available in the
            app.
            <br />
            3- The app is not liable for any cancellation or failure in
            providing the service except for the exceptions mentioned in
            paragraph No. (1) of this clause, and the maximum compensation
            entitled for the user, shall not exceed the value of what he paid as
            a subscription fee for the service.
            <br />
            4- The data controller has the right solely any time to cancel the
            reservation dates before the date of conducting it, if that happens,
            the user has the right to recover the subscription fee he paid.
            <br />
            5- The data controller at any time by its sole discretion to modify
            the scheduled dates before the date of the session is held, the user
            has the right to cancel his reservation provided that he submits a
            request for that within ( ) hours of being notified of this
            modification via the app, otherwise he is considered to have agreed
            to the new date and what it has been paid as a subscription fee that
            can never be refunded except in the exceptions referred to in
            paragraph (1) above.
            <br />
            6- Approve the app 's new date.
            <br />
            7- The data controller is not liable for any delays, interruptions
            in service, or the occurrence of any other issue with the network
            and electronic communications over which the data controller has no
            authority.
          </p>
          <br />
          <br />
          <h4>
            <strong>Clause: (5): Updates & amendments:</strong>
          </h4>
          <br />
          <p>
            1- From time to time, the data controller may make changes to the
            app or any of the agreements linked with it, and the user must
            review and agree to all these agreements in order to continue using
            the app and its services.
            <br />
            2- The app retains the right, at its own discretion, to amend the
            services any time whenever it deems necessary, without the right of
            the user to object except by canceling their memberships.
            <br />
            3- The app does not bear any liability towards the user for
            paragraphs (1) and (2) above.
            <br />
            4- If the user is dissatisfied with any of the app's amendments or
            updates, he may immediately stop using the app or any of its
            services and cancel his account.
            <br />
            5- The continuous use of the app by the user after any updates or
            modifications implies recognition and acceptance of these changes
            for all services. الخدمات.
            <br />
            <br />
          </p>
          <h4>
            <strong>Clause: (6): Payment methods and rewards:</strong>
          </h4>
          <br />
          <p>
            The app provides users with several options for paying reservation
            fees and invoices through the HyperPay payment service. The service
            mentioned above has its own policies (to learn more about the
            policies, you can access them by clicking the following link: ( )
          </p>
          <br />

          <h4>
            <strong>Clause: (7): Privacy Policy:</strong>
          </h4>
          <br />
          <p>
            App users' privacy is protected while using the app, as defined in
            the app's privacy policy, which explains the process by which this
            information is collected, used, and disclosed in the following link:
            <br />
          </p>
          <br />

          <h4>
            <strong>Clause: (8): Intellectual property rights:</strong>
          </h4>
          <br />
          <p>
            The contents of this application are protected or licensed by
            intellectual property rights, and the app 's services and
            information can only be used for personal and non-profit purposes,
            and users are not permitted to use any part of the app's content for
            commercial purposes without the written consent of the data
            controller authority. This app may display content containing
            third-party intellectual property rights, which belong to their
            owners and are used in accordance with those owners' policies.
            <br />
          </p>
          <br />
          <h4>
            <strong>Clause: (9): Termination of use:</strong>
          </h4>
          <br />
          <p>
            1- The user has the right to cancel his membership on the app at any
            time, if he does not harm the app or the controller party.
            <br />
            2- Users' services or their accounts in the app may be terminated,
            suspended, or disabled at the discretion of the controller if
            illegal activity or violations of these terms and conditions are
            detected.
            <br />
            3- The data controller may terminate or suspend a user's account
            without prior notice and take legal action if the following events
            occur:
            <br />
            a- If the data controller considers it essential in its sole
            discretion to protect the user, the app, or the partners from
            identity theft or other fraudulent activity.
            <br />
            b- If the user, including, but is not limited to, (upload or causing
            the installation of any type of virus or causing any damage or
            technical defect to the app ...etc).
            <br />
            c- If the user performs any action, such as downloading any data or
            files, which damages the application, destroys it, or prevents it
            from functioning.
            <br />
            d- If the user illegally enters the app to cancel, delete, destroy,
            change, or re-publish private data.
            <br />
            e- If the user participates in any electronic behaviors that may
            offend (the controlling party of the app or service providers or the
            app ) in any way, including their reputation, or inhibit the app, or
            are considered illegal acts under the laws of the Kingdom or any
            other country where the app is used.
            <br />
          </p>
          <br />
          <h4>
            <strong>Clause: (10): Notifications: </strong>
          </h4>
          <br />
          <p>
            Communication and notifications with the app administration shall be
            sent via the electronic means indicated by the user at the time of
            registration in the app.
          </p>
          <br />
          <h4>
            <strong>Clause: (11): Governing law and jurisdiction:</strong>
          </h4>
          <br />
          <p>
            This app or any of its linked sites is subject to the laws of the
            Kingdom of Saudi Arabia and its courts if there is a dispute arising
            out of its use.
          </p>
          <br />
        </ContentContainer>
      )}
    </PageWrapper>
  );
};

export default TermsContent;

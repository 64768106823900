import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  padding: 24px;
  max-width: 720px;
  width: 50vw;

  ${media("medium")} {
    width: 90vw;
  }
  .app-select {
    width: 100%;
    margin: 24px 0;
    text-align: right;
  }
`;
export const PlacesContainer = styled.div`
  margin-top: 30px;
`;
export const PlaceItem = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  input {
    display: block;
    height: 20px;
    width: 20px;
    accent-color: rgb(var(--main-color));
  }
`;
export const PlaceInfo = styled.div`
  display: flex;
  gap: 18px;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const PlaceImg = styled.img``;
export const PlaceName = styled.h1`
  font-weight: bold;
  font-size: 1.6rem;
  margin: 0;
`;
export const PlaceLocation = styled.p`
  font-size: 1.4rem;
  margin-top: 8px;
`;
export const PlaceMapButton = styled.button`
  font-size: 1.1rem;
  color: rgb(var(--main-color-lighter));
  border: solid 1px rgb(var(--purple));
  background-color: rgb(var(--off-white));
  padding: 13px 30px;
  margin-top: 8px;
  cursor: pointer;
`;

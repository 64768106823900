import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AlreadySubscribe,
  AlreadySubscribeContainer,
  PackageAction,
  PackageDescription,
  PackageImage,
  PackageInfo,
  PackageItem,
  PackagePrice,
  PackagesHeader,
  PackagesItems,
  PackagesListContainer,
  PackageTitle,
  SubscribeButton,
  Title,
} from "./packagesList.styles";
import MeetLogo from "assets/images/googl_meet.png";
import { trainerAPI } from "services/apis/trainer";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearPackageInfo, setPackageInfo } from "store/slices/checkout";
import { useAppSelector } from "store";
import format from "date-fns/format";
import arLocale from "date-fns/locale/ar-SA";
import enLocale from "date-fns/locale/en-US";
const PackagesList = () => {
  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [packagesList, setPackagesList] = useState<any>([]);
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  useEffect(() => {
    dispatch(clearPackageInfo());
    fetchPackages();
  }, []);

  //apis
  const fetchPackages = async () => {
    try {
      const response = await trainerAPI.getPackages();

      if (response.data.metaData.key === "success") {
        setPackagesList(response.data.data);
      }
    } catch (error) {}
  };

  //functions
  const handleSubscribeClick = (packageItem) => {
    dispatch(setPackageInfo(packageItem));
    navigate("/dashboard/checkout");
  };
  return (
    <PackagesListContainer>
      <PackagesHeader>
        <Title>{t("alemni_packages")}</Title>
      </PackagesHeader>

      <PackagesItems>
        {packagesList?.length > 0 &&
          packagesList.map((packageItem) => (
            <PackageItem key={packageItem.id}>
              {packageItem.image ? (
                <img
                  src={packageItem.image}
                  alt='google meet'
                  className='package-image'
                />
              ) : (
                <PackageImage>
                  <img src={MeetLogo} alt='google meet' />
                </PackageImage>
              )}
              <PackageInfo>
                <PackageTitle>
                  {isLtr ? packageItem.title_en : packageItem.title}
                </PackageTitle>
                <PackageDescription>
                  {isLtr ? packageItem.description_en : packageItem.description}
                </PackageDescription>
                <PackageAction>
                  <PackagePrice>
                    {packageItem.fee} <em> {t("riyal")}</em>
                  </PackagePrice>
                  {packageItem.is_subscribe ? (
                    <AlreadySubscribeContainer>
                      <AlreadySubscribe>
                        {t("alreadySubscribe")}
                      </AlreadySubscribe>
                      <AlreadySubscribe>
                        {t("expireAt")} :
                        <span>
                          {format(
                            new Date(packageItem.expiry_at),
                            "dd MMMM yyyy",
                            {
                              locale: isLtr ? enLocale : arLocale,
                            }
                          )}
                        </span>
                      </AlreadySubscribe>
                    </AlreadySubscribeContainer>
                  ) : (
                    <SubscribeButton
                      onClick={() => handleSubscribeClick(packageItem)}
                    >
                      {t("subscribe")}
                    </SubscribeButton>
                  )}
                </PackageAction>
              </PackageInfo>
            </PackageItem>
          ))}
      </PackagesItems>
    </PackagesListContainer>
  );
};

export default PackagesList;

import { Bell, ChatBlue } from "components/Icons";
import { useTranslation } from "react-i18next";
import {
  BreadCrumb,
  BreadCrumbLink,
  DashboardHeader,
  Greeting,
  IconContainer,
  IconsContainer,
  InfoContainer,
  MainContentContainer,
} from "./maincontent.styles";
import { RiMenuFoldLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { useAppSelector } from "store";
import { useEffect, useState } from "react";
import { trainerAPI } from "services/apis/trainer";

const MainContent = ({ setShowSidebar, showSidebar, children }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const isLtr = useAppSelector((state) => state.locale.isLtr);
  const [notificationsCounter, setNotificationsCounter] = useState(0);

  useEffect(() => {
    fetchNotificationsCounter();
  }, []);

  const fetchNotificationsCounter = async () => {
    try {
      const response = await trainerAPI.getNotificationsCounter();
      setNotificationsCounter(response.data.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <MainContentContainer isLtr={isLtr}>
      {!showSidebar ? (
        <IconContext.Provider value={{ color: "#5f68bf", size: "2em" }}>
          <RiMenuFoldLine
            className='sidebar-icon'
            onClick={() => setShowSidebar((prev) => !prev)}
          />
        </IconContext.Provider>
      ) : null}
      <DashboardHeader>
        <InfoContainer>
          <Greeting>
            {t("good_morning")} - {user.name}
          </Greeting>
          {/* <BreadCrumb>
            <BreadCrumbLink to='/dashboard'>{t("home")}</BreadCrumbLink>
            &nbsp;/&nbsp;
            <span>المواعيد</span>
          </BreadCrumb> */}
        </InfoContainer>
        <IconsContainer>
          <IconContainer to='/dashboard/messages'>
            <ChatBlue width='16px' height='16px' />
          </IconContainer>
          <IconContainer to='/dashboard/notifications'>
            <Bell width='16px' height='16px' />
            {notificationsCounter > 0 && (
              <span className='notifications-counter'>
                {notificationsCounter}
              </span>
            )}
          </IconContainer>
        </IconsContainer>
      </DashboardHeader>
      {children}
    </MainContentContainer>
  );
};

export default MainContent;

import AppButton from "components/shared/AppButton";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import {
  ButtonWrapper,
  CourseMeta,
  CourseMetaWrapper,
  CoursesHeader,
} from "../Courses/courses.styles";

import {
  PackagesWrapper,
  Title,
  MetaTitle,
  MetaValue,
  PackageItem,
  PackageThumbnail,
  Info,
  ActionsContainer,
  EditLink,
  DeleteButton,
} from "./trainerPackages.styles";
import { toast } from "react-hot-toast";

const TrainerPackages = () => {
  //state
  const [packagesList, setPackagesList] = useState<any>([]);
  const user = useAppSelector((state) => state.auth.user);
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  //hooks
  const navigate = useNavigate();
  const { t } = useTranslation();

  //effects
  useEffect(() => {
    fetchPackages();
  }, []);

  //Api calls
  const fetchPackages = async () => {
    try {
      const response = await trainerAPI.getTrainerPackages(user.id);
      if (response.data.metaData.key === "success") {
        setPackagesList(response.data.data);
      }
    } catch (error) {}
  };

  const handleDeletePackage = async (packageId) => {
    try {
      const response = await trainerAPI.deleteTrainerPackages(
        user.id,
        packageId
      );
      if (response.data.metaData.key === "success") {
        toast.success(t("deleted_successfully"));
        fetchPackages();
      }
    } catch (error) {}
  };

  return (
    <PackagesWrapper>
      <CoursesHeader>
        <Title>{t("custom_packages")}</Title>
        <ButtonWrapper>
          <AppButton
            onClick={() => {
              navigate("/dashboard/custom-packages/add");
            }}
            size='lg'
            p='14px'
            fontSize='11px'
            backgroundColor='#434db4'
          >
            {t("add_package")}
          </AppButton>
        </ButtonWrapper>
      </CoursesHeader>
      {packagesList?.length > 0 &&
        packagesList.map((packageItem, index) => (
          <PackageItem key={packageItem.id}>
            <PackageThumbnail>
              <h1>{t("package_number")}</h1>
              <span>{index + 1}</span>
            </PackageThumbnail>
            <Info isLtr={isLtr}>
              <CourseMetaWrapper>
                <CourseMeta>
                  <MetaTitle> {t("hours_num")}</MetaTitle>
                  <MetaValue>{packageItem.hours}</MetaValue>
                </CourseMeta>
                <CourseMeta>
                  <MetaTitle> {t("fee")}</MetaTitle>
                  <MetaValue>{packageItem.fee}</MetaValue>
                </CourseMeta>
                {/* <CourseMeta>
                  <MetaTitle> {t("discount")}</MetaTitle>
                  <MetaValue>{packageItem.discount}</MetaValue>
                </CourseMeta> */}
              </CourseMetaWrapper>
              <ActionsContainer>
                <EditLink
                  to={`/dashboard/custom-packages/${packageItem.id}/edit`}
                >
                  {t("edit")}
                </EditLink>
                <DeleteButton
                  onClick={() => handleDeletePackage(packageItem.id)}
                >
                  {t("delete")}
                </DeleteButton>
              </ActionsContainer>
            </Info>
            {/*
            {lesson.attendance === "online" ? (
              <LessonUrl onClick={() => handleLessonUrlClick(lesson)}>
                {t("lesson_url")}
              </LessonUrl>
            ) : null} */}
          </PackageItem>
        ))}
    </PackagesWrapper>
  );
};

export default TrainerPackages;

import styled from "styled-components/macro";
import { media } from "styles/utils";

export const HomeWrapper = styled.div`
  margin-top: 118px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 70px;
  grid-row-gap: 0px;
  ${media("medium")} {
    margin-top: 40px;
  }
`;
export const RSectionContainer = styled.div`
  grid-column: 1/2;
  width: 100%;
  ${media("large")} {
    grid-column: 1/3;
  }
`;

export const MainTitle = styled.h1`
  font-size: 3.6rem;
  font-weight: bold;
  max-width: 80%;

  ${media("medium")} {
    max-width: 100%;
    font-size: 2.7rem;
  }
`;

export const SubTitle = styled.h2`
  font-size: 1.4rem;
`;
export const Label = styled.span`
  width: 89px;
  height: 26px;
  padding: 7px 12px 6px;
  background-color: rgb(var(--blue-lighter));
  color: rgb(--main-color);
  font-size: 1.1rem;

  :dir(ltr) {
    margin: 0 0 20px;
  }
`;

export const InfoList = styled.div`
  margin-top: 2em;
  display: flex;
  align-items: center;
  gap: 2em;
`;
export const InfoListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;
export const InfoIcon = styled.div`
  width: 18px;
  height: 18px;
  background-color: rgb(var(--blue-lighter));
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const InfoText = styled.span`
  font-size: 1.4rem;
`;

export const AuthLinksContainer = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 30px;
`;

export const MetaContainer = styled.div`
  margin-top: 50px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 500px;
    height: 100px;
    border: solid 2px #707070;
    border-color: transparent #707070 transparent transparent;
    border-radius: 0 240px 50%/156px 165px;
    top: -72px;
    right: -25px;
  }

  &.ltr:after{
    display:none;
  }
  
  ${media("medium")} {
    display: none;
  }
`;
export const MetaText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 6px;
`;

// Left section

export const LeftSectionContainer = styled.div`
  grid-column: 2/3;
  width: 100%;
  ${media("large")} {
    grid-column: 1/3;
    margin-top: 30px;
  }
`;
export const InfoBoxes = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 40px;
  ${media("medium")} {
    flex-wrap: wrap;
  }
`;
export const InfoBox = styled.div`
  .rotate svg {
    transform: rotate(180deg);
  }
`;
export const InfoTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
`;
export const InfoSubTitle = styled.h2`
  font-size: 1.4rem;
`;
export const StatsText = styled.h2`
  font-size: 1.4rem;
  background-color: rgb(var(--main-color));
  padding: 10px 20px;
  color: #fff;
`;

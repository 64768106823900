import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Eye, Letter, Time } from "components/Icons";
import { useTranslation } from "react-i18next";
import {
  CalendarContainer,
  HomeContainer,
  StatisticsContainer,
  StatisticsItem,
  StatisticsTitle,
  StatisticsValue,
} from "./home.styles";
import { useEffect, useState, useRef } from "react";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import EventDataModal from "components/Modals/EventDataModal/EventDataModal";
import { Calendar } from "@fullcalendar/core";
const Home = () => {
  const { t } = useTranslation();
  const events = [{ title: "today's event", date: new Date() }];
  const user = useAppSelector((state) => state.auth.user);

  const [openModal, setOpenModal] = useState(false);
  const [bookings, setBookings] = useState<any[]>([]);
  const [stats, setStats] = useState<any>({});
  const [courses, setCourses] = useState<any[]>([]);
  const [lessons, setLessons] = useState<any[]>([]);
  const [currentEvent, setCurrentEvent] = useState<any>({});
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  const calendarRef = useRef(null);

  useEffect(() => {
    fetchBookings();
    fetchStats();
    // fetchCourses();
    // fetchLessons();
    checkWindowsize();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", checkWindowsize);
    return () => {
      window.removeEventListener("resize", checkWindowsize);
    };
  }, []);

  //apis

  const checkWindowsize = () => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 500) {
      if (calendarRef.current) {
        const calendar: any = calendarRef.current;
        calendar.getApi().changeView("timeGridDay");
      }
    }
  };

  const fetchBookings = async () => {
    try {
      const response = await trainerAPI.getBookings();
      if (response.data.metaData.key === "success") {
        const bookings = response.data.data.data;
        const reducedBookings = bookings.reduce((acc, current) => {
          if (
            current.session_type !== "free_lesson" &&
            current.session_type !== "workshop"
          ) {
            return acc.concat([current]);
          }

          const x = acc.find((item) => {
            return (
              item.event?.id === current.event?.id &&
              item.trainer.id === current.trainer.id &&
              item.session_type === current.session_type
            );
          });
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        let data = reducedBookings.map((booking) => {
          return {
            title:
              booking.session_type === "single"
                ? `${t(booking.session_type)} with ${booking.trainee.name}`
                : `${t(booking.session_type)} ${booking?.event?.title}`,
            date: new Date(booking.upcoming_session),
            extendedProps: { event: booking },
          };
        });
        setBookings([...data]);
      }
    } catch (error) {}
  };

  const fetchStats = async () => {
    try {
      const response = await trainerAPI.getStats();
      if (response.data.metaData.key === "success") {
        setStats(response.data.data);
      }
    } catch (error) {}
  };

  // const fetchCourses = async () => {
  //   try {
  //     const response = await trainerAPI.getEvents(user.id, "workshop");

  //     if (response.data.metaData.key === "success") {
  //       let data = response.data.data.data.map((event) => {
  //         return {
  //           title: `${t(event.type)} ${event.title}`,
  //           date: new Date(event.starts_at),
  //           extendedProps: { event },
  //         };
  //       });
  //       setCourses([...data]);
  //     }
  //   } catch (error) {}
  // };

  // const fetchLessons = async () => {
  //   try {
  //     const response = await trainerAPI.getEvents(user.id, "free_lesson");

  //     if (response.data.metaData.key === "success") {
  //       let data = response.data.data.data.map((event) => {
  //         return {
  //           title: `${t(event.type)} ${event.title}`,
  //           date: new Date(event.starts_at),
  //           extendedProps: { event },
  //         };
  //       });
  //       setLessons([...data]);
  //     }
  //   } catch (error) {}
  // };

  const handleEventClick = (e) => {
    setCurrentEvent({ ...e.event.extendedProps.event });
    setOpenModal(true);
  };
  return (
    <HomeContainer>
      <StatisticsContainer>
        <StatisticsItem>
          <div>
            <Time />
          </div>
          <StatisticsTitle>{t("teaching_hours")}</StatisticsTitle>
          <StatisticsValue>{stats?.teachingHours}</StatisticsValue>
        </StatisticsItem>

        <StatisticsItem>
          <Letter />
          <StatisticsTitle>{t("teaching_students")}</StatisticsTitle>
          <StatisticsValue>{stats?.bookingsCount}</StatisticsValue>
        </StatisticsItem>
        <StatisticsItem>
          <Eye />
          <StatisticsTitle>{t("profile_views")}</StatisticsTitle>
          <StatisticsValue>{stats?.profileViews}</StatisticsValue>
        </StatisticsItem>
      </StatisticsContainer>

      <CalendarContainer>
        <FullCalendar
          ref={calendarRef}
          initialView='dayGridMonth'
          direction={isLtr ? "ltr" : "rtl"}
          locale={isLtr ? "en" : "ar"}
          editable={true}
          selectable={true}
          buttonText={{
            today: isLtr ? "Today" : "اليوم",
            month: isLtr ? "Month" : "شهر",
            week: isLtr ? "Week" : "أسبوع",
            day: isLtr ? "Day" : "اليوم",
            list: isLtr ? "Events" : "الأحداث",
          }}
          plugins={[
            dayGridPlugin,
            listPlugin,
            timeGridPlugin,
            interactionPlugin,
          ]}
          headerToolbar={{
            start: "title,prev,next",
            end: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
          }}
          events={[...bookings]}
          // dateClick={(e) => handleEventClick(e)}
          eventClick={(e) => handleEventClick(e)}
        />
      </CalendarContainer>
      <EventDataModal
        open={openModal}
        setOpen={setOpenModal}
        booking={currentEvent}
      />
    </HomeContainer>
  );
};

export default Home;

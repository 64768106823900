import {
  ForgetPassword,
  FormContainer,
  LoginFormContainer,
  LoginTitle,
  NewAccountLink,
  NoAccount,
  NoAccountTitle,
  SocialLoginContainer,
} from "./auth.styles";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "components/shared/PhoneInput";
import PasswordInput from "components/shared/PasswordInput";
import { useForm } from "react-hook-form";
import AppButton from "components/shared/AppButton";
import { useAppDispatch, useAppSelector } from "store";
import { login, setSocialLogin } from "store/slices/auth";
import toast from "react-hot-toast";
import { useState } from "react";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { authAPI } from "services/apis/auth";
import { useGoogleLogin } from "@react-oauth/google";
import { GoogleIcon } from "components/Icons";

const LoginForm = ({ setShowOtpForm, setMobileNumber }) => {
  //hooks
  const [submitLoading, setSubmitLoading] = useState(false);
  const isCertified = useAppSelector((state) => state.auth.isCertified);
  const user = useAppSelector((state) => state.auth.user);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      handleGoogleLogin(tokenResponse.access_token);
    },
  });

  const handleGoogleLogin = async (token) => {
    const response = await authAPI.register({
      user_type: "trainer",
      provider: "google",
      provider_token: token,
    });

    if (response.status === 200) {
      dispatch(setSocialLogin(response.data.data));
      navigate("/account-setup");
    } else {
      toast.error(t("error_message"));
    }
  };

  //functions
  const onSubmit = (data) => {
    setSubmitLoading(true);
    dispatch(login(data))
      .unwrap()
      .then((response) => {
        if (response.data.is_certified) {
          navigate("/dashboard");
          return;
        }
        response.data.in_certifying
          ? navigate("/")
          : navigate("/account-setup");
      })
      .catch((error) => {
        if (error.response?.data?.metaData?.key === "not_verified") {
          toast.error(t("not_activated"));
          setMobileNumber(data.mobile);
          setShowOtpForm(true);
          return;
        }
        if (error.response?.data?.metaData?.key === "blocked") {
          toast.error(t("blocked"));
          return;
        }

        if (error.response?.data?.metaData?.key === "invalid_credentials") {
          toast.error(t("login_error"));
          return;
        }
        toast.error(t("error_message"));
      })
      .finally(() => setSubmitLoading(false));
  };

  return (
    <LoginFormContainer>
      <LoginTitle>{t("auth.login_title")}</LoginTitle>
      <NoAccount>
        <NoAccountTitle>{t("auth.dont_have_account")}</NoAccountTitle>
        <NewAccountLink>
          <Link to='/register'>{t("auth.new_account")}</Link>
        </NewAccountLink>
      </NoAccount>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <PhoneInputWithCountry
          name='mobile'
          international
          control={control}
          rules={{ required: true }}
          countryCallingCodeEditable={false}
          defaultCountry='SA'
        />
        <PasswordInput register={register} errors={errors} />
        <ForgetPassword to='/forget'>
          {t("auth.forget_password")}
        </ForgetPassword>
        <AppButton size='lg' mt='40px' fontSize={18} loader={submitLoading}>
          {t("auth.login_title")}
        </AppButton>
      </FormContainer>
      <hr />
      <SocialLoginContainer>
        <h1>{t("social_sign_in_title")}</h1>
        <button onClick={() => googleLogin()}>
          <GoogleIcon />
        </button>
      </SocialLoginContainer>
    </LoginFormContainer>
  );
};

export default LoginForm;

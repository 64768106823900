import { Input, InputContainer } from "./appinput.styles";
import { useTranslation } from "react-i18next";
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface inputProps {
  register: UseFormRegister<FieldValues>;
  errors?: FieldErrorsImpl<DeepRequired<FieldValues>>;
  type?: string;
  name: string;
  required?: boolean;
  defaultValue?: string;
  placeholder?: string;
  pattern?: RegExp | undefined;
  [x: string]: any;
}

const AppInput = ({
  register,
  errors,
  type = "text",
  name,
  required = true,
  pattern,
  placeholder = " ",
  defaultValue,
  ...rest
}: inputProps) => {
  const { t } = useTranslation();

  return (
    <InputContainer>
      <Input
        type={type}
        {...register(name, {
          required: required ? `${t(name)} ${t("required")}` : false,
          pattern: {
            value: pattern as RegExp,
            message: `${t(name)} ${t("invalid_google_link")}`,
          },
        })}
        placeholder={placeholder}
        defaultValue={defaultValue}
        {...rest}
      />
      <label htmlFor={name}>{t(`${name}`)}</label>
      <ErrorMessage
        errors={errors}
        name={name}
        as='p'
        className={errors && errors["session_url"] ? "long_text" : ""}
      />
    </InputContainer>
  );
};

export default AppInput;

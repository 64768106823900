import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  padding: 24px;
  .PhoneInput {
    margin-bottom: 20px;
    flex-direction: row-reverse;
    input {
      width: 100%;
      height: 50px;
      font-size: 1.6rem;
      color: rgb(var(--black));
      border: solid 1px rgba(0, 0, 0, 0.12);
      padding: 10px 14px;
      direction: ltr;
    }
  }

  .otp-container {
    gap: 10px;
    margin: 30px 0;
    direction: ltr;
    div {
      flex: 1;
    }
    ${media("medium")} {
      gap: 5px;
    }
  }
  input.otp-input {
    width: 60px !important;
    max-width: 73.8px;

    height: 56px;
    border: solid 1px #e6eef9;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: rgb(var(--grey));
    background-color: #ffffff;
    &:focus {
      outline: none;
      border: 1px solid #110d0d;
      color: rgb(var(--text-main));
    }
    &:focus + span {
      color: rgb(var(--text-main));
    }
    ${media("medium")} {
      max-width: 52px;
    }
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  gap: 10px;
`;
export const ModalTitle = styled.h1`
  margin: 0 0 30px 0;
  font-size: 2.7rem;
  font-weight: bold;
  text-align: right;
`;

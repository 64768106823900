import OtpForm from "components/Auth/OtpForm";
import RegisterForm from "components/Auth/RegisterForm";
import AuthLayout from "layouts/AuthLayout";
import { useState } from "react";

const RegisterContainer = () => {
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  return (
    <AuthLayout>
      {!showOtpForm ? (
        <RegisterForm
          setShowOtpForm={setShowOtpForm}
          setMobileNumber={setMobileNumber}
        />
      ) : (
        <OtpForm
          setShowOtpForm={setShowOtpForm}
          mobileNumber={mobileNumber}
          source='register'
        />
      )}
    </AuthLayout>
  );
};

export default RegisterContainer;

import styled from "styled-components/macro";

export const BasicInformationContainer = styled.div``;

export const Title = styled.h2`
  font-size: 2.7rem;
  font-weight: bold;
`;
export const SubTitle = styled.h2`
  font-size: 1.4rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  gap: 11px;
  align-items: center;
  margin-top: 24px;
  input {
    display: none;
  }
`;
export const ProfileImage = styled.img``;
export const ImageMeta = styled.div``;
export const ChangeImage = styled.label`
  background: transparent;
  border: none;
  color: rgb(var(--main-color));
  font-size: 1.4rem;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
`;
export const ImageSizes = styled.div`
  margin-top: 16px;
  span {
    font-size: 1.1rem;
    display: block;
  }
`;

//form

export const FormContainer = styled.form`
  width: 100%;
  margin-top: 30px;
`;

export const FormControl = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  .app-select {
    width: 100%;
  }
  &.social-accounts {
    margin-top: 20px;
  }
`;

export const Label = styled.span`
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  margin-top: 24px;
`;

export const ActionButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const IntroContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  .react-player {
    margin-top: 30px;
  }
`;

export const ProfilePicture = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
`;

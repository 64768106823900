import {
  RSectionContainer,
  Label,
  MainTitle,
  SubTitle,
  InfoList,
  InfoListItem,
  InfoIcon,
  InfoText,
  AuthLinksContainer,
  MetaText,
  MetaContainer,
  StatsText,
} from "./home.styles";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import AppLink from "components/shared/AppLink";
import { useAppSelector } from "store";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RightSection = () => {
  //hooks
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);
  const isCertified = useAppSelector((state) => state.auth.isCertified);
  const inCertifying = useAppSelector((state) => state.auth.inCertifying);

  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate("/dashboard");
  //   }
  // }, [isAuthenticated]);

  return (
    <RSectionContainer>
      <Label>{t("main-home.first_step")}</Label>
      <MainTitle>{t("main-home.main_title")}</MainTitle>
      <SubTitle>{t("main-home.sub_title")}</SubTitle>
      <InfoList>
        <InfoListItem>
          <InfoIcon>
            <IconContext.Provider value={{ color: "#5f68bf", size: ".7em" }}>
              <FaCheck />
            </IconContext.Provider>
          </InfoIcon>
          <InfoText>{t("main-home.info_one")}</InfoText>
        </InfoListItem>
        <InfoListItem>
          <InfoIcon>
            <IconContext.Provider value={{ color: "#5f68bf", size: ".7em" }}>
              <FaCheck />
            </IconContext.Provider>
          </InfoIcon>
          <InfoText>{t("main-home.info_two")}</InfoText>
        </InfoListItem>
      </InfoList>
      <AuthLinksContainer>
        {!isAuthenticated && (
          <>
            <AppLink to='/register' text={t("main-home.register")} />

            <AppLink to='/login' text={t("main-home.login")} type='outline' />
          </>
        )}
        {isAuthenticated && !isCertified && !inCertifying && (
          <AppLink to='/account-setup' text={t("setup_continue")} />
        )}
        {isAuthenticated && inCertifying && !isCertified && (
          <StatsText>{t("under_review")}</StatsText>
        )}
        {isAuthenticated && isCertified && (
          <AppLink to='/dashboard' text={t("enterDashboard")} />
        )}
      </AuthLinksContainer>

      <MetaContainer className={getLanguage() === "ar" ? "rtl" : "ltr"}>
        <MetaText>{t("main-home.meta_info_one")}</MetaText>
        <MetaText>{t("main-home.meta_info_two")}</MetaText>
      </MetaContainer>
    </RSectionContainer>
  );
};

export default RightSection;

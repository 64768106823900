import styled from "styled-components/macro";

export const SettingsWrapper = styled.div``;
export const FormContainer = styled.form`
  width: 100%;
  margin-top: 30px;

  .app-select {
    width: 100%;
    margin-top: 24px;
  }
`;

export const FormControl = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  margin-top: 20px;
  .app-select {
    width: 100%;
  }
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin-bottom: 20px;
`;
export const Label = styled.span`
  margin-bottom: 13px;
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  margin-top: 24px;
`;

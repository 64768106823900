import {
  HelpContainer,
  HelpIcon,
  HelpSubTitle,
  HelpTitle,
  HelpTitleContainer,
  StepContainer,
  StepNumber,
  StepNumberText,
  StepperContainer,
  StepperTitle,
  StepsContainer,
  StepTextContainer,
  StepTitle,
} from "./stepper.styles";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store";
const stepsData = [
  { text: "first", number: 0 },
  { text: "second", number: 1 },
  { text: "third", number: 2 },
  { text: "fourth", number: 3 },
  { text: "fifth", number: 4 },
];

interface stepperProps {
  formStep: Number;
}
const Stepper = ({ formStep }: stepperProps) => {
  const { t } = useTranslation();
  const isLtr = useAppSelector((state) => state.locale.isLtr);
  return (
    <StepperContainer>
      <StepperTitle>{t("account_setup.account_setup")}</StepperTitle>
      <StepsContainer>
        {stepsData.map((step) => (
          <StepContainer
            key={step.number}
            active={step.number === formStep}
            visited={step.number < formStep}
            isLtr={isLtr}
          >
            <StepNumber
              active={step.number === formStep}
              visited={step.number < formStep}
            >
              {step.number}
            </StepNumber>
            <StepTextContainer>
              <StepNumberText>
                {t(`account_setup.${step.text}_step`)}
              </StepNumberText>
              <StepTitle>
                {t(`account_setup.${step.text}_step_title`)}
              </StepTitle>
            </StepTextContainer>
          </StepContainer>
        ))}
      </StepsContainer>
      {/* <HelpContainer>
        <HelpIcon>?</HelpIcon>
        <HelpTitleContainer>
          <HelpTitle>{t("account_setup.have_issue")}</HelpTitle>
          <HelpSubTitle to='/'>{t("account_setup.contact_us")}</HelpSubTitle>
        </HelpTitleContainer>
      </HelpContainer> */}
    </StepperContainer>
  );
};

export default Stepper;

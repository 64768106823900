import { useTranslation } from "react-i18next";
import {
  ContentContainer,
  PageWrapper,
  Title,
  SectionTitle,
} from "./termsContent.styles";

const AboutUsContent = () => {
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  return (
    <PageWrapper>
      <Title>{t("menu.about_us")}</Title>
      {getLanguage() === "ar" ? (
        <ContentContainer>
          <SectionTitle>مرحباً بكم في علمني</SectionTitle>
          <p>
            تعد مؤسسة علمني الرائدة لتقنية المعلومات المالكة لتطبيق علمني مؤسسة
            سعودية مقرها المنطقة الغربية ومرخصة من وزارة التجارة برقم السجل
            ٤٦٥٠٢٣٨٥٥٣.
          </p>
          <br />

          <p>
            علمني هوا تطبيق وساطة يجمع بين من لديهم علم، مهنة، حرفة، او هواية مع
            من يرغب بالتعلم. المنصة تعتبر صديقة للمدرب حيث انها توفر خيارات
            عديدة له. حيث انك كمدرب/ة تستطيع اختيار الوقت الأنسب لك و العدد
            اللذي تريده من المتدربين و المدة المناسبة للبرنامج اللذي تريد
            تقديمه.
          </p>
          <br />

          <p>
            بالإضافة لذلك فإن للمدرب حرية وضع السعر المناسب لبرنامجه التدريبي.
            ويتم إستلام مبالغ التسجيل من المتدربين باستخدام عدة وسائل دفع
            الكترونية ومن ثم تسليم المدرب المبلغ بعد اتمام البرنامج ويقتطع من
            المبلغ الإجمالي نسبة معينة كرسوم خدمة للمنصة. توفر المنصة عقود
            الكترونية موثقة بين المدرب و المنصة لضمان حق كلا الطرفين.
          </p>
          <br />

          <p>
            تتيح المنصة اجراء الدورات عن بعد (اونلاين) عن طريق برنامج Google
            Meet او حضوري او كلاهما، على سبيل المثال يمكن اجراء جزء من البرنامج
            نظرياً باستخدام المنصة و الجزء الآخر تطبيقي حضورياً في مكان متفق
            عليه من قبل المدرب و المتدرب.
          </p>
          <br />

          <p>
            المنصة تنقسم لمجالين، الأول هوا : المجال الاكاديمي (ويتطلب مطابقة
            شهادة التخصص) ، و الثاني هوا المجال التطويري من تطوير مهني او حرفي
            او هوايات (ولا يتطلب شهادة تخصص او شهادة اعتماد) ولكن وجودهما يعتبر
            من مصلحة المدرب حيث انها ستميز حسابه.
          </p>
          <br />

          <p>
            تهدف علمني لتوفير منصة سهلة الإستخدام لكل من يرغب بالتعلم في كثير من
            المجالات المتنوعة. وتستهدف علمني صناع المحتوى و الاكاديميين
            المحترفين و أصحاب المواهب الراغبين بتقديم خدماتهم للمجتمع وزيادة
            مصدر دخلهم. علمني هوا تطبيق سعودي يهدف لخدمة المجتمع السعودي و
            المقيمين به، و نرحب بالجميع للإنضمام من أجل هذا الهدف.
          </p>
        </ContentContainer>
      ) : (
        <ContentContainer>
          <SectionTitle>Welcome to Alemni</SectionTitle>
          <p>
            Alemni the Pioneering in Informational Technology Enterprise and the
            owner of Alemni platform is a Saudi Arabian foundation. Located in
            the western region of Saudi Arabia and is registered in the Ministry
            of Commerce, under registration number 4650238553.
          </p>
          <br />

          <p>
            We aim to provide a user-friendly platform that can connect those
            who are interested in learning in various academic and professional
            fields with trainers, teachers, and those who have exceptional
            talents. We are looking forward to hosting people with remarkable
            skills who wish to share their knowledge with Saudi society and
            would like to make an extra source of income in the process
          </p>
          <br />

          <p>
            1. You can choose the number of applicants to join your course.
            <br />
            2. you can pick a suitable time for your course whether it is a
            single class or several. In addition to various options to customize
            your course.
            <br />
            3. As a provider, you have the freedom to decide how much your time
            is worth. It is up to the providers to list the price for their
            courses or you have the option to list it for free.
            <br />
            4. All money transactions are secured through a third-party pay
            gate. Payments will be collected from the applicants and then
            transferred to the provider's bank account after the end of the
            course. Alemni deducts its pre-agreed cut from the total amount.
            <br />
            5. Alemni provides electronic contracts with the providers. The
            purpose of the contracts is to preserve the rights of all the
            parties included.
          </p>
          <br />

          <p>
            Alemni's platform is divided into two categories. The first one is
            Academia which includes private tutoring and standardized exams
            preparation. The second category is professional and
            self-development.
          </p>
          <br />

          <p>
            Alemni is a Saudi platform that aims to serve the Saudi Arabian
            community, and we welcome everyone, Saudis and non-Saudis to help us
            to achieve this goal.
          </p>
          <br />
        </ContentContainer>
      )}
    </PageWrapper>
  );
};

export default AboutUsContent;

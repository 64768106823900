import { LabelContainer } from "./applabel.styles";
import { useTranslation } from "react-i18next";

interface labelProps {
  text: string;
  variation?: "upcoming" | "active" | "canceled" | "completed";
  size?: "sm" | "md" | "lg";
  [x: string]: any;
}

const AppLabel = ({
  text,
  variation = "active",
  size = "sm",
  ...rest
}: labelProps) => {
  const { t } = useTranslation();

  return (
    <LabelContainer variation={variation} size={size} {...rest}>
      {t(text)}
    </LabelContainer>
  );
};

export default AppLabel;

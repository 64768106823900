import AppButton from "components/shared/AppButton";
import AppChips from "components/shared/AppChips";
import AppInput from "components/shared/AppInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  AddCourseTitle,
  AddCourseWrapper,
  CourseLocationContainer,
  DeleteLocation,
  EditLocation,
  InputGroup,
  InputsWrapper,
  Label,
  LocationCity,
  LocationImage,
  LocationInfo,
  LocationSuggestions,
  LocationTitle,
} from "./freeLessons.styles";
import { useNavigate } from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";
import LocationSuggestionsModal from "components/Modals/LocationSuggestionsModal";
import { useAppSelector } from "store";
import { trainerAPI } from "services/apis/trainer";
import toast from "react-hot-toast";

const chipsOptions = [
  { name: "online", value: "online" },
  { name: "on_site", value: "on_site" },
];
const AddFreeLesson = () => {
  //hooks
  const navigate = useNavigate();

  const user = useAppSelector((state) => state.auth.user);
  const { t } = useTranslation();
  const [selectedLocation, setSelectedLocation] = useState("online");
  const [openSuggestions, setOpenSuggestions] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<any>({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //functions
  const handleLocationChoice = (value: string) => {
    setSelectedLocation(value);
  };
  const onSubmit = async (data) => {
    try {
      setSubmitLoading(true);
      let payload = {
        title: data.lesson_subject,
        starts_at: data.lesson_date,
        max_attendees_number: +data.max_attendees_number,
        type: "free_lesson",
        attendance: selectedLocation,
        trainer_id: user.id,
        place_id: selectedPlace?.id,
        num_hours: data.lesson_hours,
      };
      const response = await trainerAPI.createEvent(payload);
      if (response.data.metaData.key === "success") {
        navigate("/dashboard/free-lessons");

        toast.success(t("added_successfully"));
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <AddCourseWrapper onSubmit={handleSubmit(onSubmit)}>
      <AddCourseTitle> {t("add_lesson")}</AddCourseTitle>
      <InputsWrapper>
        <InputGroup>
          <AppInput register={register} name='lesson_subject' errors={errors} />
        </InputGroup>
        <Label>{t("lesson_location")}</Label>
        <InputGroup>
          <AppChips
            options={chipsOptions}
            handleChoice={(value) => handleLocationChoice(value)}
            selectedChoice={selectedLocation}
          />
        </InputGroup>
        {selectedLocation === "on_site" && (
          <>
            {Object.keys(selectedPlace).length > 0 ? (
              <CourseLocationContainer>
                <LocationInfo>
                  <LocationImage alt='' />
                  <div>
                    <LocationTitle>{selectedPlace?.name}</LocationTitle>
                    <LocationCity>{selectedPlace?.city_name}</LocationCity>
                    <DeleteLocation
                      onClick={() => {
                        setSelectedPlace({});
                      }}
                    >
                      {t("delete_location")}
                    </DeleteLocation>
                  </div>
                </LocationInfo>
                <EditLocation
                  type='button'
                  onClick={() => setOpenSuggestions(true)}
                >
                  {t("edit_location")}
                </EditLocation>
              </CourseLocationContainer>
            ) : (
              <LocationSuggestions>
                <h1>{t("location_suggestions_title")}</h1>
                <IoIosArrowBack
                  onClick={() => {
                    setOpenSuggestions(true);
                  }}
                />
              </LocationSuggestions>
            )}
          </>
        )}
        <InputGroup>
          <AppInput
            register={register}
            name='lesson_date'
            errors={errors}
            type='datetime-local'
          />
        </InputGroup>

        <InputGroup>
          <AppInput
            register={register}
            name='max_attendees_number'
            errors={errors}
            type='number'
            min={2}
          />
          <AppInput
            register={register}
            name='lesson_hours'
            errors={errors}
            type='number'
          />
        </InputGroup>
      </InputsWrapper>

      <AppButton size='lg' type='submit' loader={submitLoading}>
        {t("submit_lesson")}
      </AppButton>
      <LocationSuggestionsModal
        open={openSuggestions}
        setOpen={setOpenSuggestions}
        setSelectedPlace={setSelectedPlace}
      />
    </AddCourseWrapper>
  );
};

export default AddFreeLesson;

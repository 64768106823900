import { useTranslation } from "react-i18next";
import { PageWrapper, Title, ContentContainer } from "./termsContent.styles";

const PolicyContent = () => {
  const { t, i18n } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  return (
    <PageWrapper>
      <Title>{t("policy")}</Title>
      {getLanguage() === "ar" ? (
        <ContentContainer>
          <p>
            تشرح سياسة الخصوصية هذه ("السياسة") طريقة قيام المؤسسة ("جهة التحكم"
            أو "نحن" أو "المؤسسة") بمعالجة وجمع لبيانات المستخدم ("المستخدم/ين"
            أو "الزائر" أو "أنت") الشخصية فيما وكيفية التعامل معها وذلك خلال
            استخدامك لتطبيق "علمني" ("التطبيق")، وكذا الخدمات والإجراءات
            والتدابير المتصلة بسياسة الخصوصية التي تقدمها المؤسسة.
          </p>
          <br />

          <p>
            1. توضح السياسة ما هي البيانات التي نجمعها من مستخدم التطبيق، بما في
            ذلك المعلومات التي يمكن استخدامها لتحديد هويتك الشخصية وكيف
            نستخدمها.
            <br />
            2. نحن نحثك على قراءة سياستنا أدناه حيث إن هذه السياسة تنطبق على أي
            مستخدم أو زائر للتطبيق، ونحتفظ بالحق في تغيير هذه السياسة في أي وقت.
            <br />
            3. سنخطرك بأي تغييرات وتحديثات تطرأ على هذه السياسة بأي شكل من
            الاشكال عن طريق إرسال إشعار إلى عنوان البريد الإلكتروني المحدد في
            حسابك أو عن طريق إشعار بارز على تطبيقنا، وستُدخل التغييرات حيز
            التنفيذ فوراً من تاريخ إرسالها لك أو نشرها على التطبيق.
            <br />
            4. أنت تقر بأن إستمرار استخدامك للتطبيق او خدماته بعد قيامنا بنشر أو
            إرسال إشعارًا بشأن التغييرات التي أجريناها على هذه السياسة، يعني أن
            جمع واستخدام ومشاركة بياناتك الشخصية يخضع للسياسة المحدثة، لذا
            استمرارك في استعمال التطبيق بعد تحديث السياسة هو إقرار منك بعلمك
            بهذه التحديثات وموافقتك عليها.
            <br />
            5. يجب عليك مراجعة موقعنا بشكل دوري، بما في ذلك صفحة سياسة الخصوصية
            هذه، للحصول على التحديثات.
            <br />
            6. يمكنك قراءة سياسة الخصوصية الخاصة بنا في هذه الصفحة، لاستخدام
            تطبيق علمني، بناء عليه انت تقر بموافقتك على هذه السياسة وعلمك
            بمضمونها وأحكامها.
            <br />
          </p>
          <br />
          <br />
          <h4>
            <strong>التعاريف:</strong>
          </h4>
          <p>
            البيانات الشخصية: كل بيان -مهما كان مصدره أو شكله- من شأنه أن يؤدي
            إلى معرفة الفرد على وجه التحديد، أو يجعل التعرف عليه ممكنًا بصفة
            مباشرة أو غير مباشرة، ومن ذلك: الاسم، ورقم الهوية الشخصية،
            والعناوين، وأرقام التواصل، وأرقام الرُّخص والسجلات والممتلكات
            الشخصية، وأرقام الحسابات البنكية والبطاقات الائتمانية، وصور الفرد
            الثابتة أو المتحركة، وغير ذلك من البيانات ذات الطابع الشخصي.
            <br />
            البيانات الحساسة: كل بيان شخصي يتضمن الإشارة إلى أصل الفرد العرقي،
            أو أصله القبلي، أو معتقده الديني، أو الفكري، أو السياسي، أو يدل على
            عضويته في جمعيات أو مؤسسات أهلية. وكذلك البيانات الجنائية والأمنية،
            أو بيانات السمات الحيوية التي تحدد الهوية، أو البيانات الوراثية، أو
            البيانات الائتمانية، أو البيانات الصحية، وبيانات تحديد الموقع،
            والبيانات التي تدل على أن الفرد مجهول الأبوين أو أحدهما.
            <br />
            صاحب البيانات الشخصية: المستخدم أو زائر التطبيق الذي تتعلق به
            البيانات الشخصية أو من يمثله أو من له الولاية الشرعية عليه
            <br />
            المُعالجة: أي عملية تُجرى على البيانات الشخصية بأي وسيلة كانت يدوية
            أو آلية، ومن ذلك: عمليات الجمع، والتسجيل، والحفظ، والفهرسة،
            والترتيب، والتنسيق، والتخزين، والتعديل، والتحديث، والدمج،
            والاسترجاع، والاستعمال، والإفصاح، والنقل، والنشر، والمشاركة في
            البيانات أو الربط البيني، والحجب، والمسح، والإتلاف.
            <br />
            جهة المعالجة: أي جهة عامة، وأي شخصية ذات صفة طبيعية أو اعتبارية
            خاصة؛ تعالج البيانات الشخصية لمصلحة جهة التحكم ونيابةً عنها.
            <br />
            الموافقة الضمنية: هـي موافقـة لا يتـم منحهـا صراحـة ِ مـن قبـل
            صاحــب البيانــات، ُولكنهــا تمنــح ضمنيــاً عــن طريــق أفعــال
            الشــخص ووقائــع وظــروف الموقـف، كتوقيـع العقـود أو الموافقـة علـى
            الشــروط والأحــكام.
          </p>
          <br />
          <br />
          <h4>
            <strong>ما المعلومات التي تعالج عند استخدام للتطبيق؟</strong>
          </h4>
          <br />
          <p>
            عند تصفح المستخدم لتطبيق علمني، يقوم خادمنا بجمع معلومات المستخدم
            الفنية، بما في ذلك عنوان بروتوكول الإنترنت (IP) الخاص به، وتاريخ
            ووقت التصفح، ونوع المتصفح، ونظام تشغيل الإنترنت والنظام الأساسي،
            ونحوه.
          </p>
          <br />
          <p>
            عنوان بروتوكول الإنترنت (IP) هو رقم مخصص لجهاز المستخدم، مما يسمح
            للأجهزة الأخرى المتصلة بالشبكة بتحديد واجهة بيانات المضيف، وجمع
            المعلومات الفنية دون تحديد هوية المستخدم أو تحديد معلوماته الشخصية.
            <br />
            قد نقوم بجمع معلومات التعريف غير الشخصية عن المستخدمين كلما تفاعلوا
            مع تطبيقنا، وقد تتضمن معلومات التعريف غير الشخصية اسم المتصفح ونوع
            الكمبيوتر والمعلومات التقنية حول وسيلة اتصال المستخدمين تطبيقنا، مثل
            نظام التشغيل وموفري خدمة الإنترنت ومعلومات أخرى مماثلة.
            <br />
          </p>
          <br />
          <br />
          <h4>
            <strong>بيانات التسجيل في التطبيق؟</strong>
          </h4>

          <br />
          <p>
            عند استعمال المستخدم لهذا التطبيق، قد نعالج معلومات بياناتك الشخصية
            التي قدمتها إلينا في قواعد البيانات الخاصة بنا.
          </p>
          <br />
          <br />
          <h4>
            <strong>ما هي ملفات تعريف الارتباط؟</strong>
          </h4>
          <br />
          <p>
            "ملفات تعريف الارتباط" هي ملفات نصية تحتوي على أجزاء صغيرة من
            البيانات - مثل اسم المستخدم وكلمة المرور - التي تُستخدم لتعريف جهاز
            الحاسوب الخاص بك أثناء استخدامك لشبكة الكمبيوتر . تُستخدم ملفات
            تعريف الارتباط المحددة المعروفة باسم ملفات تعريف ارتباط HTTP لتحديد
            مستخدمين محددين وتحسين تجربة تصفحك للويب.
            <br />
            يتم إنشاء البيانات المخزنة في ملف تعريف الارتباط بواسطة الخادم عند
            اتصالك. يتم تصنيف هذه البيانات بمعرف خاص بك ولجهاز الكمبيوتر الخاص
            بك.
            <br />
            عندما يتم تبادل ملف تعريف الارتباط بين جهاز الكمبيوتر الخاص بك وخادم
            الشبكة، يقرأ الخادم المعرف ويعرف المعلومات التي يجب أن يقدمها لك على
            وجه التحديد.
          </p>
          <h4>
            <strong>كيفية استخدامنا لمعلوماتك وبياناتك؟</strong>
          </h4>
          <br />
          <p>
            بغرض توفير الخدمات نقوم بمشاركة بياناتك الشخصية مع الجهة الأخرى بعد
            الحصول على موافقتك، على سبيل المثال لا الحصر:(الاتصال بشركائنا
            وموظفينا الحاليين والمحتملين، والقيام بالطلبات، وخدمة العملاء،
            وخدمات وسائل التواصل الاجتماعي، وأغراض التوظيف، والأغراض الإحصائية،
            وجدولة المواعيد، وإجراءات السداد للخدمات، والتواصل فيما يتعلق بأي
            امر يختص بحساب المستخدم في التطبيق او خدمات التطبيق).
            <br />
            قد يستخدم تطبيقنا ملفات تعريف الارتباط لتحسين تجربة المستخدم، ويضع
            متصفح الويب الخاص بالمستخدم ملفات تعريف الارتباط على محرك الأقراص
            الثابتة لأغراض حفظ السجلات، وفي بعض الأحيان يتتبع المعلومات عنها.
            <br />
            قد يختار المستخدم تعيين متصفح الويب الخاص به لرفض ملفات تعريف
            الارتباط، أو لتنبيهك عند إرسال ملفات تعريف الارتباط، إذا قاموا بذلك،
            فبعض أجزاء التطبيق قد لا تعمل بشكل صحيح.
          </p>
          <br />

          <h4>
            <strong>الإفصاح عن المعلومات؟</strong>
          </h4>
          <br />
          <p>
            حماية معلومات المستخدم أولوية بالنسبة لنا لذلك سيتمكن موظفونا فقط من
            الوصول إلى المعلومات الشخصية للمستخدم لخدمته على نحو يؤدي الغرض
            وبالقدر اللازم للخدمة.
            <br />
            من حين لأخر قد نشارك معلومات المستخدم الشخصية التي قدمها مع مزودي
            الخدمة الذين يؤدون مهام معينة نيابة عن التطبيق.
            <br />
            في الحالات القصوى إذا كنا نعتقد لسبب معقول أن الكشف عن هذه المعلومات
            مفيد أو ضروري قد نضطر وبالشكل المعقول إلى مشاركة معلومات المستخدم
            الشخصية عندما وجود أمر من جهة حكومية أو سلطة القضائية بأمر مباشر
            منها، على أن نراجع بانتظام مدد الاحتفاظ بالبيانات للتأكد من أننا لا
            نحتفظ بهذه البيانات مدة أطول من اللازم.
            <br />
            <br />
          </p>
          <br />

          <h4>
            <strong>الأمن والحماية؟</strong>
          </h4>
          <br />
          <p>
            ● نحن نستخدم في هذا التطبيق أفضل برامج الأمان لحماية المعلومات من
            الضياع أو الكشف أو الافصاح غير المصرح به، ونوصي المستخدم بتثبيت
            برنامج مكافحة الفيروسات على جهازه الخاص.
            <br />
            ● تعني إجراءات الأمان أيضًا أننا قد نطلب إثبات الهوية قبل الكشف عن
            المعلومات الشخصية لك أو قبل معالجة طلباتك الأخرى.
            <br />
            ● نوصي باستخدام متصفح آمن عند إجراء أي تفاعل عبر الإنترنت، وعدم
            إعطاء كلمة المرور لأي شخص، إذ أننا نبذل العناية الكافية لفحص واختبار
            محتويات تطبيقنا بانتظام، وننصح بتشغيل برنامج مضاد للفيروسات على كل
            المواد التي يجري تنزيلها من الإنترنت، ولا تقع علينا أي مسؤولية عن أي
            خسارة أو انقطاع أو تلف لبياناتك أو الجهاز الإلكتروني او وسيلة
            الاستخدام لديك مما يحدث أثناء الاتصال بهذا التطبيق، أو عند استخدام
            أي مادة واردة منها
            <br />
            ● يحتوي التطبيق على عناوين ( URL ) لمواقع أخرى تستخدم سياساتها
            الخاصة، وننوه بأننا غير مسؤولين عن محتويات وطرق فق البيانات التي
            تستخدمها هذه المواقع وسياستنا حصرية لهذا التطبيق.
            <br />
            ● لا تهاون في أمان المعلومات الشخصية لدينا، ويؤخذ في الاعتبار طبيعة
            المعلومات الشخصية ونطاقها وسياقها وأغراض معالجتها، ونظرا إلى المخاطر
            التي يتعرض لها الأفراد ذات الاحتمالات والشدة المتفاوتة، فقد طبقنا
            تدابير فنية وأمنية وإجرائية وتنظيمية مصممة لحماية أمان المعلومات
            الشخصية.
            <br />
            ● يرجى ملاحظة أنه لا توجد طريقة نقل عبر الإنترنت أو وسيلة للتخزين
            الإلكتروني آمنة بنسبة 100%. لذلك نسعى دائماً باستخدام تدابير جيدة
            تجاريًا مصممة لحماية المعلومات الشخصية، فلا يمكن ضمان أمنها المطلق.
            <br />
            نحن نبذل العناية المتعارف عليها بتنفيذ إجراءات دقيقة للحماية من فقد
            المعلومات أو إساءة استخدامها أو تغييرها، غير أن التطبيق ليس مسؤولا
            عن أي ضرر قد يتعرض له المستخدم أو أي طرف نتيجة لانتهاك السرية بشأن
            المعلومات التي يكون قد قدمها المستخدم إلى التطبيق.
          </p>
          <br />
          <h4>
            <strong>المدة التي نحتفظ بها ببياناتك الشخصية؟</strong>
          </h4>
          <br />
          <p>
            نحن نقوم بإتلاف البيانات الشخصية فور انتهاء الغرض من جمعها، ويجوز
            لنا الاحتفاظ بتلك البيانات بعد انتهاء الغرض من جمعها إذا تمت إزالة
            كل ما يؤدي إلى معرفة صاحبها على وجه التحديد
            <br />
            نقوم بالاحتفاظ بالبيانات الشخصية بعد انتهاء الغرض من جمعها في
            الحالتين الآتيتين:
            <br />
            1- إذا توافر مسوغ نظامي يوجب الاحتفاظ بها مدة مُحددة، وفي هذه الحالة
            يُجرى إتلافها بعد انتهاء هذه المدة أو انتهاء الغرض من جمعها، أيهما
            أطول.
            <br />
            2- إذا كانت البيانات الشخصية متصلة اتصالاً وثيقاً بقضية منظورة أمام
            جهة قضائية وكان الاحتفاظ بها مطلوباً لهذا الغرض، وفي هذه الحالة
            يُجرى إتلافها بعد استكمال الإجراءات القضائية الخاصة بالقضية.
            <br />
            عند انتهاء علاقتنا معك، سنحتفظ ببياناتك الشخصية لمدة سنتين (2) سنة/
            أو لفترة أطول بالقدر الذي تقتضيه الحاجة أو الأنظمة أو قرار قضائي، ثم
            نتلفها بطريقة آمنة، ونضمن للمستخدم عدم الاحتفاظ بالمعلومات مدة أطول
            من اللازم، وإذا كنت ترغب في مزيد من الاستفسارات او حذف واتلاف
            بياناتك، يمكنك التواصل معنا عبر البريد الإلكتروني: هاتف:
            <br />
          </p>
          <br />
        </ContentContainer>
      ) : (
        <ContentContainer>
          <p>
            This Privacy Policy ("The Policy”) outlines how the institution (the
            terms “Data controller” or “we” or “our” or means The institution or
            the App) handle and process the user personal data (the terms
            “user\s” or "you" or " or "your\s" or means the user of the App) in
            regarding of how we process your data during your usage of “Alemni”
            ("The App"), as well as the services, procedures, and measures in
            accordance with the App privacy policy.
          </p>
          <br />

          <p>
            1. The Policy clarifies the data we collect from the App user,
            including information that can be used to personally identify you
            and how we use it.
            <br />
            2. We recommend that you read our policy below as this policy
            Applies to any user of the App. This policy may subject to
            modification in anytime.
            <br />
            3. We will notify you about any modifications and updates regarding
            this Policy via your email address in your account, or with
            prominent notice on our App. Modifications will become effective
            upon being sent to you or published on The App.
            <br />
            4. In terms of your continuous use of the App or its services after
            we have posted or informed you of the changes to this policy means
            that the collection, use, and sharing of your personal data is
            subject to the updated Policy, so your continued use of the App is
            consent and acknowledgment by you of those changes.
            <br />
            5. From time-to-time you shall check our website, including this
            Privacy Policy page, for any updates.
            <br />
            6. On this page you can read our privacy policy to use Alemni. Thus,
            you Approve and acknowledge this policy with its contents and
            provisions.
            <br />
          </p>
          <br />
          <br />
          <h4>
            <strong>Definitions:</strong>
          </h4>
          <p>
            Personal Data: Is any element of data, regardless of source or form
            whatsoever, which independently or when combined with other
            available information could lead to the identification of a person
            including but not limited to: First Name and Last Name, Saudi
            National Identity ID Number, addresses, Phone Number, bank account
            number, credit card number, health data, images or videos of the
            person.
            <br />
            Sensitive Data: Data, the loss, misuse, or unauthorized access to or
            modification of, that could adversely affect the national interest
            or the conduct of government programs, or the privacy to which
            individuals are entitled.
            <br />
            Data Subject: Any natural person to whom the personal data relates
            to, or his representative, or the person who has legal custody over
            him/her.
            <br />
            Personal Data Processing: Processing of personal data by any means,
            whether manual or automated processing, including collection,
            transfer, recording, storage, data-sharing, destruction, analysis,
            extraction of their patterns, archiving, indexing, arranging,
            coordinating, modifying, updating, merging, retrieval, use,
            disclosure, transmission, publication, blocking, erasure and,
            conclusion and interconnection.
            <br />
            Data Processor: Any independent governmental or public entity, or
            any natural or legal person, which engages in the Processing of
            Personal Data, on behalf of a Data Controller pursuant to a legal
            basis
            <br />
            Implied Consent: Consent of the Data Subject that is understood from
            their actions, certain events, or circumstances e.g. consent to the
            terms and conditions.
          </p>
          <br />
          <br />
          <h4>
            <strong>
              What information is processed when using the Application?
            </strong>
          </h4>
          <br />
          <p>
            When the user is browsing (Alemni), our server collects technical
            information about the user, such as the user's Internet Protocol
            (IP) address, date and time of browsing, type of web browser,
            internet operating system and platform, etc.
          </p>
          <br />
          <p>
            Internet Protocol (IP) address is a number assigned to a user's
            device, which allows other devices connected to the network to
            identify the host data interface and collect the technical
            information without identifying the user’s identity or the user’s
            personal information.
            <br />
            We may collect Non-Personally Identifiable Information about Users
            whenever they interact with our App. The Non-personal identifiable
            information may include the user name, computer type, technical
            information about Users' connection to our App, such as system
            operator, Internet service providers, and other similar information.
            <br />
          </p>
          <br />
          <br />
          <h4>
            <strong>Registration data in the App?</strong>
          </h4>

          <br />
          <p>
            When you use this App, we may process the personal data you provided
            in our databases.
          </p>
          <br />
          <br />
          <h4>
            <strong>What Are Cookies?</strong>
          </h4>
          <br />
          <p>
            “Cookies” are text files with small pieces of data — like a username
            and password — that are used to identify your computer as you use a
            computer network. Specific cookies known as HTTP cookies are used to
            identify specific users and improve your web browsing experience.
            <br />
            Data stored in a cookie is created by the server upon your
            connection. This data is labeled with an ID unique to you and your
            computer.
            <br />
            When the cookie is exchanged between your computer and the network
            server, the server reads the ID and knows what information to
            specifically serve to you.
          </p>
          <h4>
            <strong>How do we use your information and data?</strong>
          </h4>
          <br />
          <p>
            For the purpose of providing the services, we share your personal
            data with third parties after obtaining your consent, in order to
            provide you with the services, including but not limited to:
            (connecting with our partners and current and potential employees,
            placing orders, customer service, social media services, employment,
            statistics, scheduling appointments, and payment procedures for
            services, and to connect with any matter relating to the user's
            account in the App or the App services).
            <br />
            Our App may use cookies to improve the user experience, and the
            user’s web browser places cookies on his hard drive for
            record-keeping purposes, and sometimes tracks its information.
            <br />
            The user may choose to set his web browser to refuse the cookies or
            to alert him when cookies are being sent. If the user does so, some
            parts of the App may not function properly.
          </p>
          <br />

          <h4>
            <strong>Information disclosure?</strong>
          </h4>
          <br />
          <p>
            We prioritize the security of the user's information; therefore, our
            employees are allowed to access the user's personal information to
            carry out the purpose and to perform the services to the extent
            necessary.
            <br />
            We may, from time to time share the obtained user's personal
            information with the service providers assigned to perform certain
            services on behalf of the App.
            <br />
            In necessary circumstances when it appears to us that disclosing the
            information is beneficial or necessary upon direct order from a
            governmental or judicial authority, we may share the user's personal
            information with a reasonably possible and sufficient extent,
            provided that we review the periods of data preservation on a
            regular basis to ensure that we do not keep this data a longer
            period than required.
            <br />
            <br />
          </p>
          <br />

          <h4>
            <strong>Security and protection?</strong>
          </h4>
          <br />
          <p>
            ● We use in this App the best security software to protect
            information from loss, disclosure, or unauthorized disclosure, and
            we recommend the user to install anti-virus software on his own
            device.
            <br />
            ● Security procedures also means that we may ask for verifying your
            identity before disclosing personal information to you or before
            processing your other requests as part of our security procedures.
            <br />
            ● Since we constantly check and test our App, we recommend using a
            secure browser for any online interaction and not sharing your
            password with anyone. We also recommend installing an anti-virus
            program on all the material downloaded from the internet, and we are
            not responsible for any loss, interruption, or damage to your data,
            electronic device, or means of use that occurs while using this App
            or any of the materials contained therein.
            <br />
            ● The App contains (URL) addresses of other sites that use their own
            policies. We are not responsible for the contents or methods of data
            collection used by these sites, and our policy is exclusive to this
            App.
            <br />
            ● We have implemented technical, security, procedural, and
            organizational measures designed to protect the security of our
            personal information, considering the nature, scope, context, and
            purposes of processing personal information, as well as the risks to
            individuals of varying probability and consequence.
            <br />
            ● Bear in mind that there is no 100% secure transfer method over the
            Internet or electronic storage. We always seek to use a well-known
            commercially measure designed to protect your personal information
            despite this, its total security cannot be guaranteed.
            <br />
            We conduct the best effort in maintaining the proper security
            procedures to protect the information from loss, misuse, or
            modification, however, the App is not responsible for any damages
            that the user or any third party may suffer because of a loss of
            privacy related to the information that the user might have provided
            to the App.
          </p>
          <br />
          <h4>
            <strong>Duration of keeping your personal data.</strong>
          </h4>
          <br />
          <p>
            We destruct the personal data immediately after the purpose of
            collecting it. We may also keep the data if anything leads to
            specifically identify its owner is deleted.
            <br />
            We keep the personal data after the purpose of its collection has
            ended in the following situation:
            <br />
            a- If there is a legal excuse that requires to retain it for a
            certain period, in which case it shall be destroyed after the expiry
            of this period or the purpose of its collection, whichever is
            longer.
            <br />
            b- If the personal data is related to a case under consideration of
            judicial authority and its retention is required, in this case, we
            will retain your personal data until the case in question have been
            settled.
            <br />
            Upon termination of our relationship with you, we will keep your
            personal data for a period of two (2) years / or longer as required
            by the need, regulations or court decision, and then we destroy it
            in a secure manner, we guarantee the user not to keep the data for
            longer than necessary, If you have questions or concerns regarding
            this Privacy Policy, you should first contact us via e-mail, or to
            delete and destruct your data.
            <br />
          </p>
          <br />
        </ContentContainer>
      )}
    </PageWrapper>
  );
};

export default PolicyContent;

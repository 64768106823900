import AppButton from "components/shared/AppButton";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ActionButtonsContainer,
  BookingType,
  BookingTypesContainer,
  EducationContainer,
  FormContainer,
  Label,
  SubTitle,
  Title,
  DaysContainer,
  Day,
} from "./availabletimes.styles";
import toast from "react-hot-toast";
import EditDayModal from "components/Modals/EditDayModal";
import { BookingTypes, IAccountFormState, IWorkingDay } from "types";
import { accountSetupActions, ActionKind } from "../Steps";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { Hint } from "../TeachingSpecifications/specifications.styles";

const daysOptions: { label: string; value: string }[] = [
  { label: "السبت", value: "sat" },
  { label: "الأحد", value: "sun" },
  { label: "الاثنين", value: "mon" },
  { label: "الثلاثاء", value: "tue" },
  { label: "الأربعاء", value: "wed" },
  { label: "الخميس", value: "thu" },
  { label: "الجمعة", value: "fri" },
];

interface availableTimesProps {
  prevFormStep: () => void;
  nextFormStep: () => void;
  formStep: Number;
  formData: IAccountFormState;
  dispatch: React.Dispatch<accountSetupActions>;
}

const AvailableTimes = ({
  prevFormStep,
  nextFormStep,
  formStep,
  formData,
  dispatch,
}: availableTimesProps) => {
  //hooks
  const { t } = useTranslation();
  const [openTimeModal, setOpenTimeModal] = useState(false);

  const [currentDay, setCurrentDay] = useState({});

  const [availableDays, setAvailableDays] = useState({
    sat: false,
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
  });

  const [selectedBookingType, setSelectedBookingType] = useState<string[]>([
    "immediate_booking",
  ]);

  useEffect(() => {
    if (formData.booking_type) {
      if (formData.booking_type === "both") {
        setSelectedBookingType(["booking_by_chat", "immediate_booking"]);
      }
    }
  }, []);

  //functions
  const handleBookingTypeClick = (value) => {
    const index = selectedBookingType.indexOf(value);
    if (index > -1) {
      setSelectedBookingType(
        selectedBookingType.filter((type) => type !== value)
      );
    } else {
      setSelectedBookingType([...selectedBookingType, value]);
    }
  };

  const handleTimeEdit = (day) => {
    setCurrentDay(day);
    setOpenTimeModal(true);
  };

  const handleNextClick = () => {
    if (!selectedBookingType[0]) {
      toast.error(t("select_booking_type_error"));
      return;
    }
    let workingDays: { day: string; value: boolean | IWorkingDay[] }[] = [];
    for (let [key, value] of Object.entries(availableDays)) {
      workingDays.push({ day: key, value: value });
    }

    let formData = {
      booking_type:
        selectedBookingType.length === 2 ? "both" : selectedBookingType[0],

      working_days: workingDays,
    };
    dispatch({
      type: ActionKind.AddData,
      payload: formData,
    });

    nextFormStep();
  };

  const handleActivateClick = (e) => {
    let workingDays: { day: string; value: boolean | IWorkingDay[] }[] = [];
    for (let [key, value] of Object.entries(availableDays)) {
      workingDays.push({ day: key, value: value });
    }

    let formData = {
      booking_type:
        selectedBookingType.length === 2 ? "both" : selectedBookingType[0],

      working_days: workingDays,
    };
    dispatch({
      type: ActionKind.AddData,
      payload: formData,
    });
  };

  const handlePrevClick = () => {
    prevFormStep();
  };

  const saveTimeSlots = (day, timeData, unavailable = false) => {
    if (unavailable) {
      setAvailableDays({ ...availableDays, [day]: false });
      return;
    }

    setAvailableDays({ ...availableDays, [day]: timeData });
  };

  return (
    <EducationContainer>
      <SubTitle>{t("fifth_step.subtitle")}</SubTitle>
      <Title>{t("fifth_step.title")}</Title>
      <FormContainer>
        <Label>{t("fifth_step.booking_type")}</Label>
        <BookingTypesContainer>
          <BookingType
            active={selectedBookingType.includes("booking_by_chat")}
            onClick={() => handleBookingTypeClick("booking_by_chat")}
          >
            <p className='title'>{t("fifth_step.chat_booking")}</p>
            <p className='subtitle'>{t("fifth_step.chat_booking_subtitle")}</p>
          </BookingType>
          <BookingType
            active={selectedBookingType.includes("immediate_booking")}
            onClick={() => handleBookingTypeClick("immediate_booking")}
          >
            <p className='title'>{t("fifth_step.instant_booking")}</p>
            <p className='subtitle'>
              {t("fifth_step.instant_booking_subtitle")}
            </p>
          </BookingType>
        </BookingTypesContainer>
        <Hint>({t("you_can_select_multiple")})</Hint>

        {formData.type === "academic" && (
          <>
            <Label>{t("fifth_step.your_calendar")}</Label>
            <DaysContainer>
              {daysOptions.map((day) => (
                <Day
                  key={day.value}
                  active={
                    availableDays[day.value] &&
                    availableDays[day.value].length > 0
                  }
                >
                  <div>
                    <span>{t(day.value)}</span>
                    <button type='button' onClick={() => handleTimeEdit(day)}>
                      {t("edit")}
                    </button>
                  </div>
                  {availableDays[day.value] ? (
                    availableDays[day.value].length > 0 ? (
                      availableDays[day.value].map((period) => (
                        <p className='time-slot' key={period.id}>
                          <span>{period.from}</span>
                          {t("to")}
                          <span>{period.to}</span>
                        </p>
                      ))
                    ) : (
                      ""
                    )
                  ) : (
                    <p>{t("unavailable")}</p>
                  )}
                </Day>
              ))}
            </DaysContainer>
          </>
        )}

        <ActionButtonsContainer>
          <div>
            <AppButton
              size='sm'
              variant='outline'
              onClick={() => handlePrevClick()}
            >
              {t("prev")}
            </AppButton>
            <AppButton size='sm' onClick={() => handleNextClick()}>
              {t("next")}
            </AppButton>
          </div>
        </ActionButtonsContainer>
      </FormContainer>
      <EditDayModal
        open={openTimeModal}
        setOpen={setOpenTimeModal}
        currentDay={currentDay}
        saveTimeSlots={saveTimeSlots}
        timeData={availableDays}
      />
    </EducationContainer>
  );
};

export default AvailableTimes;

import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { normalize } from "styled-normalize";
import universals from "./universals";
const GlobalStyle = createGlobalStyle`
  ${reset}
  ${normalize}

  :root {
    --text-menu:68,64,65;//#444041
    --text-main:25, 40, 54;//#192836
    --black:17, 13, 13;//110d0d
    --border-dark:35,31,32;//#231f20
    --main-color:46, 49, 146;//#2e3192
    --main-color-light:67, 77, 180;//#3d45aa
    --main-color-lighter:67, 77, 180;//#434db4
    --main-color-lightest:123, 132, 203;//#7b84cb,
    --blue-lighter:232, 234, 246;//#e8eaf6
    --blue-light:235, 242, 255;//#ebf2ff
    --off-white:254, 248, 250;//#fef8fa
    --grey:110, 100, 100;//#6e6464
    --dark-grey:119, 115, 116;//#777374
    --light-grey:161, 155, 157;//#a19b9d
    --purple:95, 104, 191;//#5f68bf
    --beige:249, 243, 245;//#f9f3f5
    --dark-beige:249, 243, 245;//#f2eced
    --yellow:255, 207, 132;//#ffcf84
    --red:242, 18, 117; ///#f21275
    --green:61, 150, 53; //#3d9635
    --font: 'Almarai';
    font-size:62.5%;
  }




  ${universals}


`;

export default GlobalStyle;

import styled from "styled-components/macro";

export const WalletsContainer = styled.div``;
export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;

export const Banner = styled.div`
  width: 100%;
  height: 60px;
  margin-top: 25px;
  padding: 20px;
  border: solid 1px #f4f7fe;
  background-color: rgb(var(--blue-lighter));
  p {
    color: rgb(var(--main-color-light));
    text-align: center;
  }
`;

export const PaymentsOverview = styled.div`
  background: #fff;
  margin-top: 15px;
  padding: 40px 20px 20px;
  border: solid 1px #f4f7fe;
`;

export const Balances = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PaymentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Amount = styled.p`
  font-size: 2.7rem;
  font-weight: bold;
  color: rgb(var(--main-color-light));
  span {
    font-size: 1.1rem;
    margin-right: 3px;
  }
`;
export const Label = styled.p`
  font-size: 1.4rem;
  color: rgb(var(--border-dark));
`;

export const Separator = styled.div`
  height: 68px;
  width: 1px;
  background: rgb(var(--blue-light));
`;

export const PaymentsSummary = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(var(--blue-light));
  padding-top: 15px;

  p {
    font-size: 1.4rem;
    color: rgb(var(--border-dark));
  }
  div {
    color: #363b9e;
    span {
      font-size: 1.1rem;
      margin-right: 3px;
    }
    span.price {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
`;

export const BankSelector = styled.div`
  border: solid 1px #f4f7fe;
  margin-top: 15px;
  padding: 20px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  & > div {
    display: flex;
    flex-direction: column;
  }
`;
export const AccountInfo = styled.div`
  display: flex;
  gap: 3px;
  span {
    font-size: 1.8rem;
    font-weight: bold;
  }
`;
export const Note = styled.p`
  font-size: 1.1rem;
  margin-top: 12px;
`;
export const TransactionsContainer = styled.div`
  margin-top: 30px;
`;
export const TransactionTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
`;
export const TransactionItem = styled.div`
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  margin-top: 30px;
`;
export const TransactionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const TransactionData = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

export const Operation = styled.div``;
export const TransactionDate = styled.p`
  font-size: 1.2rem;
`;
export const TransactionAmount = styled.p<{
  transfer?: boolean;
}>`
  font-size: 1.1rem;
  color: #1db409;
  color: ${(props) => (props.transfer ? "rgb(var(--red))" : "#1db409")};

  span.price {
    font-size: 1.4rem;
    margin-left: 3px;
  }
`;

import { DashboardAvatar, Filter } from "components/Icons";
import AppButton from "components/shared/AppButton";
import AppLabel from "components/shared/AppLabel";
import { useTranslation } from "react-i18next";
import EmptyNotifications from "./EmptyNotifications";
import { useEffect, useState } from "react";
import {
  AppointmentsHeader,
  AppointmentsItem,
  AppointmentsItems,
  AppointmentsWrapper,
  Info,
  Title,
  Message,
  SenderName,
  Date as NotificationDate,
} from "./notifications.styles";
import { trainerAPI } from "services/apis/trainer";
import format from "date-fns/format";
import { useAppSelector } from "store";

const NotificationsList = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<any[]>([]);
  const user = useAppSelector((state) => state.auth.user);
  const [pagination, setPagination] = useState<any>({});
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  //API calls
  const fetchNotifications = async (page = 1) => {
    setSubmitLoading(true);
    try {
      const response = await trainerAPI.getNotifications(page);

      if (response.data.metaData.key === "success") {
        setNotifications([...notifications, ...response.data.data.data]);
        setPagination(response.data.data.pagination);
      }
    } catch (error) {
    } finally {
      setSubmitLoading(false);
    }
  };

  const fetchMore = () => {
    if (pagination.current_page < pagination.total_pages) {
      fetchNotifications(pagination.current_page + 1);
    }
  };

  return notifications.length === 0 ? (
    <EmptyNotifications />
  ) : (
    <AppointmentsWrapper>
      <AppointmentsHeader>
        <Title>{t("notifications")}</Title>
      </AppointmentsHeader>
      <AppointmentsItems>
        {notifications.map((notification) => (
          <AppointmentsItem>
            <Info>
              {user.avatar ? (
                <img
                  width='77px'
                  height='77px'
                  src={user.avatar}
                  alt='user avatar'
                />
              ) : (
                <DashboardAvatar width='77px' height='77px' />
              )}
              <div>
                <SenderName>{notification?.data.title}</SenderName>
                <Message>{notification?.data.message}</Message>
              </div>
            </Info>
            <NotificationDate>
              {format(
                new Date(notification.created_at),
                "EEEE MMMM yyyy HH:mm"
              )}
            </NotificationDate>
          </AppointmentsItem>
        ))}
      </AppointmentsItems>
      <AppButton
        loader={submitLoading}
        disabled={submitLoading}
        onClick={fetchMore}
        size='lg'
      >
        {t("more")}
      </AppButton>
    </AppointmentsWrapper>
  );
};

export default NotificationsList;

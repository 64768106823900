import { Apple, Google, Logo, Logout } from "components/Icons";
import {
  HeaderContainer,
  LogoContainer,
  MenuContainer,
  LeftMenuContainer,
  MenuLink,
  IconContainer,
  StyledBurger,
  LogoutButton,
  LanguageButton,
} from "./appheader.styles";
import { useTranslation } from "react-i18next";
import { FiMenu } from "react-icons/fi";
import { IconContext } from "react-icons";
import { useState } from "react";
import MobileMenu from "./MobileMenu";
import { persistor, useAppSelector } from "store";
import { setDirection, setLocale } from "store/slices/locale";
import { useDispatch } from "react-redux";

const AppHeader = () => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const isCertified = useAppSelector((state) => state.auth.isCertified);
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);

  const dispatch = useDispatch();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const handleLogoutClick = () => {
    persistor.purge();
    localStorage.removeItem("persist:root");
    window.location.replace("/");
  };

  const handleLanguageToggle = () => {
    let language = getLanguage() === "ar" ? "en" : "ar";
    dispatch(setLocale());
    dispatch(setDirection());

    i18n.changeLanguage(language);
  };
  return (
    <HeaderContainer>
      <MobileMenu open={open} />
      <LogoContainer to='/'>
        <Logo />
      </LogoContainer>
      {/* <IconContext.Provider value={{ size: "4em" }}>
        <FiMenu />
      </IconContext.Provider> */}
      <StyledBurger
        open={open}
        onClick={() => setOpen(!open)}
        className={getLanguage() === "ar" ? "rtl" : "ltr"}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <MenuContainer>
        {isCertified && (
          <MenuLink to='/dashboard'>{t("menu.dashboard")}</MenuLink>
        )}
        {/* <MenuLink to='/'>{t("menu.contact_us")}</MenuLink> */}
        <MenuLink to='/terms'>{t("menu.terms")}</MenuLink>

        <MenuLink to='/policy'>{t("menu.policy")}</MenuLink>
        <MenuLink to='/about-us'>{t("menu.about_us")}</MenuLink>
      </MenuContainer>
      <LeftMenuContainer>
        <IconContainer>
          <a href='https://play.google.com/store/apps/details?id=com.alemnie.alemnie' target='_blank' rel='noreferrer'>
            <Google width='16px' />
          </a>
        </IconContainer>
        <IconContainer>
          <a href='https://apps.apple.com/us/app/alemni-%D8%B9%D9%84%D9%85%D9%86%D9%8A/id1627030538' target='_blank' rel='noreferrer'>
            <Apple width='16px' />
          </a>
        </IconContainer>
        <IconContainer>
          <LanguageButton onClick={() => handleLanguageToggle()}>
            {getLanguage() === "ar" ? "English" : "عربي"}
          </LanguageButton>
        </IconContainer>
        {isAuthenticated && (
          <IconContainer>
            <LogoutButton onClick={() => handleLogoutClick()}>
              <span>{t("logout")}</span>
            </LogoutButton>
          </IconContainer>
        )}
      </LeftMenuContainer>
    </HeaderContainer>
  );
};

export default AppHeader;

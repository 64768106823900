import ScrollToTop from "helpers/ScrollToTop";
import Home from "pages/Home";
import Login from "pages/auth/Login";
import Register from "pages/auth/Register";
import { Route, Routes } from "react-router-dom";
import GlobalStyles from "styles/globals";
import ForgetPassword from "pages/auth/ForgetPassword";
import AccountSetup from "pages/AccountSetup";
import Dashboard from "pages/Dashboard";
import Appointments from "pages/Appointments";
import Courses from "pages/Courses";
import "react-responsive-modal/styles.css";
import { Suspense, useEffect } from "react";
import GuestOutlet from "helpers/GuestOutlet";
import PrivateOutlet from "helpers/PrivateOutlet";
import Thanks from "pages/Thanks";
import FreeLessons from "pages/FreeLessons";
import Wallet from "pages/Wallet";
import SingleAppointment from "pages/SingleAppointment";
import AddCourse from "pages/AddCourse";
import AddLesson from "pages/AddLesson";
import Notifications from "pages/Notifications";
import Messages from "pages/Messages";
import CertifiedOutlet from "helpers/CertifiedOutlet";
import ResetPassword from "pages/auth/ResetPassword";
import Packages from "pages/Packages";
import SingleMessage from "pages/SingleMessage";
import Checkout from "pages/Checkout";
import Terms from "pages/Terms";
import Policy from "pages/Policy";
import Settings from "pages/Settings";
import AboutUs from "pages/AboutUs";
import Invoice from "pages/Invoice";
import TrainerPackages from "pages/TrainerPackages";
import AddPackage from "pages/AddPackage";
import { useTranslation } from "react-i18next";
import EditPackage from "pages/EditPackage";

function App() {
  return (
    <>
      <Suspense fallback=''>
        <ScrollToTop />
        <GlobalStyles />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/policy' element={<Policy />} />
          <Route path='/about-us' element={<AboutUs />} />

          <Route element={<GuestOutlet />}>
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/forget' element={<ForgetPassword />} />
            <Route path='/reset' element={<ResetPassword />} />
          </Route>

          {/* Private Routes */}
          <Route element={<PrivateOutlet />}>
            <Route path='/account-setup' element={<AccountSetup />} />
            <Route path='/thanks' element={<Thanks />} />
          </Route>

          {/* Certified Routes */}
          <Route element={<CertifiedOutlet />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/dashboard/appointments' element={<Appointments />} />
            <Route
              path='/dashboard/appointments/:id'
              element={<SingleAppointment />}
            />
            <Route path='/dashboard/courses' element={<Courses />} />
            <Route path='/dashboard/courses/add' element={<AddCourse />} />
            <Route path='/dashboard/free-lessons' element={<FreeLessons />} />
            <Route path='/dashboard/free-lessons/add' element={<AddLesson />} />
            <Route path='/dashboard/wallet' element={<Wallet />} />
            <Route
              path='/dashboard/notifications'
              element={<Notifications />}
            />
            <Route path='/dashboard/messages' element={<Messages />} />
            <Route path='/dashboard/messages/:id' element={<SingleMessage />} />
            <Route path='/dashboard/packages' element={<Packages />} />
            <Route
              path='/dashboard/custom-packages'
              element={<TrainerPackages />}
            />

            <Route
              path='/dashboard/custom-packages/:id/edit'
              element={<EditPackage />}
            />
            <Route
              path='/dashboard/custom-packages/add'
              element={<AddPackage />}
            />
            <Route path='/dashboard/checkout' element={<Checkout />} />
            <Route path='/dashboard/settings' element={<Settings />} />
            <Route path='/dashboard/invoice' element={<Invoice />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;

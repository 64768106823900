import iconGenerator from "components/Icons/iconGenerator";
import LogoSVG from 'assets/images/logo.svg'
import GoogleSVG from 'assets/icons/google_play.svg'
import  AppleSvg from 'assets/icons/apple.svg'
import HomeShapeOneSVG from 'assets/images/home-shape.svg'
import AvatarSVG from 'assets/images/avatar.svg'
import DashboardAvatarSVG from 'assets/images/dashboard-avatar.svg'
import  HomeBlueSVG from 'assets/icons/sidebar/home-blue.svg'
import  HomeSVG from 'assets/icons/sidebar/home.svg'
import  CalendarBlueSVG from 'assets/icons/sidebar/calendar-blue.svg'
import  CalendarSVG from 'assets/icons/sidebar/calendar.svg'
import  ChatBlueSVG from 'assets/icons/sidebar/chat-blue.svg'
import  ChatSVG from 'assets/icons/sidebar/chat.svg'
import  CoursesBlueSVG from 'assets/icons/sidebar/courses-blue.svg'
import  CoursesSVG from 'assets/icons/sidebar/courses.svg'
import  FreeLessonBlueSVG from 'assets/icons/sidebar/free-blue.svg'
import  FreeLessonSVG from 'assets/icons/sidebar/free.svg'
import  SettingBlueSVG from 'assets/icons/sidebar/setting-blue.svg'
import  SettingSVG from 'assets/icons/sidebar/setting.svg'
import  WalletBlueSVG from 'assets/icons/sidebar/wallet-blue.svg'
import  WalletSVG from 'assets/icons/sidebar/wallet.svg'
import  LogoutSVG from 'assets/icons/sidebar/logout.svg'
import  PremiumSVG from 'assets/icons/sidebar/premium.svg'
import  ThanksSVG from 'assets/icons/thanks.svg'
import  FacebookSVG from 'assets/icons/facebook.svg'
import  LinkedinSVG from 'assets/icons/linkedin.svg'
import  TwitterSVG from 'assets/icons/tw.svg'
import  YoutubeSVG from 'assets/icons/youtube.svg'
import  InstagramSVG from 'assets/icons/instagram.svg'
import  TikTokSVG from 'assets/icons/tiktok.svg'
import  SnapchatSVG from 'assets/icons/snapchat.svg'
import  PdfSVG from 'assets/icons/pdf.svg'
import GoogleIconSVG from 'assets/icons/google.svg'

import  CallSVG from 'assets/icons/panel/call.svg'
import  FriendsSVG from 'assets/icons/panel/friends.svg'
import  PolicySVG from 'assets/icons/panel/policy.svg'
import  ReferralSVG from 'assets/icons/panel/referral.svg'
import  StudentsSVG from 'assets/icons/panel/students.svg'
import  TermsSVG from 'assets/icons/panel/terms.svg'
import BellSVG from 'assets/icons/sidebar/bell.svg'
import EyeSVG from 'assets/icons/sidebar/eye.svg'
import LetterSVG from 'assets/icons/sidebar/students-number.svg'
import TimeSVG from 'assets/icons/sidebar/time.svg'
import FilterSVG from 'assets/icons/dashboard/filter.svg'
import EmptyCourseSVG from 'assets/icons/dashboard/classes_empty.svg'
import EmptyFreeLessonsSVG from 'assets/icons/dashboard/free_lessons.svg'
import EmptyMessageSVG from 'assets/icons/dashboard/message_empty.svg'
import SendMessageSvg from 'assets/icons/dashboard/message.svg'


export const Logo = iconGenerator(LogoSVG);
export const Google = iconGenerator(GoogleSVG);
export const Apple = iconGenerator(AppleSvg);
export const HomeShapeOne= iconGenerator(HomeShapeOneSVG);
export const Avatar= iconGenerator(AvatarSVG);
export const DashboardAvatar= iconGenerator(DashboardAvatarSVG);

export const Home= iconGenerator(HomeSVG);
export const HomeBlue= iconGenerator(HomeBlueSVG);
export const Calendar= iconGenerator(CalendarSVG);
export const CalendarBlue= iconGenerator(CalendarBlueSVG);
export const Chat= iconGenerator(ChatSVG);
export const ChatBlue= iconGenerator(ChatBlueSVG);
export const Courses= iconGenerator(CoursesSVG);
export const CoursesBlue= iconGenerator(CoursesBlueSVG);
export const FreeLesson= iconGenerator(FreeLessonSVG);
export const FreeLessonBlue= iconGenerator(FreeLessonBlueSVG);
export const Setting= iconGenerator(SettingSVG);
export const SettingBlue= iconGenerator(SettingBlueSVG);
export const Wallet= iconGenerator(WalletSVG);
export const WalletBlue= iconGenerator(WalletBlueSVG);
export const Logout = iconGenerator(LogoutSVG);
export const Premium = iconGenerator(PremiumSVG);

export const Call = iconGenerator(CallSVG);
export const Friends = iconGenerator(FriendsSVG);
export const Policy = iconGenerator(PolicySVG);
export const Referral = iconGenerator(ReferralSVG);
export const Students = iconGenerator(StudentsSVG);
export const Terms = iconGenerator(TermsSVG);
export const Bell = iconGenerator(BellSVG);
export const Eye= iconGenerator(EyeSVG);
export const Letter= iconGenerator(LetterSVG);
export const Time= iconGenerator(TimeSVG);
export const Filter= iconGenerator(FilterSVG);
export const EmptyCourse= iconGenerator(EmptyCourseSVG);
export const ThanksBackground= iconGenerator(ThanksSVG);
export const EmptyFreeLessons= iconGenerator(EmptyFreeLessonsSVG);
export const EmptyMessage= iconGenerator(EmptyMessageSVG);
export const Facebook= iconGenerator(FacebookSVG);
export const Linkedin= iconGenerator(LinkedinSVG);
export const Twitter= iconGenerator(TwitterSVG);
export const Youtube= iconGenerator(YoutubeSVG);
export const Instagram= iconGenerator(InstagramSVG);
export const TikTok= iconGenerator(TikTokSVG);
export const Snapchat= iconGenerator(SnapchatSVG);
export const SendMessage= iconGenerator(SendMessageSvg);
export const Pdf= iconGenerator(PdfSVG);
export const GoogleIcon= iconGenerator(GoogleIconSVG);
import AppButton from "components/shared/AppButton";
import AppInput from "components/shared/AppInput";
import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { trainerAPI } from "services/apis/trainer";
import {
  ActionsContainer,
  ModalBodyContainer,
  ModalTitle,
} from "./lessonUrlModal.styles";

const LessonUrlModal = ({
  setOpen,
  open,
  lessonUrl,
  bookingId,
  fetchResources = () => {},
  type = "lesson",
}) => {
  //hooks
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  //functions

  useEffect(() => {
    setValue("session_url", lessonUrl || "");
  }, [lessonUrl]);

  const handleCancelSubmit = () => {
    setOpen(false);
  };

  const handleConfirmSubmit = async (data) => {
    try {
      setSubmitLoading(true);
      let response;
      if (type === "lesson") {
        response = await trainerAPI.updateSessionUrl(data, bookingId);
      } else {
        response = await trainerAPI.updateEventUrl(data, bookingId);
      }
      if (response.data.metaData.key === "success") {
        toast.success(t("added_successfully"));
        fetchResources();
        setOpen(false);
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setSubmitLoading(false);
      // setValue("session_url", "");
    }
  };
  return (
    <Modal
      ref={modalRef}
      open={open}
      onClose={() => setOpen(false)}
      initialFocusRef={modalRef}
      showCloseIcon={true}
      closeOnOverlayClick={true}
      classNames={{
        root: "custom-root",
        overlay: "app-modal-overlay",
        modal: "app-modal review-app-modal",
        closeButton: "app-modal-close",
      }}
      center
    >
      <ModalBodyContainer>
        <ModalTitle>{t("session_url")}</ModalTitle>
        <form onSubmit={handleSubmit(handleConfirmSubmit)}>
          <AppInput
            register={register}
            name='session_url'
            errors={errors}
            pattern={/^https:\/\/meet\.google\.com\/[a-z0-9-]+$/i}
            defaultValue={lessonUrl || ""}
          />
          <ActionsContainer>
            <AppButton
              size='sm'
              variant='outline'
              onClick={() => handleCancelSubmit()}
              type='button'
            >
              {t("cancel")}
            </AppButton>

            <AppButton type='submit' size='sm' loader={submitLoading}>
              {t("edit")}
            </AppButton>
          </ActionsContainer>
        </form>
      </ModalBodyContainer>
    </Modal>
  );
};

export default LessonUrlModal;

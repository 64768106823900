import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  padding: 24px;
  max-width: 720px;

  ${media("medium")} {
    width: 90vw;
  }
`;
export const ModalTitle = styled.h1`
  margin: 0;
  font-size: 2.7rem;
  font-weight: bold;
  text-align: right;
`;
export const DayName = styled.span<{
  isLtr: boolean;
}>`
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(var(--border-dark));
  text-align: ${({ isLtr }) => (isLtr ? "right" : "left")};

  display: block;
  margin-top: 45px;
`;
export const TimeInputsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
`;
export const TimeInput = styled.div`
  width: 315px;
  position: relative;
  ${media("medium")} {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
  }
  p.label {
    display: none;
    ${media("medium")} {
      display: block;
    }
  }
  input {
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    height: 56px;
    padding: 15px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 125px !important;
  }
  .react-datepicker__time-container {
    width: 125px !important;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    font-size: 16px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;

  ${media("medium")} {
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
`;
export const UnavailableCheckbox = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  span {
    font-size: 1.4rem;
  }
  input {
    display: block;
    height: 20px;
    width: 20px;
    accent-color: rgb(var(--main-color));
  }
`;

export const AddPeriodContainer = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;
  margin-top: 40px;
`;
export const AddPeriodIcon = styled.span`
  background: rgb(var(--off-white));
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
`;
export const AddPeriodButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: rgb(var(--purple));
  font-size: 1.4rem;
  cursor: pointer;
  text-decoration: underline;
`;

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-around;

  ${media("medium")} {
    display: none;
  }
`;

export const Note = styled.p`
  font-size: 1.4rem;
  position: relative;
`;

export const DeleteButton = styled.button`
  //reset button styles with one line
  all: unset;
  cursor: pointer;
`;

import styled from "styled-components/macro";
import { media } from "styles/utils";

export const EducationContainer = styled.div``;

export const Title = styled.h2`
  font-size: 2.7rem;
  font-weight: bold;
`;
export const SubTitle = styled.h2`
  font-size: 1.4rem;
`;

//form

export const FormContainer = styled.form`
  width: 100%;
  margin-top: 30px;

  .app-select {
    width: 100%;
    margin-top: 24px;
  }
`;

export const CertificatesContainer = styled.div``;

export const Label = styled.span`
  margin-bottom: 13px;
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  margin-top: 24px;
`;

export const ImageInput = styled.input`
  display: none;
`;
export const ImageUploader = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 202px;
  height: 140px;
  border: 1px dashed #707070;
  background-color: rgb(var(--blue-light));
  cursor: pointer;
  span {
    font-size: 1.4rem;
    color: rgb(var(--main-color));
    margin-top: 18px;
  }

  h1 {
    background-color: rgb(var(--main-color-lighter));
    border: none;
    color: #fff;
    font-size: 1.1rem;
    padding: 10px 18px;
    margin-top: 8px;
    cursor: pointer;
  }
`;

export const ActionButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    gap: 10px;
    ${media("medium")} {
      margin-bottom: 13px;
    }
  }
  ${media("medium")} {
    flex-direction: column;
  }
`;

export const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px dotted rgb(var(--main-color));
`;
export const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
export const ImageWrapper = styled.div`
  width: 202px;
  height: 140px;
  position: relative;
`;

export const DeleteImage = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(var(--red));
  position: absolute;
  bottom: 20px;
  left: 50%;
  cursor: pointer;
`;

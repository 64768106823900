import AppButton from "components/shared/AppButton";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { Modal } from "react-responsive-modal";
import {
  ModalBodyContainer,
  ModalTitle,
  DayName,
  TimeInputsContainer,
  TimeInput,
  ActionsContainer,
  UnavailableCheckbox,
  AddPeriodButton,
  AddPeriodIcon,
  AddPeriodContainer,
  LabelsContainer,
  Note,
  DeleteButton,
} from "./editDayModal.styles";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getMidnightTime,
  getTimeFromString,
  getTimeString,
} from "helpers/utils";
interface ITimeField {
  from: string;
  to: string;
  id: number;
}
const EditDayModal = ({
  setOpen,
  open,
  currentDay,
  saveTimeSlots,
  timeData,
}) => {
  //hooks
  const modalRef = useRef(null);
  const [periodFields, setPeriodFields] = useState<any[]>([
    { from: "", to: "", id: 1 },
  ]);
  const [periodCounter, setPeriodCounter] = useState(1);
  const [unavailable, setUnavailable] = useState(false);
  const { t, i18n } = useTranslation();
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  //effects

  useEffect(() => {
    if (timeData[currentDay.value] === false) {
      setPeriodFields([{ from: "", to: "", id: 1 }]);
      setUnavailable(true);
      return;
    }
    if (timeData[currentDay.value] && timeData[currentDay.value].length > 0) {
      setPeriodFields([...timeData[currentDay.value]]);
      setUnavailable(false);
      return;
    }

    if (timeData[currentDay.value] && timeData[currentDay.value].length === 0) {
      setPeriodFields([{ from: "", to: "", id: 1 }]);
      setUnavailable(false);

      return;
    }
  }, [currentDay.value, timeData]);

  const handleTimeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    let period = periodFields.find((period) => period.id === id);
    if (period) {
      if (e.target.name === "from") {
        if (period.to.length > 0 && period.to <= e.target.value) {
          toast.error(t("lower_time_error"));
          return;
        }
      }
      if (e.target.name === "to") {
        if (period.from.length > 0 && period.from >= e.target.value) {
          toast.error(t("higher_time_error"));
          return;
        }
      }
    }

    setPeriodFields(
      periodFields.map((period: ITimeField) => {
        if (period.id !== id) {
          return period;
        }
        if (e.target.name === "from") {
          return { ...period, from: e.target.value };
        }
        if (e.target.name === "to") {
          return { ...period, to: e.target.value };
        }
        return period;
      })
    );
  };

  const isFieldsEmpty = () => {
    periodFields.forEach((period) => {
      if (period.to.length === 0 || period.from.length === 0) {
        throw new Error(t("empty_time_error"));
      }
    });
    return false;
  };

  const handleAddPeriod = (e) => {
    setPeriodCounter((prev) => prev + 1);
    setPeriodFields([
      ...periodFields,
      { from: "", to: "", id: periodCounter + 1 },
    ]);
  };

  const handleDeletePeriod = (id: number) => {
    setPeriodFields(periodFields.filter((period) => period.id !== id));
  };

  const handleUnavailableChange = (e) => {
    if (unavailable) {
      setUnavailable(false);
    } else {
      setUnavailable(true);
    }
  };

  const handleSaveTime = () => {
    if (unavailable) {
      toast(t("unavailable_saved"));
      saveTimeSlots(currentDay.value, periodFields, unavailable);
      setOpen(false);
      return;
    }

    try {
      isFieldsEmpty();
      saveTimeSlots(currentDay.value, periodFields, unavailable);
      setPeriodFields([{ from: "", to: "", id: 1 }]);
      setUnavailable(false);
      setOpen(false);
    } catch (error) {
      let message = "Unknown Error";
      if (error instanceof Error) message = error.message;
      toast.error(message);
    }
    // return;
  };
  return (
    <Modal
      ref={modalRef}
      open={open}
      onClose={() => setOpen(false)}
      initialFocusRef={modalRef}
      showCloseIcon={true}
      closeOnOverlayClick={true}
      classNames={{
        root: "custom-root",
        overlay: "app-modal-overlay",
        modal: "app-modal review-app-modal",
        closeButton: "app-modal-close",
      }}
      center
    >
      <ModalBodyContainer
        style={{ direction: getLanguage() === "ar" ? "rtl" : "ltr" }}
      >
        <ModalTitle>{t("specify_your_availability")}</ModalTitle>
        <DayName isLtr={isLtr}>{t(currentDay.value)}</DayName>
        <LabelsContainer>
          <p>{t("from")}</p>
          <p>{t("to")}</p>
        </LabelsContainer>
        {periodFields.map((period) => (
          <TimeInputsContainer key={period.id}>
            <TimeInput>
              <p className='label'>{t("from")}</p>
              <ReactDatePicker
                selected={
                  period.from
                    ? getTimeFromString(period.from)
                    : getMidnightTime()
                }
                onChange={(date) =>
                  handleTimeChange(
                    {
                      target: { name: "from", value: getTimeString(date) },
                    } as React.ChangeEvent<HTMLInputElement>,
                    period.id
                  )
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption='Time'
                dateFormat='h:mm aa'
                required
              />
            </TimeInput>
            <TimeInput>
              <p className='label'>{t("to")}</p>

              <ReactDatePicker
                selected={
                  period.to ? getTimeFromString(period.to) : getMidnightTime()
                }
                onChange={(date) =>
                  handleTimeChange(
                    {
                      target: { name: "to", value: getTimeString(date) },
                    } as React.ChangeEvent<HTMLInputElement>,
                    period.id
                  )
                }
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={60}
                timeCaption='Time'
                dateFormat='h:mm aa'
                required
              />
            </TimeInput>

            {periodFields.length > 1 && (
              <DeleteButton onClick={() => handleDeletePeriod(period.id)}>
                X
              </DeleteButton>
            )}
          </TimeInputsContainer>
        ))}
        <AddPeriodContainer>
          <AddPeriodIcon>
            <FiPlus />
          </AddPeriodIcon>
          <AddPeriodButton onClick={handleAddPeriod}>
            {t("add_period")}
          </AddPeriodButton>
        </AddPeriodContainer>
        <ActionsContainer>
          <UnavailableCheckbox>
            <input
              type='checkbox'
              checked={unavailable}
              onChange={(e) => handleUnavailableChange(e)}
            />
            <span>{t("unavailable")}</span>
          </UnavailableCheckbox>

          <Note>({t("unavailable_note")}) </Note>
          <AppButton size='sm' onClick={() => handleSaveTime()}>
            {t("save")}
          </AppButton>
        </ActionsContainer>
      </ModalBodyContainer>
    </Modal>
  );
};

export default EditDayModal;

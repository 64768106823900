import styled from "styled-components/macro";

export const AppointmentContainer = styled.div``;
export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;
export const Header = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 18px;
  padding: 15px;
  max-height: 78px;
  margin-top: 25px;
`;
export const StudentName = styled.p``;
export const Details = styled.div`
  margin-top: 15px;
  background: #fff;
  padding: 15px 15px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  hr {
    background: #e8ecef;
    height: 1px;
    border: 0;
    width: 100%;
    margin: 20px 0 0;
  }
`;
export const DetailSection = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
export const TitleContainer = styled.div<{ isLtr: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isLtr }) => (isLtr ? "row-reverse" : "row")}; ;
`;
export const SectionTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(var(--border-dark));
`;
export const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`;
export const DetailTitle = styled.span`
  font-size: 1.4rem;
  color: rgb(var(--border-dark));
  &.bold {
    font-weight: bold;
  }
`;
export const DetailValue = styled.span`
  font-size: 1.1rem;
  color: rgb(var(--border-dark));
  display: flex;
  align-items: center;
  gap: 3px;
  em {
    font-size: 1.4rem;
    font-weight: bold;
  }
`;

export const MessageStudent = styled.button`
  padding: 0;
  background-color: transparent;
  color: rgb(var(--purple));
  margin-top: 20px;
  width: 100%;
  border: none;
  cursor: pointer;
`;

export const BackButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: rgb(var(--off-white));
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`;

import Settings from "components/Dashboard/Settings";
import DashboardLayout from "layouts/DashboardLayout";

const SettingsContainer = () => {
  return (
    <DashboardLayout>
      <Settings />
    </DashboardLayout>
  );
};

export default SettingsContainer;

import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  padding: 24px;
  min-width: 350px;
`;
export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  gap: 10px;
`;
export const ModalTitle = styled.h1`
  margin: 0 0 30px 0;
  font-size: 2.7rem;
  font-weight: bold;
  text-align: right;
`;

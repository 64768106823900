import styled from "styled-components/macro";

export const PackagesListContainer = styled.div``;

export const PackagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;

export const PackagesItems = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
`;
export const PackageItem = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  gap: 12px;
  &:not(:first-child) {
    margin-top: 15px;
  }
  .package-image {
    width: 122px;
    height: 118px;
    max-width: 122px;
    object-fit: cover;
  }
`;

export const PackageImage = styled.div`
  background-color: rgb(var(--blue-light));
  width: 122px;
  height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const PackageInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const PackageTitle = styled.div``;
export const PackageDescription = styled.p`
  margin-top: 12px;
  font-size: 1.3rem;
  color: #635f60;
  word-break: break-word;
`;
export const PackageAction = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  align-items: center;
`;
export const SubscribeButton = styled.button`
  font-size: 1.4rem;
  font-weight: bold;
  color: #363b9e;
  border: none;
  background-color: rgb(var(--beige));
  padding: 13px;
  cursor: pointer;
`;
export const PackagePrice = styled.div`
  font-weight: bold;
  em {
    font-weight: normal;
    font-size: 1.1rem;
  }
`;

export const AlreadySubscribeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const AlreadySubscribe = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  color: #363b9e;
  border: none;
  background-color: rgb(var(--beige));
  padding: 13px;
  display: flex;
  gap: 4px;
`;

import styled from "styled-components/macro";
import AuthBackground from "assets/images/auth-background.jpg";
import WhiteLogo from "assets/images/logo-white.svg";
import { Link } from "react-router-dom";
import { media } from "styles/utils";

export const AuthLeftSection = styled.div`
  background-image: url(${AuthBackground});

  width: 100%;
  &:before {
    content: "";
    display: block;
    background-image: url(${WhiteLogo});
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
  }

  ${media("large")} {
    display: none;
  }
`;

//Login
export const LoginFormContainer = styled.div`
  padding: 100px 150px 20px 100px;
  max-width: 900px;
  ${media("large")} {
    grid-column: 1/3;
    padding: 100px 20px 20px;
  }
  ${media("medium")} {
    width: 100%;

    padding: 0;
  }

  .otp-container {
    gap: 10px;
    margin-bottom: 30px;
    direction: ltr;
    div {
      flex: 1;
    }
    ${media("medium")} {
      gap: 5px;
    }
  }
  input.otp-input {
    width: 60px !important;
    max-width: 73.8px;

    height: 56px;
    border: solid 1px #e6eef9;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: rgb(var(--grey));
    background-color: #ffffff;
    &:focus {
      outline: none;
      border: 1px solid #110d0d;
      color: rgb(var(--text-main));
    }
    &:focus + span {
      color: rgb(var(--text-main));
    }
    ${media("medium")} {
      max-width: 52px;
    }
  }
`;
export const LoginTitle = styled.h1`
  font-size: 2.7rem;
  font-weight: bold;
  margin: 0;
`;

export const AuthSubTitle = styled.h2`
  font-size: 1.4rem;
  margin-top: 15px;
`;
export const NoAccount = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const NoAccountTitle = styled.span`
  font-size: 1.4rem;
`;
export const NewAccountLink = styled.h1`
  color: rgb(var(--main-color));
  font-size: 1.4rem;
`;

export const FormContainer = styled.form`
  margin-top: 40px;
  /* max-width: 400px; */
  ${media("medium")} {
    max-width: 100%;
  }
  .PhoneInput {
    margin-bottom: 20px;
    flex-direction: row-reverse;
    input {
      width: 100%;
      height: 50px;
      font-size: 1.6rem;
      color: rgb(var(--black));
      border: solid 1px rgba(0, 0, 0, 0.12);
      padding: 10px 14px;
      direction: ltr;
    }
  }
`;

export const ForgetPassword = styled(Link)`
  font-size: 1.1rem;
  color: rgb(var(--grey));
  text-decoration: none;
  text-align: left;
  margin-right: auto;
  display: block;
  margin-top: 10px;
`;

//register

export const TermsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const TermsLink = styled.a`
  margin-right: 4px;
`;
export const TermsText = styled.p`
  font-size: 1.4rem;
`;
export const TermsAccept = styled.input`
  display: block;
  height: 20px;
  width: 20px;
  accent-color: rgb(var(--main-color));
`;

//forget password

export const ForgetPasswordImage = styled.img`
  margin-top: 40px;
`;

export const ForgetPasswordTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const BackLink = styled(Link)`
  width: 24px;
  height: 24px;
  background-color: rgb(var(--off-white));
  display: flex;
  align-items: center;
  justify-content: center;
`;

//otp form

export const OtpFormContainer = styled.div`
  padding: 100px 150px 20px 100px;
  max-width: 900px;

  ${media("large")} {
    grid-column: 1/3;
    padding: 100px 20px 20px;
  }
  ${media("medium")} {
    width: 100%;

    padding: 0;
  }
  .otp-container {
    gap: 10px;
    margin-bottom: 30px;
    direction: ltr;
    div {
      flex: 1;
    }
    ${media("medium")} {
      gap: 5px;
    }
  }
  input.otp-input {
    width: 60px !important;
    max-width: 73.8px;

    height: 56px;
    border: solid 1px #e6eef9;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: rgb(var(--grey));
    background-color: #ffffff;
    &:focus {
      outline: none;
      border: 1px solid #110d0d;
      color: rgb(var(--text-main));
    }
    &:focus + span {
      color: rgb(var(--text-main));
    }
    ${media("medium")} {
      max-width: 52px;
    }
  }
`;
export const OtpTitle = styled.h1`
  font-size: 2.7rem;
  font-weight: bold;
  margin: 0;
`;

export const OtpSubTitle = styled.h1`
  font-size: 1.4rem;
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ResendButton = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
  color: rgb(var(--purple));
  margin-top: 24px;
  font-size: 1.4rem;
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
`;

export const BackButton = styled.button`
  width: 24px;
  height: 24px;
  background-color: rgb(var(--off-white));
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
`;

import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ContractContainer = styled.div`
  .sigCanvas {
    border: 1px solid;
  }
`;

export const Title = styled.h2`
  font-size: 2.7rem;
  font-weight: bold;
`;
export const SubTitle = styled.h2`
  font-size: 1.4rem;
`;

export const FormContainer = styled.form`
  width: 100%;
  margin-top: 30px;
`;

export const FormControl = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  ${media("medium")} {
    flex-wrap: wrap;
  }
  .PhoneInput {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row-reverse;
    input {
      width: 100%;
      height: 50px;
      font-size: 1.6rem;
      color: rgb(var(--black));
      border: solid 1px rgba(0, 0, 0, 0.12);
      padding: 10px 14px;
      direction: ltr;
    }
  }
  .app-select {
    width: 100%;
  }
`;
export const ContractPreview = styled.div`
  border: 1px solid #333333;
  width: 100%;
  max-height: 400px !important;
  overflow: scroll;
  padding: 20px;
  margin-top: 30px;
`;

export const ContractSection = styled.div`
  margin-bottom: 20px;
`;
export const ActionButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  ${media("medium")} {
    margin-bottom: 13px;
    flex-direction: column;
  }
`;

export const Label = styled.span`
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  margin-top: 24px;
`;

export const ContractHeader = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;

export const SignatureSection = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 160px;
`;
export const Party = styled.div``;
export const PartyTitle = styled.h1`
  font-size: 1.6rem;
`;
export const SignatureImage = styled.img`
  max-width: 200px;
`;

export const PrintButton = styled.button`
  margin-top: 20px;
`;

export const SignatureButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

export const Hint = styled.span`
  font-size: 1.1rem;
  margin-top: 10px;
  margin-bottom: 2px;
`;

export const SignatureContainer = styled.div`
  display: flex;
  flex-direction: column;

  canvas {
    max-width: 400px;
  }
`;

import { DashboardAvatar } from "components/Icons";
import ChooseBankModal from "components/Modals/ChooseBankModal";
import AppButton from "components/shared/AppButton";
import format from "date-fns/format";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import { IWalletData } from "types";
import {
  Amount,
  Banner,
  Label,
  PaymentItem,
  PaymentsOverview,
  Separator,
  Title,
  WalletsContainer,
  Balances,
  PaymentsSummary,
  BankSelector,
  AccountInfo,
  Note,
  TransactionsContainer,
  TransactionTitle,
  TransactionItem,
  TransactionInfo,
  Operation,
  TransactionDate,
  TransactionAmount,
  TransactionData,
} from "./walletDetails.styles";

const WalletDetails = () => {
  //hooks
  const [openModal, setOpenModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const user = useAppSelector((state) => state.auth.user);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [walletData, setWalletData] = useState<IWalletData>();
  const { t } = useTranslation();
  useEffect(() => {
    fetchBanks();
    fetchTransactions();
  }, []);
  //function

  const fetchTransactions = async () => {
    try {
      const response = await trainerAPI.getTransactionsData(user.id);
      if (response.data.metaData.key === "success") {
        setWalletData({ ...response.data.data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBanks = async () => {
    try {
      const response = await trainerAPI.getBanks();

      if (response.data.metaData.key === "success") {
        setBanks(
          response.data.data.map((bank) => ({
            label: bank.name,
            value: bank.id,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditBank = () => {
    setOpenModal(true);
  };

  const handleSubmitBank = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await trainerAPI.updateBankAccount(data, user.id);
      if (response.data.metaData.key === "success") {
        setOpenModal(false);
        fetchTransactions();
        toast.success(t("edited_successfully"));
      }
    } catch (error) {
      toast.error(t("error_message"));
      setOpenModal(true);
    } finally {
      setSubmitLoading(false);
    }
  };
  return (
    <WalletsContainer>
      <Title>{t("wallet")}</Title>
      <Banner>
        <p>{t("wallet_banner")}</p>
      </Banner>
      <PaymentsOverview>
        <Balances>
          <PaymentItem>
            <Amount>
              {walletData?.balance}
              <span>{t("riyal")}</span>
            </Amount>
            <Label>{t("total_balance")}</Label>
          </PaymentItem>
          <Separator></Separator>
          {/* <PaymentItem>
            <Amount>
              00.00
              <span>{t("riyal")}</span>
            </Amount>
            <Label>{t("outstanding_balance")}</Label>
          </PaymentItem>
          <Separator></Separator>
          <PaymentItem>
            <Amount>
              00.00
              <span>{t("riyal")}</span>
            </Amount>
            <Label>{t("withdrawable_balance")}</Label>
          </PaymentItem> */}
        </Balances>
        <PaymentsSummary>
          <p>{t("payments_summary")}</p>
          <div>
            <span className='price'> {walletData?.balance}</span>
            <span>{t("riyal")}</span>
          </div>
        </PaymentsSummary>
      </PaymentsOverview>
      <BankSelector>
        <div>
          <AccountInfo>
            <span> {walletData?.bank}</span>
            <span> - </span>
            <span>{walletData?.holder_name}</span>
          </AccountInfo>
          <Note>{t("payment_note")}</Note>
        </div>
        <AppButton
          size={walletData?.bank ? "xs" : "sm"}
          fontSize='14px'
          onClick={handleEditBank}
        >
          {walletData?.bank ? t("edit") : t("add_bank")}
        </AppButton>
      </BankSelector>
      {walletData?.transactions && walletData?.transactions?.length > 0 && (
        <TransactionsContainer>
          <TransactionTitle>{t("transactions")}</TransactionTitle>
          {walletData?.transactions.map((transaction) => (
            <TransactionItem key={transaction.id}>
              <TransactionInfo>
                <DashboardAvatar width='50px' height='50px' />
                <TransactionData>
                  <Operation>
                    {transaction.user_type === "managerial"
                      ? "تحويل لحسابك"
                      : transaction.user_name}
                  </Operation>
                  <TransactionDate>
                    {format(new Date(transaction.created_at), "dd-yyyy-MMM")}
                  </TransactionDate>
                </TransactionData>
              </TransactionInfo>
              <TransactionAmount
                transfer={transaction.user_type === "managerial"}
              >
                <span className='price'>
                  <span>
                    {transaction.user_type === "managerial" ? " - " : " + "}
                  </span>
                  {transaction.amount}
                </span>
                <span>{t("riyal")}</span>
              </TransactionAmount>
            </TransactionItem>
          ))}
        </TransactionsContainer>
      )}
      <ChooseBankModal
        open={openModal}
        setOpen={setOpenModal}
        handleSubmitBank={handleSubmitBank}
        banks={banks}
        loading={submitLoading}
      />
    </WalletsContainer>
  );
};

export default WalletDetails;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings: {
    facebook: "",
    twitter: "",
    snapchat: "",
    tiktok: "",
    linkedin: "",
    instagram: "",
    phoneNumber: "",
    percentage: 30,
  },
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setSettingsData: (state, action) => {
      state.settings.facebook = action.payload.find(
        (setting) => setting.key === "facebook"
      ).value;
      state.settings.twitter = action.payload.find(
        (setting) => setting.key === "twitter"
      ).value;
      state.settings.snapchat = action.payload.find(
        (setting) => setting.key === "snapchat"
      ).value;
      state.settings.tiktok = action.payload.find(
        (setting) => setting.key === "tiktok"
      ).value;
      state.settings.instagram = action.payload.find(
        (setting) => setting.key === "instagram"
      ).value;
      state.settings.linkedin = action.payload.find(
        (setting) => setting.key === "linkedin"
      ).value;
      state.settings.percentage = action.payload.find(
        (setting) => setting.key === "percentage"
      ).value;
      state.settings.phoneNumber = action.payload.find(
        (setting) => setting.key === "phone_number"
      ).value;
    },
  },
});
export const { setSettingsData } = settingSlice.actions;

export const { reducer: settingReducer } = settingSlice;

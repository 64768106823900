import AppFooter from "components/global/AppFooter/AppFooter";
import AppHeader from "components/global/AppHeader";
import { useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import { setIsCertified } from "store/slices/auth";
import { setSettingsData } from "store/slices/settings";

const MainLayout = ({ children }) => {
  const isCertified = useAppSelector((state) => state.auth.isCertified);
  let interval = useRef<any>();
  const { t, i18n } = useTranslation();
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && !isCertified) {
      interval.current = setInterval(() => {
        fetchUserInfo();
      }, 60000);
    }
    fetchSettings();
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await trainerAPI.getSettings();
      if (response.data.metaData.key === "success") {
        dispatch(setSettingsData(response.data.data));
      }
    } catch (error) {}
  };
  const fetchUserInfo = async () => {
    try {
      const response = await trainerAPI.getUserInfo();

      if (response.data.metaData.key === "success") {
        if (response.data.data.is_certified) {
          toast.success(t("activated_successfully"));
          dispatch(setIsCertified());
        }
      }
    } catch (error) {}
  };
  return (
    <div
      className='main-container'
      style={{ direction: getLanguage() === "ar" ? "rtl" : "ltr" }}
    >
      <AppHeader />
      {children}
      <AppFooter />
    </div>
  );
};

export default MainLayout;

import { Apple, Google } from "components/Icons";
import { useTranslation } from "react-i18next";
import { persistor, useAppSelector } from "store";
import {
  IconContainer,
  LanguageButton,
  LanguageSwitcher,
  LogoutButton,
  MenuLink,
  MobileMenuContainer,
  MobileSidebarWrapper,
  SocialContainer,
} from "./appheader.styles";
import { useDispatch } from "react-redux";
import { setDirection, setLocale } from "store/slices/locale";

const MobileMenu = ({ open }) => {
  const { t, i18n } = useTranslation();
  const isCertified = useAppSelector((state) => state.auth.isCertified);
  const isAuthenticated = useAppSelector((state) => state.auth.authenticated);

  const dispatch = useDispatch();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const handleLogoutClick = () => {
    persistor.purge();
    localStorage.removeItem("persist:root");
    window.location.replace("/");
  };

  const handleLanguageToggle = () => {
    let language = getLanguage() === "ar" ? "en" : "ar";
    dispatch(setLocale());
    dispatch(setDirection());

    i18n.changeLanguage(language);
  };

  return (
    <MobileSidebarWrapper
      open={open}
      className={getLanguage() === "ar" ? "rtl" : "ltr"}
    >
      <MobileMenuContainer>
        {isCertified && (
          <MenuLink to='/dashboard'>{t("menu.dashboard")}</MenuLink>
        )}
        <MenuLink to='/terms'>{t("menu.terms")}</MenuLink>

        <MenuLink to='/policy'>{t("menu.policy")}</MenuLink>
        <MenuLink to='/about-us'>{t("menu.about_us")}</MenuLink>
      </MobileMenuContainer>
      {isAuthenticated && (
        <LogoutButton onClick={() => handleLogoutClick()}>
          <span>{t("logout")}</span>
        </LogoutButton>
      )}
      {/* <LanguageSwitcher>
        <IconContainer>English</IconContainer>
      </LanguageSwitcher> */}
      <IconContainer className='mobile'>
        <LanguageButton onClick={() => handleLanguageToggle()}>
          {getLanguage() === "ar" ? "English" : "عربي"}
        </LanguageButton>
      </IconContainer>
      <SocialContainer>
        <IconContainer>
          <a
            href='https://play.google.com/store/apps/details?id=com.alemnie.alemnie'
            target='_blank'
            rel='noreferrer'
          >
            <Google width='16px' />
          </a>
        </IconContainer>
        <IconContainer>
          <a href='https://apps.apple.com/us/app/alemni-%D8%B9%D9%84%D9%85%D9%86%D9%8A/id1627030538' target='_blank' rel='noreferrer'>
            <Apple width='16px' />
          </a>
        </IconContainer>
      </SocialContainer>
    </MobileSidebarWrapper>
  );
};

export default MobileMenu;

import { TextArea, TextAreaContainer } from "./apptext.styles";
import { useTranslation } from "react-i18next";
import {
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface inputProps {
  register: UseFormRegister<FieldValues>;
  errors?: FieldErrorsImpl<DeepRequired<FieldValues>>;
  type?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string | null;
  [x: string]: any;
}

const AppText = ({
  register,
  errors,
  name,
  placeholder,
  required = true,
  defaultValue = null,
  ...rest
}: inputProps) => {
  const { t } = useTranslation();

  return (
    <TextAreaContainer {...rest}>
      <TextArea
        {...register(name, {
          required: required ? `${t(name)} ${t("required")}` : false,
          value: defaultValue ? defaultValue : null,
        })}
        placeholder={placeholder}
        rows={3}
      ></TextArea>

      <ErrorMessage
        errors={errors}
        name={name}
        as='p'
        className='error-message'
      />
    </TextAreaContainer>
  );
};

export default AppText;

import ForgetImage from "assets/images/forget-password.png";
import AppButton from "components/shared/AppButton";
import PhoneInput from "components/shared/PhoneInput";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";
import {
  AuthSubTitle,
  BackButton,
  BackLink,
  ForgetPasswordImage,
  ForgetPasswordTitleContainer,
  FormContainer,
  LoginFormContainer,
  LoginTitle,
} from "./auth.styles";
import { FiArrowRight } from "react-icons/fi";
import { authAPI } from "services/apis/auth";
import toast from "react-hot-toast";
import { useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import OtpInput from "react-otp-input";
import PasswordInput from "components/shared/PasswordInput";
import { AxiosError } from "axios";

const ResetForm = () => {
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  //functions
  const handleOtpChange = (e) => {
    setOtp(e);
  };

  const handleBackButtonClick = () => {
    navigate("/forget");
  };

  const onSubmit = async (data) => {
    if (otp.length < 6) {
      toast.error(t("otp_error_message"));
    }
    setSubmitLoading(true);
    let phoneNumber = queryParams.get("mobile");

    // check if phoneNumber has a + sign at the beginning
    if (!phoneNumber) {
      toast.error(t("error_message"));
      return;
    }
    try {
      const payload = {
        code: otp,
        mobile: phoneNumber,
        ...data,
      };
      const response = await authAPI.resetPassword(payload);
      if (response.data.metaData.key === "success") {
        toast.success(t("password_changed_successfully"));
        navigate("/login");
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.metaData.message);
      } else {
        toast.error(t("error_message"));
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <LoginFormContainer>
      <ForgetPasswordTitleContainer>
        <BackButton
          onClick={() => {
            handleBackButtonClick();
          }}
        >
          <IconContext.Provider value={{ color: "#5f68bf", size: "1em" }}>
            <FiArrowRight />
          </IconContext.Provider>
        </BackButton>
        <LoginTitle>{t("auth.resend_title")}</LoginTitle>
      </ForgetPasswordTitleContainer>
      <AuthSubTitle>{t("auth.resend_subtitle")}</AuthSubTitle>
      <ForgetPasswordImage src={ForgetImage} alt='forget password' />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <OtpInput
          value={otp}
          onChange={(e) => handleOtpChange(e)}
          numInputs={6}
          separator={<span> </span>}
          containerStyle='otp-container'
          inputStyle='otp-input'
        />
        {/* <PhoneInput register={register} errors={errors} /> */}
        <PasswordInput register={register} errors={errors} />

        <AppButton size='lg' mt='40px' fontSize={18} loader={submitLoading}>
          {t("save")}
        </AppButton>
      </FormContainer>
    </LoginFormContainer>
  );
};

export default ResetForm;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locale: localStorage.getItem("i18nextLng") || "ar",
  isLtr: localStorage.getItem("i18nextLng") === "ar" ? false : true,
};

export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale: (state) => {
      state.locale = state.locale === "ar" ? "en" : "ar";
    },
    setDirection: (state) => {
      state.isLtr = state.isLtr ? false : true;
    },
  },
});
export const { setLocale, setDirection } = localeSlice.actions;

export const { reducer: localeReducer } = localeSlice;

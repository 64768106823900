import AddPackage from "components/Dashboard/TrainerPackages/AddPackage";
import DashboardLayout from "layouts/DashboardLayout";

const AddPackageContainer = () => {
  return (
    <DashboardLayout>
      <AddPackage />
    </DashboardLayout>
  );
};

export default AddPackageContainer;

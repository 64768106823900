import { StyledLink } from "./applink.styles";

interface linkProps {
  to: string;
  text: string;
  type?: "primary" | "outline";
  [x: string]: any;
}
const AppLink = ({ to, text, type = "primary", ...rest }: linkProps) => {
  return (
    <StyledLink type={type} to={to} {...rest}>
      {text}
    </StyledLink>
  );
};

export default AppLink;

import { AuthLeftSection } from "components/Auth/auth.styles";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const AuthLayout = ({ children }) => {
  const { t, i18n } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  return (
    <div
      className='auth-container'
      style={{ direction: getLanguage() === "ar" ? "rtl" : "ltr" }}
    >
      {children}
      <AuthLeftSection />
    </div>
  );
};

export default AuthLayout;

import { EmptyFreeLessons } from "components/Icons";
import AppButton from "components/shared/AppButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  EmptyCoursesContainer,
  EmptyCourseSubTitle,
  EmptyCourseTitle,
} from "../Courses/courses.styles";

const EmptyNotifications = () => {
  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  //functions
  const handleHomeClick = () => {
    navigate("/dashboard/");
  };
  return (
    <EmptyCoursesContainer>
      <EmptyFreeLessons />
      <EmptyCourseTitle>{t("empty_notification")}</EmptyCourseTitle>
      <EmptyCourseSubTitle>
        {t("empty_notification_subtitle")}
      </EmptyCourseSubTitle>
      <AppButton onClick={() => handleHomeClick()} mt='35px'>
        {t("home")}
      </AppButton>
    </EmptyCoursesContainer>
  );
};

export default EmptyNotifications;

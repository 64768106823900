import { css } from "styled-components";
import {media} from './utils';
// import "assets/fonts/4_F5.ttf";
// import RbFont from "assets/fonts/4_F5.ttf";

export default css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html,
  body {
    font-family:var(--font);
    font-size:1.6rem;
    line-height:1.4;
    overflow-x: hidden;
    color: rgb(var(--text-main));

  }

  .main-container{
    padding:24px 147px 600px;;
    width:100%;
    min-height:100vh;
    position: relative;

    ${media("large")} {
      padding:24px 22px 550px;
    }
    ${media("medium")} {
      padding:24px 12px 650px;
  }
  }

  .auth-container{
    width:100%;
    min-height:100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    ${media("medium")} {
      grid-template-columns:1fr;
      place-items:center ;
      padding:20px ;
  }
  }
  .dashboard-container{
    width:100%;
    min-height:100vh;
    display: flex;
    flex-wrap: wrap;
  }

  //form
  .error-message{
    color:red;
    text-align:right ;
    font-size:1.1rem;
  }

  //modal
  .app-modal-close {
    left: 33px;
    top: 18px;
    width: 32px;
  height: 32px;
  padding: 5px 6px 6px 5px;
  background-color: #fef8fa;
    max-width: 25px;
    max-height: 25px;
    justify-content: center;
    align-items: center;
    margin-right:auto;
  }

  .app-modal-overlay {
    /* backdrop-filter: blur(10.9px); */
    opacity:.7;
  background-color: #231f20;
  }

  .app-modal {
    padding: 0 !important;

    direction:rtl;
  }

  @media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    direction: rtl;
  }
  div{
    height: 100% !important;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.PhoneInputCountrySelectArrow {
  margin-right:4px;
}
`;

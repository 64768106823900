import {
  ForgetPassword,
  FormContainer,
  LoginFormContainer,
  LoginTitle,
  NewAccountLink,
  NoAccount,
  NoAccountTitle,
  SocialLoginContainer,
  TermsAccept,
  TermsContainer,
  TermsLink,
  TermsText,
} from "./auth.styles";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "components/shared/PhoneInput";
import PasswordInput from "components/shared/PasswordInput";
import { useForm } from "react-hook-form";
import AppButton from "components/shared/AppButton";
import AppInput from "components/shared/AppInput";
import AppChips from "components/shared/AppChips";
import { useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { IRegisterForm } from "types";
import { authAPI } from "services/apis/auth";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import "react-phone-number-input/style.css";
import { GoogleIcon } from "components/Icons";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { setSocialLogin } from "store/slices/auth";

const chipsOptions = [
  { name: "male", value: "male" },
  { name: "female", value: "female" },
];

const RegisterForm = ({ setShowOtpForm, setMobileNumber }) => {
  //hooks
  const { t } = useTranslation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [selectedGender, setSelectedGender] = useState("male");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      handleGoogleLogin(tokenResponse.access_token);
    },
  });
  //functions
  const handleSexChoice = (value: string) => {
    setSelectedGender(value);
  };

  const handleGoogleLogin = async (token) => {
    const response = await authAPI.register({
      user_type: "trainer",
      provider: "google",
      provider_token: token,
    });

    if (response.status === 200) {
      dispatch(setSocialLogin(response.data.data));
      navigate("/account-setup");
    } else {
      toast.error(t("error_message"));
    }
  };

  const onSubmit = async (data) => {
    setSubmitLoading(true);
    let formData: IRegisterForm = {
      ...data,
      gender: selectedGender,
      fcm_token: "123456",
      user_type: "trainer",
      name: `${data.first_name} ${data.middle_name} ${data.last_name}`,
    };

    try {
      const response = await authAPI.register(formData);

      setMobileNumber(data.mobile);
      setShowOtpForm(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        toast.error(error.response?.data.metaData.message);
      } else {
        toast.error(t("error_message"));
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <LoginFormContainer>
      <LoginTitle>{t("auth.register_title")}</LoginTitle>
      <NoAccount>
        <NoAccountTitle>{t("auth.have_account")}</NoAccountTitle>
        <NewAccountLink>
          <Link to='/login'>{t("auth.login_title")}</Link>
        </NewAccountLink>
      </NoAccount>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <AppInput register={register} name='first_name' errors={errors} />
        <AppInput register={register} name='middle_name' errors={errors} />
        <AppInput register={register} name='last_name' errors={errors} />

        <AppInput
          register={register}
          name='email'
          errors={errors}
          type='email'
        />
        <PhoneInputWithCountry
          name='mobile'
          international
          control={control}
          rules={{ required: true }}
          countryCallingCodeEditable={false}
          defaultCountry='SA'
        />

        <AppChips
          options={chipsOptions}
          mb='20px'
          handleChoice={(value) => handleSexChoice(value)}
          selectedChoice={selectedGender}
        />
        <PasswordInput register={register} errors={errors} />

        <TermsContainer>
          <TermsAccept
            type='checkbox'
            {...register("terms", {
              required: "يرجى الموافقة على الشروط والأحكام",
            })}
          />
          <TermsText>
            {t("auth.terms_text")}
            <TermsLink href='/terms' target='_blank'>
              {t("menu.terms")}
            </TermsLink>
          </TermsText>
          <ErrorMessage
            errors={errors}
            name='terms'
            as='p'
            className='error-message'
          />
        </TermsContainer>
        <AppButton size='lg' my='40px' fontSize={18} loader={submitLoading}>
          {t("auth.register_title")}
        </AppButton>
      </FormContainer>
      <hr />
      <SocialLoginContainer>
        <h1>{t("social_sign_in_title")}</h1>
        <button onClick={() => login()}>
          <GoogleIcon />
        </button>
      </SocialLoginContainer>
    </LoginFormContainer>
  );
};

export default RegisterForm;

import Echo from "laravel-echo";
import store from "store";
import Pusher from "pusher-js";
window["Pusher"] = Pusher;
const authItem = localStorage.getItem("persist:root") || "";
let echoConfig = {};
const token = JSON.parse(JSON.parse(authItem)?.auth)?.token;

echoConfig = {
  broadcaster: "pusher",

  key: "7e8e5d2792856ce56a17",
  cluster: "ap2",
  forceTLS: true,
  // authEndpoint: "http://127.0.0.1:8000/" + "broadcasting/auth",
  authEndpoint:
    `${process.env.REACT_APP_BACKEND_DOMAIN}broadcasting/auth` ||
    "https://admin.alemni.com.sa/broadcasting/auth",
  auth: {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  },
};

export const echo = new Echo(echoConfig);

import Invoice from "components/Dashboard/Invoice";
import DashboardLayout from "layouts/DashboardLayout";

const InvoiceContainer = () => {
  return (
    <DashboardLayout>
      <Invoice />
    </DashboardLayout>
  );
};

export default InvoiceContainer;

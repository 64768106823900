import AddFreeLesson from "components/Dashboard/FreeLessons/AddFreeLesson";
import DashboardLayout from "layouts/DashboardLayout";

const AddLessonContainer = () => {
  return (
    <DashboardLayout>
      <AddFreeLesson />
    </DashboardLayout>
  );
};

export default AddLessonContainer;

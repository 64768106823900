import styled from "styled-components/macro";
import { media } from "styles/utils";

export const EducationContainer = styled.div``;

export const Title = styled.h2`
  font-size: 2.7rem;
  font-weight: bold;
`;
export const SubTitle = styled.h2`
  font-size: 1.4rem;
`;
export const Hint = styled.span`
  font-size: 1.1rem;
`;

//form

export const FormContainer = styled.form`
  width: 100%;
  margin-top: 30px;

  .app-select {
    width: 100%;
    margin-top: 50px;
  }
`;

export const FormControl = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const TeachingTypesContainer = styled.section`
  display: flex;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
`;
export const RemotelyContainer = styled.div<{
  active?: boolean;
  isLtr?: boolean;
}>`
  width: 350px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 12px;
  ${media("medium")} {
    flex-direction: column;
  }
  background: ${(props) =>
    props.active ? "rgba(var( --main-color-lighter),0.08)" : "#fff"};
  color: ${(props) =>
    props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  border: 1px solid
    ${(props) =>
      props.active ? "rgb(var(--main-color-light))" : "rgba(0, 0, 0, 0.12)"};
  p.title {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${(props) =>
      props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  }
  p.subtitle {
    font-size: 1.1rem;
    margin-top: 6px;
    color: ${(props) =>
      props.active ? "rgb(var(--purple))" : "rgb(var(--dark-grey))"};
  }

  div.input-container {
    display: ${(props) => (props.active ? "block" : "none")};
    max-width: 150px;
    position: relative;
    ${media("medium")} {
      margin-top: 10px;
    }
    input {
      border: solid 1px rgb(var(--blue-light));
      width: 100%;
      padding-right: 10px;
      height: 43px;
      &:focus-visible {
        outline: 2px solid rgb(var(--main-color-light));
      }
    }
    span {
      position: absolute;
      font-size: 11px;
      color: rgb(var(--border-dark));
      left: ${({ isLtr }) => (isLtr ? "unset" : "25px")};
      right: ${({ isLtr }) => (isLtr ? "25px" : "unset")};
      height: 100%;
      padding-top: 13px;
      padding-right: 10px;
      border-right: 1px solid rgb(var(--beige));
    }
  }
`;
export const OnsiteContainer = styled.div<{
  active?: boolean;
  isLtr?: boolean;
}>`
  width: 350px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 12px;
  ${media("medium")} {
    flex-direction: column;
  }
  background: ${(props) =>
    props.active ? "rgba(var( --main-color-lighter),0.08)" : "#fff"};
  color: ${(props) =>
    props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  border: 1px solid
    ${(props) =>
      props.active ? "rgb(var(--main-color-light))" : "rgba(0, 0, 0, 0.12)"};

  p.title {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${(props) =>
      props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  }
  p.subtitle {
    font-size: 1.1rem;
    margin-top: 6px;
    color: ${(props) =>
      props.active ? "rgb(var(--purple))" : "rgb(var(--dark-grey))"};
  }

  div.input-container {
    display: ${(props) => (props.active ? "block" : "none")};
    max-width: 150px;
    position: relative;
    ${media("medium")} {
      margin-top: 10px;
    }
    input {
      border: solid 1px rgb(var(--blue-light));
      width: 100%;
      height: 43px;
      padding-right: 10px;

      &:focus-visible {
        outline: 2px solid rgb(var(--main-color-light));
      }
    }
    span {
      position: absolute;
      font-size: 11px;
      color: rgb(var(--border-dark));
      left: ${({ isLtr }) => (isLtr ? "unset" : "25px")};
      right: ${({ isLtr }) => (isLtr ? "25px" : "unset")};
      height: 100%;
      padding-top: 13px;
      padding-right: 10px;
      border-right: 1px solid rgb(var(--beige));
    }
  }
`;

export const Label = styled.span`
  margin-bottom: 13px;
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  margin-top: 24px;
`;

export const ActionButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    gap: 10px;
    ${media("medium")} {
      margin-bottom: 13px;
    }
  }
  ${media("medium")} {
    flex-direction: column;
  }
`;

import styled from "styled-components/macro";
import { space, fontSize } from "styled-system";

export const TextAreaContainer = styled.div`
  ${space};
  ${fontSize};
`;
export const TextArea = styled.textarea`
  width: 100%;
  padding: 20px 14px;

  resize: vertical;
  &:focus-visible {
    outline: none;
    border: 2px solid rgb(var(--main-color-lighter));
  }
`;

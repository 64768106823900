import styled from "styled-components/macro";

export const EducationContainer = styled.div``;

export const Title = styled.h2`
  font-size: 2.7rem;
  font-weight: bold;
`;
export const SubTitle = styled.h2`
  font-size: 1.4rem;
`;

//form

export const FormContainer = styled.form`
  width: 100%;
  margin-top: 30px;
`;

export const BookingTypesContainer = styled.section`
  display: flex;
  gap: 30px;
  width: 100%;
  flex-wrap: wrap;
`;
export const BookingType = styled.div<{
  active?: boolean;
}>`
  width: 350px;
  flex: 1;
  cursor: pointer;
  padding: 12px;
  background: ${(props) =>
    props.active ? "rgba(var( --main-color-lighter),0.08)" : "#fff"};
  color: ${(props) =>
    props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  border: 1px solid
    ${(props) =>
      props.active ? "rgb(var(--main-color-light))" : "rgba(0, 0, 0, 0.12)"};
  p.title {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${(props) =>
      props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  }
  p.subtitle {
    font-size: 1.1rem;
    margin-top: 6px;
    color: ${(props) =>
      props.active ? "rgb(var(--purple))" : "rgb(var(--dark-grey))"};
  }
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 30px;
`;
export const Day = styled.div<{
  active?: boolean;
}>`
  background: ${(props) =>
    props.active ? "rgba(var( --main-color-lighter),0.08)" : "#fff"};
  border: 1px solid
    ${(props) => (props.active ? "rgb(var(--main-color-light))" : "#f9f3f5")};
  color: ${(props) =>
    props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  padding: 12px;
  width: 154px;
  /* height: 80px; */
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  span {
    color: rgb(var(--purple));
    font-size: 1.4rem;
    font-weight: bold;
  }
  button {
    color: rgb(var(--purple));
    border: none;
    background: transparent;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.1rem;
  }
  p {
    font-size: 1.1rem;
    margin-top: 16px;
    &.time-slot {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
`;

export const Label = styled.span`
  margin-bottom: 13px;
  font-size: 1.4rem;
  display: block;
  font-weight: bold;
  margin-top: 24px;
`;

export const ActionButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    gap: 10px;
  }
`;

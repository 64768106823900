import {
  Facebook,
  Linkedin,
  Logo,
  Twitter,
  TikTok,
  Instagram,
  Snapchat,
} from "components/Icons";
import { useTranslation } from "react-i18next";
import {
  FooterContainer,
  FooterSections,
  FooterSection,
  InfoLabel,
  InfoValue,
  InfoItem,
  FooterBrand,
  SocialContainer,
  SocialItem,
  PaymentIcons,
  PaymentIcon,
} from "./appfooter.styles";
import VatImage from "assets/images/payments/vat.jpg";
import Mada from "assets/images/payments/mada.png";
import Stc from "assets/images/payments/stc-pay.png";
import ApplePay from "assets/images/payments/apple-pay.png";
import AExpress from "assets/images/payments/aexpress.png";
import Visa from "assets/images/payments/visa.png";
import { useAppSelector } from "store";
const AppFooter = () => {
  const { t } = useTranslation();
  const settings = useAppSelector((state) => state.setting.settings);
  return (
    <FooterContainer>
      <FooterSections>
        <FooterSection>
          <InfoItem>
            <InfoLabel>{t("email")}:</InfoLabel>
            <InfoValue>info@alemni.com.sa</InfoValue>
          </InfoItem>
        </FooterSection>
        <FooterSection>
          <InfoItem>
            <InfoLabel>{t("tax_number")}:</InfoLabel>
            <InfoValue>311137151100003</InfoValue>
            <PaymentIcon src={VatImage} alt='var' />
          </InfoItem>
        </FooterSection>
        <FooterSection>
          <InfoItem>
            <InfoLabel>{t("follow_us")}</InfoLabel>
            <SocialContainer>
              <SocialItem
                href={
                  settings["facebook"] ||
                  "https://www.facebook.com/profile.php?id=100083551600023&mibextid=ZbWKwL"
                }
                target='_blank'
              >
                <Facebook />
              </SocialItem>
              <SocialItem
                href={settings["twitter"] || "https://twitter.com/Alemniapp"}
                target='_blank'
              >
                <Twitter />
              </SocialItem>
              <SocialItem
                href={
                  settings["linkedin"] ||
                  "https://www.linkedin.com/in/%D8%B9%D9%84%D9%85%D9%86%D9%8A-%D8%A7%D9%84%D8%B1%D8%A7%D8%A6%D8%AF%D8%A9-%D9%84%D8%AA%D9%82%D9%86%D9%8A%D8%A9-%D8%A7%D9%84%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA-2b3343254"
                }
                target='_blank'
              >
                <Linkedin />
              </SocialItem>
              <SocialItem
                href={
                  settings["instagram"] ||
                  "https://instagram.com/alemni.sa?igshid=ZDdkNTZiNTM="
                }
                target='_blank'
              >
                <Instagram width='20px' />
              </SocialItem>
              <SocialItem
                href={
                  settings["tiktok"] ||
                  "https://www.tiktok.com/@alemni.sa?_t=8Xg4zuETt3q&_r=1"
                }
                target='_blank'
              >
                <TikTok width='20px' />
              </SocialItem>

              <SocialItem
                href={
                  settings["snapchat"] ||
                  "https://www.snapchat.com/add/alemnisa?share_id=xCPW9H-Qbns&locale=ar-AE"
                }
                target='_blank'
              >
                <Snapchat width='20px' />
              </SocialItem>
            </SocialContainer>
          </InfoItem>
        </FooterSection>
      </FooterSections>
      <hr />
      <PaymentIcons>
        <PaymentIcon src={Mada} alt='Mada' />
        <PaymentIcon src={AExpress} alt='AExpress' />
        <PaymentIcon src={Visa} alt='Visa' />
        <PaymentIcon src={Stc} alt='Stc' />
        <PaymentIcon src={ApplePay} alt='ApplePay' />
      </PaymentIcons>
      <FooterBrand>{t("footer_brand")}</FooterBrand>
    </FooterContainer>
  );
};

export default AppFooter;

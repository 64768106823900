import DashboardLayout from "layouts/DashboardLayout";
import Notifications from "components/Dashboard/Notifications";
const NotificationsContainer = () => {
  return (
    <DashboardLayout>
      <Notifications />
    </DashboardLayout>
  );
};

export default NotificationsContainer;

import { DashboardAvatar, Filter } from "components/Icons";
import AppButton from "components/shared/AppButton";
import AppLabel from "components/shared/AppLabel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import format from "date-fns/format";

import {
  AppointmentDate,
  AppointmentsHeader,
  AppointmentsItem,
  AppointmentsItems,
  AppointmentsWrapper,
  AppointmentType,
  FiltersContainer,
  Image,
  Info,
  StudentName,
  Time,
  Title,
} from "./appointments.styles";
import EmptyAppointments from "./EmptyAppointments";
import SingleAppointment from "./SingleAppointment";
import { CourseImage } from "../Courses/courses.styles";
import { LessonThumbnail } from "../FreeLessons/freeLessons.styles";
import FreeLessonImage from "assets/images/free_lesson.png";
import arLocale from "date-fns/locale/ar-SA";
import enLocale from "date-fns/locale/en-US";

const Appointments = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const isLtr = useAppSelector((state) => state.locale.isLtr);

  const [selectedAppointment, setSelectedAppointment] = useState<any>({});
  const [showSingleAppointment, setShowSingleAppointment] = useState(false);
  const [bookings, setBookings] = useState<any[]>([]);

  useEffect(() => {
    fetchBookings();
  }, []);

  //functions

  const handleSelectedAppointment = (booking) => {
    setSelectedAppointment(booking);
    setShowSingleAppointment(true);
  };

  const fetchBookings = async () => {
    try {
      const response = await trainerAPI.getBookings();
      if (response.data.metaData.key === "success") {
        const bookings = response.data.data.data;
        const reducedBookings = bookings.reduce((acc, current) => {
          if (
            current.session_type !== "free_lesson" &&
            current.session_type !== "workshop"
          ) {
            return acc.concat([current]);
          }

          const x = acc.find(
            (item) =>
              item.event?.id === current.event?.id &&
              item.trainer.id === current.trainer.id &&
              item.session_type === current.session_type
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setBookings(reducedBookings);
      }
    } catch (error) {}
  };

  return (
    <AppointmentsWrapper>
      {bookings.length > 0 ? (
        !showSingleAppointment ? (
          <>
            <AppointmentsHeader>
              <Title>{t("appointments")}</Title>
              {/* <FiltersContainer>
              <span>{t("filter")}</span>
              <Filter />
            </FiltersContainer> */}
            </AppointmentsHeader>
            <AppointmentsItems>
              {bookings.map((booking) => (
                <AppointmentsItem
                  key={booking.id}
                  onClick={() => handleSelectedAppointment(booking)}
                >
                  {booking.session_type === "single" ? (
                    <DashboardAvatar width='77px' height='77px' />
                  ) : booking.session_type === "free_lesson" ? (
                    <img
                      src={FreeLessonImage}
                      alt='free lesson'
                      width='77px'
                      height='77px'
                    />
                  ) : booking.session_type === "workshop" ? (
                    <CourseImage
                      src={booking?.event?.cover}
                      width='77px'
                      height='77px'
                    />
                  ) : null}

                  <Info isLtr={isLtr}>
                    <StudentName>
                      {["single", "package", "custom"].includes(
                        booking.session_type
                      )
                        ? booking.trainee.name
                        : booking.session_type === "workshop"
                        ? booking?.event.title
                        : "درس مجاني"}
                    </StudentName>
                    <AppointmentDate>
                      <span>
                        {format(
                          new Date(booking.upcoming_session),
                          "EEEE dd MMMM yyyy",
                          {
                            locale: isLtr ? enLocale : arLocale,
                          }
                        )}
                      </span>
                      &nbsp; - &nbsp;
                      <Time>
                        {format(new Date(booking.upcoming_session), "HH:mm")}
                      </Time>
                    </AppointmentDate>
                    <AppointmentType>{t(booking.attendance)}</AppointmentType>
                  </Info>
                  <AppLabel
                    variation={booking.status}
                    text={t(booking.status)}
                  />
                </AppointmentsItem>
              ))}
            </AppointmentsItems>
            {/* <AppButton size='lg'>{t("more")}</AppButton> */}
          </>
        ) : (
          <SingleAppointment
            booking={selectedAppointment}
            setShowBooking={setShowSingleAppointment}
          />
        )
      ) : (
        <EmptyAppointments />
      )}
    </AppointmentsWrapper>
  );
};

export default Appointments;

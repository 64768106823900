import { SendMessage } from "components/Icons";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { trainerAPI } from "services/apis/trainer";
import { echo } from "services/lib/echo";
import { useAppSelector } from "store";
import { MessagesHeader, Title } from "./messages.styles";
import {
  ChatContainer,
  ChatContent,
  ChatDate,
  ChatItem,
  MessageInput,
  SendButton,
  SendMessageContainer,
  SingleMessageWrapper,
} from "./singleMessage.styles";
import format from "date-fns/format";

const SingleMessage = () => {
  //hooks
  const { t } = useTranslation();
  const params = useParams();
  const [messages, setMessages] = useState<any>([]);
  const [pagination, setPagination] = useState<any>({});
  const [messageContent, setMessageContent] = useState("");
  const user = useAppSelector((state) => state.auth.user);
  const bottomRef = useRef<any>(null);
  const inputRef = useRef<any>("");

  useEffect(() => {
    fetchSingleSession(1);
  }, []);

  useEffect(() => {
    echo.private(`Chat.${params.id}`).notification((message) => {
      if (message) {
        console.log(
          "🚀 ~ file: SingleMessage.tsx:39 ~ echo.private ~ message:",
          message
        );
        setMessages((prevMessages) => {
          const newMessages = [
            ...prevMessages,
            {
              content: message.data.content,
              created_at: message.data.created_at,
              id: message.data.id,
              user: message.data.receiver,
            },
          ];
          // Sort the messages by their 'created_at' property
          const sorted = newMessages.sort((a, b) => {
            return (
              (new Date(a?.created_at) as any) -
              (new Date(b?.created_at) as any)
            );
          });
          return sorted;
        });
      }
    });

    return () => echo.leave(`chat.${params.id}`);
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.target;
    if (scrollTop === 0) {
      if (pagination.current_page === pagination.total_pages) return;

      fetchSingleSession(pagination.current_page + 1); // call the API to get the next page of chat data
    }
  };

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    window.scroll({
      top: bottomRef.current?.scrollHeight,
      left: 0,
    });
    bottomRef.current?.scroll({
      top: bottomRef.current?.scrollHeight,
      left: 0,
    });
    // bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  //functions
  const handleInputChange = (e) => {
    inputRef.current.value = e.target.value;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
  };
  //Apis
  const fetchSingleSession = async (page = 1) => {
    try {
      const response = await trainerAPI.getSingleSession(params.id, page);
      if (
        response.data.metaData.key === "success" &&
        response.data.data.data.length > 0
      ) {
        // Update the state with the sorted messages and pagination
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages, ...response.data.data.data];
          // Sort the messages by their 'created_at' property
          const sorted = newMessages.sort((a, b) => {
            return (
              (new Date(a?.created_at) as any) -
              (new Date(b?.created_at) as any)
            );
          });
          return sorted;
        });
        setPagination(response.data.data.pagination);
      }

      // setMessages([...messages, ...response.data.data.data]);
    } catch (error) {}
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!inputRef.current.value?.trim()?.length) return;

    let receiverId = messages.find(
      (message) => message?.sender?.id !== user.user_id
    );

    try {
      let payload = {
        user_id: receiverId?.sender?.id,
        content: inputRef.current.value,
      };
      inputRef.current.value = "";

      window.scroll({
        top: bottomRef.current?.scrollHeight,
        left: 0,
      });
      const response = await trainerAPI.sendMessage(payload);
      if (response.data.metaData.key === "success") {
        window.scroll({
          top: bottomRef.current?.scrollHeight,
          left: 0,
        });
      }
    } catch (error) {}
  };
  return (
    <SingleMessageWrapper>
      <MessagesHeader>
        <Title>{t("chatting")}</Title>
      </MessagesHeader>

      <ChatContainer ref={bottomRef} onScroll={handleScroll}>
        {React.Children.toArray(
          messages?.length > 0 &&
            messages.map((chat) => (
              <ChatItem sender={chat.user.user_id === user.user_id}>
                <ChatContent sender={chat.user.user_id === user.user_id}>
                  {chat.content}
                </ChatContent>
                <ChatDate sender={chat.user.user_id === user.user_id}>
                  {" "}
                  {format(new Date(chat.created_at), "dd MM yyyy HH:mm")}
                </ChatDate>
              </ChatItem>
            ))
        )}
      </ChatContainer>
      <SendMessageContainer>
        <MessageInput
          type='text'
          placeholder={t("message_text")}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
        />
        <SendButton onClick={sendMessage}>
          <SendMessage />
        </SendButton>
      </SendMessageContainer>
    </SingleMessageWrapper>
  );
};

export default SingleMessage;

import MessagesList from "components/Dashboard/Messages/MessagesList";
import SingleMessage from "components/Dashboard/Messages/SingleMessage";
import DashboardLayout from "layouts/DashboardLayout";

const MessagesContainer = () => {
  return (
    <DashboardLayout>
      <MessagesList />
      {/* <SingleMessage /> */}
    </DashboardLayout>
  );
};

export default MessagesContainer;

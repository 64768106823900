import { CheckoutWrapper, FormContainer, Title } from "./checkoutForm.styles";
import { useEffect, useRef, useState } from "react";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCheckout } from "store/slices/checkout";

const CheckoutForm = () => {
  //state hooks
  const [checkoutId, setCheckoutId] = useState(null);
  const selectedPackage = useAppSelector(
    (state) => state.checkout.selectedPackage
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("mada");
  const formRef = useRef<any>();

  //redux hooks
  const dispatch = useDispatch();
  //library hooks
  const { t } = useTranslation();

  //effects hooks
  useEffect(() => {
    prepareCheckout();
  }, []);

  //api

  useEffect(() => {
    if (checkoutId) {
      const script = document.createElement("script");
      // script.src = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;

      script.src = `https://oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      script.async = true;

      document.body.appendChild(script);

      const form = document.createElement("form");
      form.action = `/dashboard/invoice`;
      form.setAttribute("class", "paymentWidgets");
      form.setAttribute(
        "data-brands",
        "MADA VISA MASTER APPLEPAY AMEX STC_PAY"
      );
      formRef.current && formRef.current.appendChild(form);
    }
  }, [checkoutId]);

  const prepareCheckout = async () => {
    try {
      let payload = {
        // entityID: "8ac7a4ca81c2bf220181c2c5f36704f5",
        amount: selectedPackage.fee,
      };

      let response = await trainerAPI.prepareCheckout(
        selectedPackage.id,
        payload,
        selectedPaymentMethod
      );

      const { id } = response.data;
      setCheckoutId(id);
      dispatch(setCheckout(id));
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <CheckoutWrapper>
      <Title>
        {t("checkout_title")}
        {selectedPackage.title}
      </Title>

      <FormContainer>
        <div ref={formRef} id='checkout-form'>
          {/* {renderForm()} */}
        </div>
      </FormContainer>
    </CheckoutWrapper>
  );
};

export default CheckoutForm;

import styled from "styled-components/macro";
import { space, fontSize } from "styled-system";
import { media } from "styles/utils";

export const ChipsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 11px;
  ${space};
  ${fontSize};
  ${media("medium")} {
    flex-wrap: wrap;
  }
`;
export const ChipContainer = styled.div<{
  active?: boolean;
  variant: string;
  [x: string]: any;
}>`
  min-width: 101px;
  background: ${(props) =>
    props.active ? "rgba(var( --main-color-lighter),0.08)" : "#fff"};
  color: ${(props) =>
    props.active ? "rgb(var(--main-color))" : "rgb(var(--grey))"};
  border: 1px solid
    ${(props) =>
      props.active ? "rgb(var(--main-color-light))" : "rgba(0, 0, 0, 0.12)"};
  width: ${(props) => (props.variant === "full" ? "100%" : "120px")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${space};
  ${fontSize};
`;

export const ChipText = styled.span``;

import { ReactNode, useEffect, useState, useCallback } from "react";
import {
  Avatar,
  Call,
  Friends,
  Policy,
  Referral,
  Students,
  Terms,
  WalletBlue,
} from "components/Icons";
import {
  Amount,
  AmountTitle,
  BalanceContainer,
  Label,
  LessonUrlLink,
  NextBookingContainer,
  PanelContainer,
  QuickLink,
  QuickLinkItem,
  QuickLinksContainer,
  StudentInfo,
  TimeContainer,
  TimeItem,
  TransactionsLink,
} from "./panel.styles";
import { useTranslation } from "react-i18next";
import AppLabel from "components/shared/AppLabel";
import LessonUrlModal from "components/Modals/LessonUrlModal";
import AppButton from "components/shared/AppButton";
import { trainerAPI } from "services/apis/trainer";
import intervalToDuration from "date-fns/intervalToDuration";
import { useAppSelector } from "store";

const quickLinks: {
  label: string;
  link: string;
  icon: ReactNode;
}[] = [
  // {
  //   label: "invite_friends",
  //   link: "#",
  //   icon: <Friends />,
  // },
  // {
  //   label: "support",
  //   link: "#",
  //   icon: <Call />,
  // },
  // {
  //   label: "referral",
  //   link: "#",
  //   icon: <Referral />,
  // },
  {
    label: "policy",
    link: "/policy",
    icon: <Policy />,
  },
  {
    label: "terms",
    link: "/terms",
    icon: <Terms />,
  },
];

const Panel = () => {
  //hooks
  const { t } = useTranslation();
  const [openUrlModal, setOpenUrlModal] = useState(false);
  const [upcomingBooking, setUpcomingBooking] = useState<any>({});
  const [upcomingBookingDate, setUpcomingBookingDate] = useState<any>({});
  const user = useAppSelector((state) => state.auth.user);

  const [hasUpcomingBooking, setHasUpcomingBooking] = useState(false);

  useEffect(() => {
    fetchUpcomingEvent();
  }, []);

  //functions

  const fetchUpcomingEvent = useCallback(async () => {
    try {
      const response = await trainerAPI.getUpcomingBookings();
      if (response.data.metaData.key === "success") {
        setUpcomingBooking({ ...response.data.data });
        setHasUpcomingBooking(true);
        const timeDifference = intervalToDuration({
          start: new Date(response.data.data.upcoming_session),
          end: new Date(),
        });
        setUpcomingBookingDate({ ...timeDifference });
      }
    } catch (error) {
      setHasUpcomingBooking(false);
    }
  }, []);

  const handleOpenModal = () => {
    setOpenUrlModal(true);
  };

  return (
    <PanelContainer>
      <BalanceContainer>
        <WalletBlue width='48px' height='48px' />
        <Amount>
          {user.balance || 0} <span>{t("riyal")}</span>
        </Amount>
        <AmountTitle>{t("total_balance")}</AmountTitle>
        <TransactionsLink to='/dashboard/wallet'>
          {t("transactions")}{" "}
        </TransactionsLink>
      </BalanceContainer>
      <Label>{t("next_booking")}</Label>
      <NextBookingContainer>
        {hasUpcomingBooking && Object.keys(upcomingBooking).length > 0 && (
          <>
            <StudentInfo>
              <Avatar width='48px' height='48' />
              <div>
                <p>
                  {["single", "package", "custom"].includes(
                    upcomingBooking.session_type
                  )
                    ? upcomingBooking.trainee.name
                    : upcomingBooking.session_type === "workshop"
                    ? upcomingBooking?.event.title
                    : "درس مجاني"}
                </p>
                <AppLabel
                  variation={upcomingBooking.status}
                  text={t(upcomingBooking.status)}
                  mt='10px'
                />
              </div>
            </StudentInfo>
            <TimeContainer>
              <TimeItem>
                <span>
                  {upcomingBookingDate.months
                    ? upcomingBookingDate.months
                    : upcomingBookingDate.days}
                </span>
                <p>{upcomingBookingDate.months ? t("months") : t("day")}</p>
              </TimeItem>
              <TimeItem>
                <span>
                  {upcomingBookingDate.months
                    ? upcomingBookingDate.days
                    : upcomingBookingDate.hours}
                </span>
                <p>{upcomingBookingDate.months ? t("day") : t("hours")}</p>
              </TimeItem>
              <TimeItem>
                <span>
                  {upcomingBookingDate.months
                    ? upcomingBookingDate.hours
                    : upcomingBookingDate.minutes}
                </span>
                <p>{upcomingBookingDate.months ? t("hours") : t("minutes")}</p>
              </TimeItem>
            </TimeContainer>
            <AppButton
              size='lg'
              mt='30px'
              customColor='#3d45aa'
              onClick={() => handleOpenModal()}
            >
              {t("lesson_url")}
            </AppButton>
          </>
        )}
      </NextBookingContainer>
      <Label>{t("quick_linkx")}</Label>
      <QuickLinksContainer>
        {quickLinks.map((link, index) => (
          <QuickLinkItem key={index}>
            {link.icon}
            <QuickLink to={link.link}>{t(link.label)}</QuickLink>
          </QuickLinkItem>
        ))}
      </QuickLinksContainer>
      <LessonUrlModal
        open={openUrlModal}
        setOpen={setOpenUrlModal}
        lessonUrl={upcomingBooking.session_url}
        bookingId={upcomingBooking.id}
      />
    </PanelContainer>
  );
};

export default Panel;

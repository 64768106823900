import AppButton from "components/shared/AppButton";
import AppInput from "components/shared/AppInput";
import { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { trainerAPI } from "services/apis/trainer";
import {
  ActionsContainer,
  ModalBodyContainer,
  ModalTitle,
} from "./verifyNumberModal.styles";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { authAPI } from "services/apis/auth";
import OtpInput from "react-otp-input";
import AppLabel from "components/shared/AppLabel";
import { setVerified } from "store/slices/auth";
import { useAppDispatch } from "store";

const VerifyNumberModal = ({ setOpen, open }) => {
  //hooks
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");

  const [submitLoading, setSubmitLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  //functions

  const handleOtpChange = (e) => {
    setOtp(e);
  };
  const handleCancelSubmit = () => {
    setOpen(false);
  };
  const handlePhoneSubmit = async (data) => {
    try {
      setSubmitLoading(true);
      let response = await authAPI.sendCode(data);
      if (response.data.metaData.key === "success") {
        toast.success(t("send_successfully"));
        setShowOtpInput(true);
      }
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.metaData?.message
          ? (error as any)?.response?.data?.metaData?.message
          : t("error_message")
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleOtpSubmit = async (data) => {
    try {
      setSubmitLoading(true);
      let payload = {
        ...data,
        code: otp,
      };
      let response = await authAPI.verify(payload);
      if (response.data.metaData.key === "success") {
        dispatch(setVerified(response.data.data));

        setOpen(false);
        toast.success(t("verified_successfully"));
      }
    } catch (error) {
      toast.error(
        (error as any)?.response?.data?.metaData?.message
          ? (error as any)?.response?.data?.metaData?.message
          : t("error_message")
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      ref={modalRef}
      open={open}
      onClose={() => setOpen(false)}
      initialFocusRef={modalRef}
      showCloseIcon={true}
      closeOnOverlayClick={true}
      classNames={{
        root: "custom-root",
        overlay: "app-modal-overlay",
        modal: "app-modal review-app-modal",
        closeButton: "app-modal-close",
      }}
      center
    >
      <ModalBodyContainer>
        <ModalTitle>{t("verify_number")}</ModalTitle>
        <form
          onSubmit={handleSubmit(
            showOtpInput ? handleOtpSubmit : handlePhoneSubmit
          )}
        >
          <PhoneInputWithCountry
            name='mobile'
            international
            control={control}
            rules={{ required: true }}
            countryCallingCodeEditable={false}
            defaultCountry='SA'
          />
          {showOtpInput ? (
            <>
              <span>{t("verification_code")}</span>
              <OtpInput
                value={otp}
                onChange={(e) => handleOtpChange(e)}
                numInputs={6}
                separator={<span> </span>}
                containerStyle='otp-container'
                inputStyle='otp-input'
                isInputNum={true}
              />
            </>
          ) : null}
          <ActionsContainer>
            <AppButton
              size='sm'
              variant='outline'
              onClick={() => handleCancelSubmit()}
              type='button'
            >
              {t("cancel")}
            </AppButton>

            <AppButton type='submit' size='sm' loader={submitLoading}>
              {t("submit")}
            </AppButton>
          </ActionsContainer>
        </form>
      </ModalBodyContainer>
    </Modal>
  );
};

export default VerifyNumberModal;

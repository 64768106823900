import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const StepperContainer = styled.div`
  background-color: rgb(var(--off-white));
  flex: 1;
  max-width: 340px;
  padding: 36px 32px;
  ${media("medium")} {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    padding: 14px 10px;
  }
`;

export const StepperTitle = styled.h1`
  color: rgb(var(--main-color-light));
  font-size: 2.1rem;
  font-weight: bold;
  ${media("medium")} {
    font-size: 1.8rem;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
  ${media("medium")} {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    align-items: flex-start;
  }
`;

export const StepContainer = styled.div<{
  active?: boolean;
  visited?: boolean;
  isLtr?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  ${media("medium")} {
    flex-direction: column;
    justify-content: center;
  }
  &:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: -50px;
    right: ${(props) => (props.isLtr ? "20px" : "unset")};
    left: ${(props) => (props.isLtr ? "unset" : "20px")};
    height: 36px;
    width: 0;
    border-right: 1px dotted rgb(var(--main-color-lightest));

    ${media("medium")} {
      content: "";
      position: absolute;
      top: 20px;
      left: -34px;
      height: 0px;
      right: unset;
      /* background: red; */
      width: 39px;
      border-bottom: 1px dotted rgb(var(--main-color-lightest));
    }
  }
  opacity: ${(props) => (props.visited || props.active ? "1" : ".5")};
`;

export const StepNumber = styled.span<{ active?: boolean; visited?: boolean }>`
  min-width: 39px;
  min-height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  color: ${(props) =>
    props.visited
      ? "#fff"
      : props.active
      ? "rgb(var(--main-color-light))"
      : "rgba(var(--main-color-light,.1))"};
  background-color: ${(props) =>
    props.visited ? "rgb(var(--main-color))" : "transparent"};

  border-width: 1px;
  border-color: rgb(var(--main-color-light));
  border-style: ${(props) =>
    props.visited || props.active ? "solid" : "dotted"};
`;

export const StepTextContainer = styled.div`
  ${media("medium")} {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
export const StepNumberText = styled.h2`
  color: rgb(var(--main-color-lightest));
  font-size: 1.1rem;
  cursor: pointer;
  ${media("medium")} {
    font-size: 0.8rem;

    text-align: center;
  }
`;
export const StepTitle = styled.h2`
  color: rgb(var(--main-color-light));
  font-size: 1.6rem;
  cursor: pointer;

  ${media("medium")} {
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const HelpContainer = styled.div`
  margin-top: 60px;
  background-color: rgb(var(--main-color-lighter));
  padding: 14px 17px;
  display: flex;
  align-items: center;
  gap: 17px;
  ${media("medium")} {
    display: none;
  }
`;
export const HelpIcon = styled.span`
  border: solid 1px rgb(var(--off-white));
  width: 39px;
  height: 39px;
  font-size: 21px;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const HelpTitleContainer = styled.div``;
export const HelpTitle = styled.h1`
  margin: 0;
  font-size: 1.1rem;
  color: rgb(var(--blue-lighter));
`;
export const HelpSubTitle = styled(Link)`
  font-size: 1.4rem;
  margin: 8px 0 0 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
`;

import PolicyContent from "components/PagesContent/PolicyContent";
import MainLayout from "layouts/MainLayout";

const PolicyContainer = () => {
  return (
    <MainLayout>
      <PolicyContent />
    </MainLayout>
  );
};

export default PolicyContainer;

import { HomeWrapper } from "components/Home/home.styles";
import LeftSection from "components/Home/LeftSection";
import RightSection from "components/Home/RightSection";
import MainLayout from "layouts/MainLayout";

const HomeContainer = () => {
  return (
    <MainLayout>
      <HomeWrapper>
        <RightSection />
        <LeftSection />
      </HomeWrapper>
    </MainLayout>
  );
};

export default HomeContainer;

import { ReactSVG } from "react-svg";
import styled from "styled-components/macro";
import classnames from "classnames";

const iconGenerator =
  (iconSrc: any) =>
  ({
    color = "",
    height = "",
    maxHeight = "",
    maxWidth = "",
    width = "",
    id = "",
    className = "",
  }) => {
    const iconClasses = classnames({
      icon: true,
      [className]: className.length > 0,
    });
    const ReactSVGStyled = styled(ReactSVG)`
      svg {
        height: ${height || null};
        width: ${width || null};
        max-height: ${maxHeight || null};
        max-width: ${maxWidth || null};
        min-height: 5px;
        min-width: 5px;
        transition: width 0.3s linear 0s, height 0.3s linear 0s;
      }

      svg path {
        fill: ${color || ""};
      }
    `;

    return <ReactSVGStyled id={id} src={iconSrc} className={iconClasses} />;
  };

export default iconGenerator;

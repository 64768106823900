export const getTimeFromString = (timeString) => {
  if (!timeString) return;

  const [hoursStr, minutesStr] = timeString.split(":"); // split the string into hours and minutes components
  const hours = parseInt(hoursStr, 10); // convert the hours component to a number
  const minutes = parseInt(minutesStr, 10); // convert the minutes component to a number
  const now = new Date(); // create a new Date object with the current date and time
  now.setHours(hours); // set the hours component of the Date object to the parsed hours value
  now.setMinutes(minutes); // set the minutes component of the Date object to the parsed minutes value
  return now;
};

export const getTimeString = (time) => {
  const now = new Date(time);
  const hours = now.getHours().toString().padStart(2, "0"); // get hours and pad with leading zero if necessary
  const minutes = now.getMinutes().toString().padStart(2, "0"); // get minutes and pad with leading zero if necessary
  const timeString = `${hours}:${minutes}`; // combine hours and minutes into a string
  return timeString;
};

export const getMidnightTime = () => {
  const midnight = new Date();
  midnight.setHours(0);
  midnight.setMinutes(0);
  midnight.setSeconds(0);
  midnight.setMilliseconds(0);
  return midnight;
};

export const isTimeBigger = (timeOne, timeTwo) => {
  const date1 = getTimeFromString(timeOne);
  const date2 = getTimeFromString(timeTwo);

  if (date1 && date2) {
    const time1 = date1.getTime();
    const time2 = date2.getTime();

    if (time1 >= time2) {
      return true;
    } else {
      return false;
    }
  }
};

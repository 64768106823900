import { EmptyFreeLessons } from "components/Icons";
import AppButton from "components/shared/AppButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  EmptyCoursesContainer,
  EmptyCourseSubTitle,
  EmptyCourseTitle,
} from "../Courses/courses.styles";

const EmptyLessons = () => {
  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  //functions
  const handleAddLesson = () => {
    navigate("/dashboard/free-lessons/add");
  };
  return (
    <EmptyCoursesContainer>
      <EmptyFreeLessons />
      <EmptyCourseTitle>{t("free_lessons")}</EmptyCourseTitle>
      <EmptyCourseSubTitle>{t("free_lessons_subtitle")}</EmptyCourseSubTitle>
      <AppButton onClick={() => handleAddLesson()} mt='35px'>
        {t("add_free_lesson")}
      </AppButton>
    </EmptyCoursesContainer>
  );
};

export default EmptyLessons;

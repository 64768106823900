import Appointments from "components/Dashboard/Appointments";
import DashboardLayout from "layouts/DashboardLayout";

const AppointmentsContainer = () => {
  return (
    <DashboardLayout>
      <Appointments />
    </DashboardLayout>
  );
};

export default AppointmentsContainer;

import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ModalBodyContainer = styled.div`
  padding: 24px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  gap: 10px;
`;
export const ModalTitle = styled.h1`
  margin: 20px 0 0 0;
  font-size: 1.8rem;
  width: 90%;
  font-weight: bold;
  text-align: center;
`;

export const FormContainer = styled.form`
  width: 100%;
  margin-top: 30px;

  .app-select {
    width: 100%;
    margin: 24px 0;
    text-align: right;
  }
`;

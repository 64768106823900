import AddFreeLesson from "components/Dashboard/FreeLessons/AddFreeLesson";
import EmptyLessons from "components/Dashboard/FreeLessons/EmptyLessons";
import FreeLessonsList from "components/Dashboard/FreeLessons/FreeLessonsList";
import DashboardLayout from "layouts/DashboardLayout";

const FreeLessonsContainer = () => {
  return (
    <DashboardLayout>
      {/* <EmptyLessons /> */}
      <FreeLessonsList />
    </DashboardLayout>
  );
};

export default FreeLessonsContainer;

import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const FooterContainer = styled.div`
  background-color: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 146px 20px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  background-color: rgb(var(--blue-light));
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  hr {
    height: 0px;
    width: 100%;
    background: unset;
    /* border: solid 1px #d0d0d0; */
    margin-top: 50px;
  }
  ${media("large")} {
    padding: 30px 22px 20px;
  }

  ${media("medium")} {
    padding: 30px 12px 20px;
  }
`;

export const FooterSections = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: center; */
  ${media("medium")} {
    flex-direction: column;
    gap: 10px;
  }
`;
export const FooterSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
`;
export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  ${media("medium")} {
    gap: 5px;
  }
`;
export const InfoLabel = styled.span`
  font-size: 1.3rem;
  color: #635f60;
  font-weight: bold;
`;
export const InfoValue = styled.span`
  color: #333333;
`;
export const LogoContainer = styled(Link)`
  cursor: pointer;
  display: block;
  ${media("medium")} {
    display: none;
  }
`;

export const FooterBrand = styled.p`
  font-size: 1.3rem;
  margin-top: 20px;
  color: rgb(var(--border-dark));
  text-align: center;
  /* position: absolute; */
  span {
    display: block;
  }
`;
export const SocialContainer = styled.div`
  display: flex;
  gap: 16px;
`;
export const SocialItem = styled.a`
  width: 48px;
  height: 48px;
  background-color: #f2f4f8;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PaymentIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;
export const PaymentIcon = styled.img`
  max-width: 100px;
  object-fit: contain;
  ${media("medium")} {
    max-width: 65px;
  }
`;

import AddCourse from "components/Dashboard/Courses/AddCourse";
import DashboardLayout from "layouts/DashboardLayout";

const AddCourseContainer = () => {
  return (
    <DashboardLayout>
      <AddCourse />
    </DashboardLayout>
  );
};

export default AddCourseContainer;

import { Oval } from "react-loader-spinner";
import { StyledButton } from "./appbutton.styles";

interface buttonProps {
  children: React.ReactNode;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: "primary" | "outline" | "grey";
  customColor?: string;
  size?: string; //xs, sm, md, lg
  disabled?: boolean;
  loader?: boolean;
  [x: string]: any;
}
const AppButton: React.FC<buttonProps> = ({
  children,
  variant = "primary",
  size = "md",
  onClick,
  disabled,
  loader = false,
  customColor = "",
  ...rest
}) => {
  return (
    <StyledButton
      variant={variant}
      onClick={onClick}
      disabled={loader}
      size={size}
      loader={loader}
      customColor={customColor}
      {...rest}
    >
      {loader ? (
        <Oval wrapperClass='loader' color='#fff' height={20} width={20} />
      ) : (
        children
      )}
      {/* {children} */}
    </StyledButton>
  );
};

export default AppButton;

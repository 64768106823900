import ForgetForm from "components/Auth/ForgetForm";
import LoginForm from "components/Auth/LoginForm";
import AuthLayout from "layouts/AuthLayout";

const ForgetPasswordContainer = () => {
  return (
    <AuthLayout>
      <ForgetForm />
    </AuthLayout>
  );
};

export default ForgetPasswordContainer;

import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { checkoutReducer } from "./checkout";
import { localeReducer } from "./locale";
import { settingReducer } from "./settings";

const reducers = combineReducers({
  auth: authReducer,
  checkout: checkoutReducer,
  setting: settingReducer,
  locale: localeReducer,
});

export default reducers;

import { Link, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { media } from "styles/utils";

export const SideBarContainer = styled.aside<{
  ref: any;
  open: boolean;
  isLtr: boolean;
}>`
  background-color: rgb(var(--purple));
  width: 300px;
  padding-top: 45px;
  z-index: 100;
  display: block;
  /* transform: scaleX(0); */
  transition: transform 0.2s;
  transform-origin: ${({ isLtr }) => (isLtr ? "left" : "right")};

  .sidebar-icon {
    display: none;
  }
  ${media("medium")} {
    position: absolute;
    /* right: 0;
    top: 0; */
    /* transform: translateX(300px); */
    /* display: none; */
    transform: ${(props) => (props.open ? "scaleX(1)" : "scaleX(0)")};
    .sidebar-icon {
      display: block;
      position: absolute;
      left: ${({ isLtr }) => (isLtr ? "unset" : "10px")};
      right: ${({ isLtr }) => (isLtr ? "10px" : "unset")};
      transform: ${({ isLtr }) => (isLtr ? "rotateY(180deg)" : "unset")};

      top: 10px;
    }
  }
`;

export const TeacherInfoContainer = styled.div``;

export const TeacherImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    width: 77px;
    height: 77px;
    border-radius: 50%;
    object-fit: cover;
  }
`;
export const TeacherName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  span {
    color: white;
  }
`;
export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    font-size: 1.1rem;
  }
`;
export const TeacherMajor = styled.p`
  font-size: 1.1rem;
  text-align: center;
  margin-top: 7px;
  color: rgb(var(--blue-light));
`;

export const MenuContainer = styled.div`
  margin-top: 40px;
`;
export const MenuItem = styled(NavLink)<{ active?: boolean }>`
  font-size: 1.4rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 7px 30px;
  background-color: transparent;

  color: #fff;

  .inactive-icon {
    display: block;
  }
  .active-icon {
    display: none;
  }
  &.active {
    color: rgb(var(--main-color-light));
    background-color: #fff;

    .inactive-icon {
      display: none;
    }
    .active-icon {
      display: block;
    }
  }

  &:hover {
    background-color: #fff;
    color: rgb(var(--main-color-light));
    .active-icon {
      display: block;
    }
    .inactive-icon {
      display: none;
    }
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
`;
export const MenuName = styled.span``;

export const LogoutButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 7px 30px;
  color: #fff;
  cursor: pointer;
  font-size: 1.4rem;
  margin-top: 50px;
`;

export const PremiumContainer = styled.div`
  background-color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin: 50px auto;
  max-width: 240px;
`;
export const PremiumButton = styled.button`
  color: rgb(var(--border-dark));
  font-size: 1.8rem;
  background-color: #fcc751;
  margin-top: 24px;
  border: none;
  padding: 15px;
  cursor: pointer;
`;

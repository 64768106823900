import { ChipContainer, ChipsContainer, ChipText } from "./appchips.styles";
import { useTranslation } from "react-i18next";

interface chipOption {
  name: string;
  value: string;
}

interface chipsProps {
  options: chipOption[];
  variant?: "sm" | "md" | "lg" | "full";
  handleChoice: (value: any) => void;
  selectedChoice: string | string[];
  multiple?: boolean;
  [x: string]: any;
}

const AppChips = ({
  options,
  variant = "full",
  handleChoice,
  selectedChoice,
  multiple = false,
  ...rest
}: chipsProps) => {
  const { t } = useTranslation();

  return (
    <ChipsContainer {...rest}>
      {options.map((option) => (
        <ChipContainer
          active={
            multiple
              ? selectedChoice.includes(option.value)
              : selectedChoice === option.value
          }
          py='20px'
          key={option.value}
          variant={variant}
          onClick={() => handleChoice(option.value)}
        >
          <ChipText>{t(option.name)}</ChipText>
        </ChipContainer>
      ))}
    </ChipsContainer>
  );
};

export default AppChips;

import { post, get, put } from "config/axios";

export const trainerAPI = {
  setupAccount: (data, trainerId) =>
    post(`trainers/${trainerId}?_method=put`, data),
  createEvent: (data) => post("events", data),
  getEvents: (trainerId, type) =>
    get(`trainers/${trainerId}/events?type=${type}`),
  getBanks: () => get(`banks`),
  updateBankAccount: (data, trainerId) =>
    post(`trainers/${trainerId}/bank-account?_method=put`, data),
  getBookings: () => get(`bookings?resource='trainer'`),
  getUpcomingBookings: () => get(`bookings/upcoming`),
  updateSessionUrl: (data, bookingId) =>
    post(`bookings/${bookingId}/session-url?_method=put`, data),
  updateEventUrl: (data, eventId) =>
    post(`events/${eventId}/event-url?_method=put`, data),
  getTransactionsData: (trainerId) => get(`trainers/${trainerId}/transactions`),
  getCities: () => get("cities"),
  getPlaces: (cityId) => get(`cities/${cityId}/places`),
  getSubjects: () => get("subjects"),
  getSpecialities: () => get("specialities"),
  getUserInfo: () => get("users/me"),
  //chats

  getMySession: () => get("sessions"),
  getSingleSession: (sessionId, page) =>
    get(`sessions/${sessionId}/dms?page=${page}`),
  sendMessage: (data) => post(`dms`, data),

  //packages
  getPackages: () => get("packages"),

  //checkout
  prepareCheckout: (packageId, data, paymentType = "mada") =>
    post(`subscriptions/${packageId}/checkout`, data, {
      headers: {
        PAYMENT_METHOD_KEY: paymentType,
      },
    }),
  checkPaymentStatus: (data) => post("subscriptions/status", data),

  //custom packages
  getTrainerPackages: (trainerId) => get(`trainers/${trainerId}/packages`),

  addTrainerPackages: (trainerId, data) =>
    post(`trainers/${trainerId}/packages`, data),

  getTrainerPackage: (trainerId, packageId) =>
    get(`trainers/${trainerId}/packages/${packageId}`),

  editTrainerPackages: (packageId, trainerId, data) =>
    post(`trainers/${trainerId}/packages/${packageId}?_method=put`, data),

  deleteTrainerPackages: (trainerId, packageId) =>
    post(`trainers/${trainerId}/packages/${packageId}?_method=delete`),

  //settings
  getSettings: () => get("settings"),
  getNotifications: (page) => get(`notifications?page=${page}`),
  getNotificationsCounter: () => get("notifications-counter"),

  // stats
  getStats: () => get("stats"),

  // prices
  getPrices: () => get("prices"),
};

import { createSlice } from "@reduxjs/toolkit";

interface ICheckoutState {
  selectedPackage: {
    id?: number;
    fee?: number;
    title?: string;
    description?: string;
  };
  checkoutId?: string;
}
const initialState: ICheckoutState = {
  selectedPackage: {},
};

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setPackageInfo: (state, action) => {
      state.selectedPackage = action.payload;
    },
    clearPackageInfo: (state) => {
      state.selectedPackage = {};
    },
    setCheckout: (state, action) => {
      state.checkoutId = action.payload;
    },
  },
});
export const { clearPackageInfo, setPackageInfo, setCheckout } =
  checkoutSlice.actions;

export const { reducer: checkoutReducer } = checkoutSlice;

import { EmptyCourse } from "components/Icons";
import AppButton from "components/shared/AppButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  EmptyCoursesContainer,
  EmptyCourseTitle,
  EmptyCourseSubTitle,
} from "../Courses/courses.styles";

const EmptyAppointments = () => {
  //hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  //functions
  const handleAddCourse = () => {
    navigate("/dashboard");
  };

  return (
    <EmptyCoursesContainer>
      <EmptyCourse />
      <EmptyCourseTitle>{t("empty_appointment")}</EmptyCourseTitle>
      <AppButton onClick={() => handleAddCourse()} mt='35px'>
        {t("home")}
      </AppButton>
    </EmptyCoursesContainer>
  );
};

export default EmptyAppointments;

import styled from "styled-components/macro";
import { media } from "styles/utils";

export const ThanksWrapper = styled.div`
  width: 100%;
  height: 100vh;
  direction: rtl;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media("medium")} {
    padding: 20px;
    overflow-y: auto;
  }
`;

export const ThanksContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 80px;
  flex-wrap: wrap-reverse;
  ${media("medium")} {
    gap: 30px;
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const MessageTitle = styled.h1`
  margin: 0;
  font-size: 2.7rem;
  font-weight: bold;
`;
export const MessageSubtitle = styled.h1`
  margin: 13px 0 0 0;
  font-size: 1.6rem;
`;

export const CloseButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;
  margin-top: 16px;
  cursor: pointer;
`;

import { Title } from "../Appointments/appointments.styles";
import { InvoiceWrapper } from "./invoice.styles";
import { useEffect, useState } from "react";
import { trainerAPI } from "services/apis/trainer";
import { useAppSelector } from "store";

const Invoice = () => {
  const checkoutID = useAppSelector((state) => state.checkout.checkoutId);
  const [result, setResult] = useState("");
  useEffect(() => {
    if (checkoutID) {
      checkPaymentStatus();
    }
  }, []);

  const checkPaymentStatus = async () => {
    try {
      const response = await trainerAPI.checkPaymentStatus({ checkoutID });
      setResult("success");
    } catch (error) {
      setResult("fail");
    }
  };

  return (
    <InvoiceWrapper>
      {result === "success" ? (
        <Title>تمت عملية الاشتراك بنجاح</Title>
      ) : result === "fail" ? (
        <Title>حدث خطأ ما</Title>
      ) : null}
    </InvoiceWrapper>
  );
};

export default Invoice;

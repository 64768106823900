import styled from "styled-components/macro";

export const CheckoutWrapper = styled.div``;
export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;

export const FormContainer = styled.div`
  margin-top: 20px;
`;

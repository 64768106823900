import CoursesList from "components/Dashboard/Courses/CoursesList";
import EmptyCourses from "components/Dashboard/Courses/EmptyCourses";
import DashboardLayout from "layouts/DashboardLayout";

const CoursesContainer = () => {
  return (
    <DashboardLayout>
      {/* <EmptyCourses /> */}
      <CoursesList />
    </DashboardLayout>
  );
};

export default CoursesContainer;

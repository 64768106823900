import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { isLTR } from "styles/utils";

export const MessagesWrapper = styled.div``;
export const MessagesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.h1`
  font-size: 2.7rem;
  margin: 0;
  font-weight: bold;
`;

export const MessagesItems = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
`;
export const MessageItem = styled(Link)`
  width: 100%;
  text-decoration: none;
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 15px;
  }
`;
export const Image = styled.div``;

export const Info = styled.div<{
  isLtr: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: ${({ isLtr }) => (isLtr ? "unset" : "auto")};
`;
export const Message = styled.p`
  font-size: 1.2rem;
  color: rgb(var(--text-menu));
  margin-top: 5px;
`;
export const SenderName = styled.p`
  color: rgb(var(--text-main));
`;

export const Date = styled.span`
  font-size: 1.1rem;
  color: rgb(var(--grey));
`;

import LoginForm from "components/Auth/LoginForm";
import OtpForm from "components/Auth/OtpForm";
import AuthLayout from "layouts/AuthLayout";
import { useState } from "react";

const LoginContainer = () => {
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  return (
    <AuthLayout>
      {!showOtpForm ? (
        <LoginForm
          setShowOtpForm={setShowOtpForm}
          setMobileNumber={setMobileNumber}
        />
      ) : (
        <OtpForm
          setShowOtpForm={setShowOtpForm}
          mobileNumber={mobileNumber}
          source='login'
        />
      )}
    </AuthLayout>
  );
};

export default LoginContainer;

import MainContent from "components/Dashboard/MainContent";
import {
  VerifyMessageContainer,
  VerifyText,
} from "components/Dashboard/MainContent/maincontent.styles";
import Panel from "components/Dashboard/Panel";
import Sidebar from "components/Dashboard/Sidebar";
import VerifyNumberModal from "components/Modals/VerifyNumberModal";
import AppButton from "components/shared/AppButton";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { echo } from "services/lib/echo";
import { useAppSelector } from "store";

const DashboardLayout = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const { t, i18n } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const isVerified = useAppSelector((state) => state.auth.isVerified);

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  useEffect(() => {
    echo.private(`App.Models.User.${user.user_id}`).notification((info) => {
      toast(info.data.message, { duration: 5000 });
    });

    return () => echo.leave(`App.Models.User.${user.id}`);
  }, []);

  useEffect(() => {
    if (isVerified) return;
    toast(
      (ts) => (
        <VerifyMessageContainer dir={getLanguage() === "ar" ? "rtl" : "ltr"}>
          <VerifyText>{t("unverified_message")}</VerifyText>
          <div className='actions-container'>
            <AppButton
              variant='outline'
              size='sm'
              onClick={() => toast.dismiss(ts.id)}
            >
              {t("dismiss")}
            </AppButton>
            <AppButton
              onClick={() => {
                setOpenVerifyModal(true);
                toast.dismiss(ts.id);
              }}
            >
              {t("verify_number")}
            </AppButton>
          </div>
        </VerifyMessageContainer>
      ),
      { duration: 10000 }
    );

    return () => toast.dismiss();
  }, [isVerified]);

  useEffect(() => {
    echo.private(`notifications.${user.user_id}`).notification((info) => {
      if (info.type === "App\\Notifications\\NewDmNotification") {
        toast(`${t("newMessageFrom")} ${info.data.sender.name}`, {
          duration: 5000,
        });
      } else {
        toast(info.data.message, { duration: 5000 });
      }
    });

    return () => echo.leave(`notifications.${user.id}`);
  }, []);

  return (
    <div
      className='dashboard-container'
      style={{ direction: getLanguage() === "ar" ? "rtl" : "ltr" }}
    >
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <MainContent showSidebar={showSidebar} setShowSidebar={setShowSidebar}>
        {children}
      </MainContent>
      <Panel />
      <VerifyNumberModal open={openVerifyModal} setOpen={setOpenVerifyModal} />
    </div>
  );
};

export default DashboardLayout;
